<button class="action-button" (click)="newShirt()">Add Inventory</button>

<div *ngFor="let shirt of shirts" class="shirt-row">
    <div>Type: {{shirt.type}}</div>
    <div style="flex-grow: 1; min-width: 10px;"></div>
    <div>Size: {{shirt.size}}</div>
    <div style="flex-grow: 2; min-width: 15px;"></div>
    <button class="green small-button" (click)="editShirt(shirt)">Edit</button>
    <button class="red small-button" (click)="deleteShirt(shirt)">Delete</button>
</div>

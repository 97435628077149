import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "tipCalculator",
})
export class TipCalculatorPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    var type = "absolute";
    var orderAmount = 1;
    var tipAmount = 0;

    if (args.length > 0) {
      type = String(args[0]);
    }

    if (args.length > 1) {
      orderAmount = Number(args[1]);
    }

    tipAmount = value as number;

    switch (type) {
      case "percentage":
        return orderAmount * (tipAmount / 100);
      case "round":
        //rounds up to nearest tip amount
        var remaineder = orderAmount % tipAmount;
        if (remaineder === 0) {
          return 0;
        } else {
          return tipAmount - remaineder;
        }
      case "absolute":
        return tipAmount;
      default:
        return "na";
    }
  }
}

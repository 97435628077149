<h2>Bug Report</h2>

<app-input type="text" name="Title" [model]="bug.title" (changed)="bug.title = $event"></app-input>
<app-input type="text" name="Description" [model]="bug.description" (changed)="bug.description = $event"></app-input>
<app-input type="text" name="Location" [model]="bug.location" (changed)="bug.location = $event"></app-input>

<br />

<div>
    <button (click)="cancel()">Cancel</button>
    <button (click)="save()">Save</button>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

import { ChartEvent } from 'chart.js';
import { Subscription, take } from 'rxjs';
import { AlertsService } from 'src/app/Packages/alerts/alerts.service';
import { LoadingService } from 'src/app/Services/loading.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';
import { BarInfo } from 'src/app/Helpers/functions/BarInfo';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit {

  computers: any[] = [];
  computer: any;
  sub: Subscription;
  viewType = 'info';


  //ram
  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,

    plugins: {
      legend: {
        display: false,
        position: 'top',
      },


    }
  };

  //cpu
  public pieChartOptions2: ChartConfiguration['options'] = {
    responsive: true,

    plugins: {
      legend: {
        display: false,
        position: 'top',
      },


    }
  };
  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [],
    datasets: [{
      data: []
    }]
  };

  public ramChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [],
    datasets: [{
      data: []
    }]
  };
  public pieChartType: ChartType = 'pie';
  public pieChartPlugins = [];

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [],
    labels: []
  };

  public barChartData: ChartConfiguration['data'] = {
    datasets: [],
    labels: []
  };
  public barChartData2: ChartConfiguration['data'] = {
    datasets: [],
    labels: []
  };

  public lineChartData2: ChartConfiguration['data'] = {
    datasets: [],
    labels: []
  };

  //ram
  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      y:
      {

        beginAtZero: true,
        position: 'left',
        stacked: true,
      },
      x: {
        reverse: true,
        stacked: true,
      }
    },

    plugins: {
      legend: { display: true },

    }
  };

  //cpu
  public lineChartOptions2: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.

      y:
      {
        stacked: true,

        beginAtZero: true,
        position: 'left',
      },
      x: {
        reverse: true,
        stacked: true,
      }
    },

    plugins: {
      legend: { display: true },

    }
  };

  public lineChartType: ChartType = 'line';

  constructor(private alert: AlertsService, private db: StaffAppDatabase, private loading: LoadingService, private bar: BarInfo) { }


  async selected(computer: any) {
    this.computer = computer;
    this.unsubscribe();
    this.sub = this.db.getUsage(computer.PC, this.bar.bar).subscribe((val: any[]) => {


      const last = val[val.length - 1];

      this.pieChartData = {
        labels: last.value.map((v) => v.Name).concat(['Avaialble']),
        datasets: [{ data: last.value.map((v) => v.CPUUsage).concat([100 - last.value.reduce((pre, acc) => { return pre + acc.CPUUsage }, 0)]) }]

      }
      this.ramChartData = {
        labels: last.value.map((v) => v.Name).concat(['Avaiable']),
        datasets: [{ data: last.value.map((v) => v.RAMUsage).concat([computer.TotalRam - last.value.reduce((pre, acc) => { return pre + acc.RAMUsage }, 0)]) }]
      }

      var labels = val.map(record => new Date(Number(record.key)).toLocaleString('en-US', {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }));
      var nameIndex = {};

      val.forEach(record => {
        record.value.forEach((usage) => {
          if (!nameIndex[usage.Name]) {
            nameIndex[usage.Name] = {
              label: usage.Name,
              data: [],
              stack: 'a'
            }
          }
          nameIndex[usage.Name].data.push(usage.CPUUsage);
        })
      })

    


      const datasets: any[] = Object.values(nameIndex);
    
      this.lineChartOptions2.scales.y.max = Math.max(...val.map((d) => d.value.reduce((pre, acc) => { return pre + acc.CPUUsage }, 0))) + 10;

      this.lineChartData = {
        labels,
        datasets
      }
     
      var ramIndex = {};
      val.forEach(record => {
        record.value.forEach((usage) => {
          if (!ramIndex[usage.Name]) {
            ramIndex[usage.Name] = {
              label: usage.Name,
              data: [],
            }
          }
          ramIndex[usage.Name].data.push(Number(usage.RAMUsage) / 1000000);
        })
      });

      const datasets2: any[] = Object.values(ramIndex);
      this.lineChartOptions.scales.y.max = computer.TotalRam / 1000000;
      this.lineChartData2 = {
        labels,
        datasets: datasets2
      }
      this.barChartData2 = {
        labels,
        datasets: datasets2
      }

      this.barChartData = {
        labels,
        datasets
      }
   
    });
  }

  /* ---------- Base ------------- */
  ngOnInit(): void {
    this.loading.nextEmit('on');
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  unsubscribe() {
    try {
      this.sub.unsubscribe();
    } catch (error) {
      //sub is null
    }
  }

  async getData() {
    this.computers = await this.db.getComputers(this.bar.bar).then((data) => {
      
      return data;
    })
    this.computer = this.computers[0];
    this.selected(this.computer)

    this.loading.nextEmit('off');
  }


}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService } from 'src/app/Packages/alerts/alerts.service';
import { OverviewService } from 'src/app/Packages/overview.service';
import { LoadingService } from 'src/app/Services/loading.service';

@Component({
  selector: 'app-missing-telling-dates',
  templateUrl: './missing-telling-dates.component.html',
  styleUrls: ['./missing-telling-dates.component.css']
})
export class MissingTellingDatesComponent implements OnInit {
  @Input('date') date: Date = new Date();
  data: any[] = [];
  constructor(public loading: LoadingService, public alert:AlertsService, public overview: OverviewService, public modal: NgbActiveModal) { }

  ngOnInit(): void {
    if(this.overview.missingDates.length > 0) {
      this.data = this.overview.missingDates;
    }
  }

  async catchUp(){
    await this.overview.getMissingDates(this.date.getFullYear());
    this.data = this.overview.missingDates;
  }

  close(){
    this.modal.close();
  }

}

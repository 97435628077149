import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/Services/loading.service';


@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],

})
export class LoadingComponent implements OnInit {
  isLoading = false;
  percent: string = null;
  constructor(private loading: LoadingService, private zone: NgZone, private ref: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.loading.listen().subscribe(val => {
      //console.log('loading component', val)
      // console.log('running')
      this.isLoading = (val.vari === 'on' ? true : false);
      try {
        this.percent = val.percent.toFixed(2) || null;
        //  console.log(this.percent)
      } catch (e) {
        // console.log(e)
        this.percent = null;
      }



    })



  }

}

import {
  Component,
  OnInit,
  HostListener,
  Input,
  ViewChild,
  Output,
} from "@angular/core";
import { Auth } from "../../../auth/auth";
import { Router } from "@angular/router";
import { MyLogger } from "../../../Helpers/functions/MyLogger";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  @ViewChild("email") email: { nativeElement: { value: string } };
  @ViewChild("password") password: { nativeElement: { value: string } };

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.keyEvent(event);
  }

  constructor(
    public auth: Auth,
    public router: Router,
    public alert: AlertsService,
    public loading: LoadingService
  ) {
    this.auth.signOut();
  }

  ngOnInit() {}

  keyEvent(event: KeyboardEvent) {
    if (
      event.key === "Enter" &&
      this.email.nativeElement.value &&
      this.password.nativeElement.value
    ) {
      this.auth.login(
        this.email.nativeElement.value,
        this.password.nativeElement.value
      );
    }
  }

  async login() {
    console.log("loggin in");
    this.auth
      .login(this.email.nativeElement.value, this.password.nativeElement.value)
      .then(() => {
        console.log("logged in successfully");
        this.alert.nextEmit(AlertsService.good("Logged in successfully"));
      })
      .catch((e) => {
        console.log(e);
        this.alert.nextEmit(AlertsService.error(e.toString()));
      });
  }

  async reset() {
    console.log("resetting");
    this.auth
      .reset(this.email.nativeElement.value)
      .then(() => {
        console.log("reset successfully");
        this.alert.nextEmit(AlertsService.good("Reset email sent"));
      })
      .catch((err) => {
        console.log(err);
        this.alert.nextEmit(AlertsService.error(err.toString()));
      });
  }
}

<div [class]="align === 'left' ? 'left' : 'right'">{{data.name}}</div>
<div [class]="align === 'left' ? 'left' : 'right'">{{data.address}} {{data.number}}</div>
<div [class]="align === 'left' ? 'left' : 'right'">{{data.zip}}, {{data.city}}</div>
<div [class]="align === 'left' ? 'left' : 'right'">
    {{data.email}}
</div>
<div [class]="align === 'left' ? 'left' : 'right'" *ngIf="showPayment">
    IBAN: {{data.iban}}
</div>

<div [class]="align === 'left' ? 'left' : 'right'" *ngIf="showPayment">
    BTW: {{data.btw}}
</div>
<div [class]="align === 'left' ? 'left' : 'right'" *ngIf="showPayment">
    KvK: {{data.kvk}}
</div>
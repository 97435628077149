export class GenericMessage {
  type: string = ""; // success, info, warning, danger, message
  message: string = "";
  user: { uid: string; email: string; displayName: string } = {
    uid: "",
    email: "",
    displayName: "",
  };
  date: number = new Date().getTime();
  page: string = "";
  notificationChannel: string = "";
  threadDate: number = new Date().getTime();
  bar: string = "";

  constructor(
    type: string,
    message: string,
    user: { uid: string; email: string; displayName: string },
    page: string,
    notificationChannel: string,
    date: Date,
    bar: string
  ) {
    this.bar = bar;
    this.type = type;
    this.message = message;
    this.user = user;
    this.threadDate = new Date(date).getTime();
    this.page = page;
    this.notificationChannel = notificationChannel;
  }
}

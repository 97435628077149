import { AsyncPipe } from "@angular/common";
import { ChangeDetectorRef, Injector, OnDestroy } from "@angular/core";
import { Pipe, PipeTransform } from "@angular/core";


import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Pipe({
    name: 'staffname', pure: false
})

export class StaffNamePipe implements PipeTransform, OnDestroy {
    private asyncPipe: AsyncPipe;
    constructor(private db: StaffAppDatabase, private injector: Injector){
        this.asyncPipe = new AsyncPipe(injector.get(ChangeDetectorRef))
    }
    async transform(uid) {
       console.log('transforming UID');
       const name = await this.asyncPipe.transform(this.db.getStaffName(uid));

        return name;
    }

    ngOnDestroy(){
        this.asyncPipe.ngOnDestroy();
    }
}

import { Component, OnInit } from '@angular/core';
import { AlertsService } from './alerts.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {

  data: any;
  constructor(private alerts: AlertsService) { }

  ngOnInit(): void {
    this.alerts.listen().subscribe(val => {
      this.data = val;
      setTimeout(() => {
        this.data = null;
      },5000)
    })
  }

  close (){
    this.data = null;
  }
}

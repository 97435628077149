export class EquationModel {
  normalise: boolean = true;
  customWeights: {
    last5Minutes: number,
    last15Minutes: number,
    lastHour: number,
    middle5Minutes: number,
    first5Minutes: number,
  } = {
    last5Minutes: 1,
    last15Minutes: 1,
    lastHour: 1,
    middle5Minutes: 1,
    first5Minutes: 1,
  };
  minimumPrice: number = 3.2;
  maxUp: number = 3.2;
  maxDown: number = 2.8;

  constructor(){

  }

  static clone(m: EquationModel){
    var em = new EquationModel();
    em.normalise = m.normalise;
    em.customWeights = {
      last5Minutes: m.customWeights.last5Minutes,
      last15Minutes: m.customWeights.last15Minutes,
      lastHour: m.customWeights.lastHour,
      middle5Minutes: m.customWeights.middle5Minutes,
      first5Minutes: m.customWeights.first5Minutes,
    };
    em.minimumPrice = m.minimumPrice;
    em.maxUp = m.maxUp;
    em.maxDown = m.maxDown;
    return em;
  }
}

import { Component, Inject,Input,OnInit } from '@angular/core';
import { AvatarServiceService } from '../avatar-service.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
const multiavatar = require('@multiavatar/multiavatar');
@Component({
  selector: 'app-avatar-viewer',
  templateUrl: './avatar-viewer.component.html',
  styleUrls: ['./avatar-viewer.component.css']
})
export class AvatarViewerComponent implements OnInit {
  @Input() config = null;
  source;
  constructor(private avatarService: AvatarServiceService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if(this.config){
      let svg2= multiavatar(this.avatarService.seed, this.avatarService.defaultBackground, this.config);
      this.source= this.sanitizer.bypassSecurityTrustHtml(svg2);

    } else {
      this.avatarService.sourceObserver.subscribe(values => {
        this.source = values;
      })
    }

  }

}

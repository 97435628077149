import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase, Tutorial } from "src/app/Services/staffappdatabase";
import { Auth } from "src/app/auth/auth";

@Component({
  selector: "app-tutorials",
  templateUrl: "./tutorials.component.html",
  styleUrls: ["./tutorials.component.css"],
})
export class TutorialsComponent implements OnInit {
  defaultTypes: string[] = [
    "Bier Techniek",
    "Kassa",
    "Dranken Kennis",
    "Afsluiten",
    "Openen",
    "StaffApp",
    "Other",
  ];
  tutorials: Tutorial[] = [];
  tutorial: Tutorial;
  videoLink: string | SafeUrl = "";

  editing: boolean = false;
  edited: boolean = false;
  file: File;

  constructor(
    public fb: StaffAppDatabase,
    public alert: AlertsService,
    public loading: LoadingService,
    private sanitizer: DomSanitizer,
    public auth: Auth
  ) {}

  ngOnInit(): void {
    this.getTutorials();
    console.log(this.auth.accessUser);
  }

  async getTutorials() {
    this.loading.nextEmit("on");
    this.tutorials = await this.fb.getTutorials();
    console.log(this.tutorials);
    this.loading.nextEmit(null);
  }

  tutorialClicked(tut) {
    if (this.editing) {
      this.alert.nextEmit(
        AlertsService.error(
          "Please save or cancel your current tutorial before editing another"
        )
      );
      return;
    }
    this.videoLink = "";
    this.tutorial = tut;
    this.edited = false;
    this.editing = false;
    //this.getVideo(tut);
  }

  async getVideo(tutorial: Tutorial) {
    this.videoLink = await this.fb.getTutorialVideo(tutorial);
    console.log(this.videoLink);
  }

  async uploadVideo(event: any) {
    this.loading.nextEmit("on");
    const file = event.target.files[0];

    if (file) {
      this.file = file;
      var URL = window.URL || window.webkitURL;
      this.videoLink = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(file)
      );
      this.alert.nextEmit(AlertsService.success("Video Selected"));
      this.loading.nextEmit(null);
    } else {
      this.alert.nextEmit(AlertsService.error("No file selected"));
      this.loading.nextEmit(null);
    }
  }

  createTutorial() {
    if (this.editing) {
      this.alert.nextEmit(
        AlertsService.error(
          "Please save or cancel your current tutorial before editing another"
        )
      );
      return;
    }
    this.edited = true;
    this.editing = true;
    this.videoLink = null;
    this.tutorial = new Tutorial();
    this.tutorial.ref = this.fb.createId();
    setTimeout(() => {
      this.videoLink = "na";
    });
  }

  categorySelected(event) {
    console.log(event);
    this.tutorial.type = event;
  }

  cancel() {
    this.tutorial = null;
    this.videoLink = null;
    this.edited = false;
    this.editing = true;
    this.getTutorials();
  }

  async delete() {
    if (this.editing) {
      this.alert.nextEmit(
        AlertsService.error(
          "Please save or cancel your current tutorial before editing another"
        )
      );
      return;
    }
    //confirm first
    var confirm = window.confirm(
      "Are you sure you want to delete this tutorial?"
    );
    if (confirm) {
      const error = await this.fb.deleteTutorial(this.tutorial);
      if (error) {
        this.alert.nextEmit(AlertsService.error(error.error.message));
        return;
      } else {
        this.alert.nextEmit(AlertsService.success("Tutorial Deleted"));
        this.getTutorials();
      }
      this.tutorial = null;
      this.videoLink = null;
      this.edited = false;
      this.editing = true;
    }
  }

  async save() {
    var confirm = window.confirm(
      "Are you sure you want to save this tutorial?"
    );

    if (!confirm) {
      return;
    }

    if (!this.tutorial) {
      this.alert.nextEmit(AlertsService.error("No tutorial selected"));
      return;
    }

    if (!this.tutorial.title) {
      this.alert.nextEmit(AlertsService.error("No title"));
      return;
    }

    if (!this.tutorial.type) {
      this.alert.nextEmit(AlertsService.error("No category"));
      return;
    }
    this.loading.nextEmit("on");
    if (!this.videoLink || this.videoLink == "na" || this.videoLink == "") {
      this.alert.nextEmit(
        AlertsService.info(
          "No new video selected",
          "No video has been added or if a video is already there no new video replaces it",
          3
        )
      );
    } else {
      const error = await this.fb.uploadTutorialVideo(this.tutorial, this.file);
      if (error) {
        this.alert.nextEmit(AlertsService.error(error.error.message));
        this.loading.nextEmit(null);
        return;
      } else {
        this.alert.nextEmit(AlertsService.success("Video Uploaded"));
      }
    }

    const error2 = await this.fb.saveTutorial(this.tutorial);
    if (error2) {
      this.alert.nextEmit(AlertsService.error(error2.error.message));
      this.loading.nextEmit(null);
      return;
    } else {
      this.alert.nextEmit(AlertsService.success("Tutorial Saved"));
    }

    this.loading.nextEmit(null);
    this.getTutorials();

    this.tutorial = null;
    this.videoLink = null;
    this.edited = false;
    this.editing = true;
    this.file = null;
  }
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "../Components/Home/login/login.component";
import { AuthGuard } from "../auth/authguard";

import { AccountComponent } from "../Components/Account/account/account.component";

import { NDOrderComponent } from "../Components/Bestellingen/ndorder/ndorder.component";

import { CountingComponent } from "../Components/Controle/counting/counting.component";
import { TillComponent } from "../Components/Controle/till/till.component";
import { MessagesComponent } from "../Components/Instellingen/messages/messages.component";
import { TasksComponent } from "../Components/Onderhoud/tasks/tasks.component";
import { BarComponent } from "../Components/Instellingen/bar/bar.component";
import { DrinkOrdersComponent } from "../Components/Bestellingen/drink-orders/drink-orders.component";

import { HelpComponent } from "../Components/Help/help/help.component";
import { StockComponent } from "../Components/Instellingen/stock/stock.component";

import { StaffDrinksComponent } from "../Components/Personeel/staff-drinks/staff-drinks.component";
import { StaffOrdersComponent } from "../Components/Personeel/staff-orders/staff-orders.component";

import { VipRoomsComponent } from "../Components/Instellingen/vip-rooms/vip-rooms.component";
import { TestersComponent } from "../Components/Instellingen/testers/testers.component";

import { LostandfoundComponent } from "../Components/Instellingen/lostandfound/lostandfound.component";
import { PayslipUploaderComponent } from "../Components/Personeel/payslip-uploader/payslip-uploader.component";
import { InstelligenComponent } from "../Components/Instellingen/instelligen/instelligen.component";
import { OnderhoudComponent } from "../Components/Onderhoud/onderhoud/onderhoud.component";
import { BestellingenComponent } from "../Components/Bestellingen/bestellingen/bestellingen.component";
import { PeroneelComponent } from "../Components/Personeel/peroneel/peroneel.component";
import { InkomstenComponent } from "../Components/Inkomsten/inkomsten/inkomsten.component";
import { ControleComponent } from "../Components/Controle/controle/controle.component";
import { ShiftsComponent } from "../Components/Instellingen/shifts/shifts.component";
import { TellingComponent } from "../Components/Controle/telling/telling.component";
import { DashboardComponent } from "../Components/Controle/dashboard/dashboard.component";
import { ExpensesComponent } from "../Components/Instellingen/expenses/expenses.component";
import { TestingComponent } from "../Components/Testing/testing/testing.component";
import { BonusComponent } from "../Components/Testing/bonus/bonus.component";
import { InkoopComponent } from "../Components/Testing/inkoop/inkoop.component";
import { CustomerAppComponent } from "../Components/CustomerApp/customer-app/customer-app.component";
import { CatalogueComponent } from "../Components/CustomerApp/catalogue/catalogue.component";
import { CustomersComponent } from "../Components/CustomerApp/customers/customers.component";
import { LiveComponent } from "../Components/CustomerApp/live/live.component";
import { AccessLevelsComponent } from "../Components/Instellingen/access-levels/access-levels.component";
import { BookingAPIComponent } from "../Components/Testing/booking-api/booking-api.component";
import { SongRequestsComponent } from "../Components/CustomerApp/song-requests/song-requests.component";
import { MapComponent } from "../Components/Testing/map/map.component";
import { SongpricingComponent } from "../Components/CustomerApp/songpricing/songpricing.component";
import { DetailedTurnoverComponent } from "../Components/Testing/detailed-turnover/detailed-turnover.component";
import { EmulatorComponent } from "../Packages/CoinCost/emulator/emulator.component";
import { UsageComponent } from "../Components/CustomerApp/usage/usage.component";
import { ReviewsComponent } from "../Components/Instellingen/reviews/reviews.component";
import { HomeComponent } from "../Components/Home/home/home.component";
import { Schedule2Component } from "../Components/Personeel/schedule2/schedule2.component";
import { NDOrderItemsComponent } from "../Components/Bestellingen/ndorder-items/ndorder-items.component";
import { NDOrdersComponent } from "../Components/Bestellingen/ndorders/ndorders.component";
import { StaffInfoComponent } from "../Components/Personeel/staff-info/staff-info.component";
import { StaffhoursComponent } from "../Components/Personeel/staffhours/staffhours.component";
import { WipeCreditComponent } from "../Components/CustomerApp/wipe-credit/wipe-credit.component";
import { StatisticsComponent } from "../Components/CustomerApp/statistics/statistics.component";
import { DocumentManagerComponent } from "../Components/Personeel/document-manager/document-manager.component";
import { ComponentCanDeactivateGuard } from "../Guards/component-can-deactivate.guard";
import { TipsComponent } from "../Components/Controle/tips/tips.component";
import { DevicesComponent } from "../Components/Instellingen/devices/devices.component";
import { DocumentsComponent } from "../Components/Account/documents/documents.component";
import { HoursComponent } from "../Components/Account/hours/hours.component";
import { SetScheduleComponent } from "../Components/Account/set-schedule/set-schedule.component";
import { InvoiceMakerComponent } from "../Components/Tools/invoice-maker/invoice-maker.component";
import { ToolsComponent } from "../Components/Tools/tools/tools.component";
import { VoucherComponent } from "../Components/CustomerApp/voucher/voucher.component";
import { WorkingComponent } from "../Components/Testing/working/working.component";
import { WeeklyGiftComponent } from "../Components/Personeel/weekly-gift/weekly-gift.component";
import { SongCreditSimulationComponent } from "../Components/Testing/song-credit-simulation/song-credit-simulation.component";
import { TutorialsComponent } from "../Components/tutorials/tutorials.component";
import { CompetitionComponent } from "../Components/Testing/competition/competition.component";
import { ChangeRecordsComponent } from "../Components/Tools/change-records/change-records.component";

import { CanDutchComponent } from "../Components/CustomerApp/can-dutch/can-dutch.component";
import { TablesComponent } from "../Components/Controle/tables/tables.component";
import { WalletpassthroughComponent } from "../walletpassthrough/walletpassthrough.component";
import { InkoopKostenComponent } from "../Components/Instellingen/inkoop-kosten/inkoop-kosten.component";
import { CCTVComponent } from "../Components/Testing/cctv/cctv.component";
import { ShiftCheckComponent } from "../Components/Controle/shift-check/shift-check.component";
import { NDOStockHQComponent } from "../Components/Instellingen/ndostock-hq/ndostock-hq.component";
import { CountingHQComponent } from "../Components/Control/counting-hq/counting-hq.component";
import { NDCountingHQComponent } from "../Components/Control/ndcounting-hq/ndcounting-hq.component";

import { TipsSettingsComponent } from "../Components/Testing/tips/tips.component";
import { DynamicVoucherComponent } from "../Components/CustomerApp/dynamic-voucher/dynamic-voucher.component";

import { InvoiceSave } from "../Classes/invoice-save";
import { InvoiceSplitterComponent } from "../Components/Instellingen/invoice-splitter/invoice-splitter.component";
import { EmailComponent } from "../Components/Testing/email/email.component";
import { TheEndTVComponent } from "../components/Testing/the-end-tv/the-end-tv.component";
import { PaymentsComponent } from "../Components/Controle/payments/payments.component";
import { CreateBarComponent } from "../Components/Tools/create-bar/create-bar.component";
import { FlagsComponent } from "../Components/CustomerApp/flags/flags.component";

const routes: Routes = [
  { path: "walletpassthrough", component: WalletpassthroughComponent },
  { path: "login", component: LoginComponent },
  { path: "", component: HomeComponent },

  {
    path: "controle",
    component: ControleComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 2 },
  },
  {
    path: "inkomsten",
    component: InkomstenComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 4 },
  },
  {
    path: "personeel",
    component: PeroneelComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 2 },
  },
  {
    path: "bestellingen",
    component: BestellingenComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 4 },
  },
  {
    path: "onderhoud",
    component: TasksComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 2 },
  },
  {
    path: "instellingen",
    component: InstelligenComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 2 },
  },
  {
    path: "account",
    component: AccountComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 0 },
  },

  {
    path: "help",
    component: HelpComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 0 },
  },
  {
    path: "account/documents",
    component: DocumentsComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 1 },
  },
  {
    path: "account/hours",
    component: HoursComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 1 },
  },
  {
    path: "account/schedule",
    component: SetScheduleComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 1 },
  },

  {
    path: "personeel/hours",
    component: StaffhoursComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 2 },
  },
  {
    path: "personeel/cleaning",
    component: StaffhoursComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 2 },
  },
  {
    path: "bestellingen/NDOrder",
    component: NDOrderComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 4 },
  },
  {
    path: "bestellingen/NDOrders",
    component: NDOrdersComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 4 },
  },
  {
    path: "bestellingen/NDOrderItems",
    component: NDOrderItemsComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 4 },
  },
  {
    path: "personeel/staffinfo",
    component: StaffInfoComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 3 },
  },
  {
    path: "controle/counting",
    component: CountingComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 2 },
  },
  {
    path: "controle/countinghq",
    component: CountingHQComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 5 },
  },
  {
    path: "controle/ndcountinghq",
    component: NDCountingHQComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 5 },
  },
  {
    path: "controle/tips",
    component: TipsComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 5 },
  },
  {
    path: "controle/payments",
    component: PaymentsComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 4 },
  },
  {
    path: "controle/tables",
    component: TablesComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 4 },
  },
  {
    path: "controle/shiftcheck",
    component: ShiftCheckComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 4 },
  },
  {
    path: "instellingen/InkoopKosten",
    component: InkoopKostenComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 5 },
  },
  {
    path: "instellingen/NDOHQStock",
    component: NDOStockHQComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 5 },
  },
  {
    path: "instellingen/messages",
    component: MessagesComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 2 },
  },
  {
    path: "instellingen/shifts",
    component: ShiftsComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 2 },
  },
  {
    path: "instellingen/bar",
    component: BarComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "personeel/schedule2",
    component: Schedule2Component,
    canActivate: [AuthGuard],
    data: { roleLevel: 2 },
  },
  {
    path: "personeel/documents",
    component: DocumentManagerComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 4 },
  },
  {
    path: "controle/till",
    component: TillComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 2 },
  },
  {
    path: "controle/telling",
    component: TellingComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "controle/dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 3 },
  },
  {
    path: "bestellingen/orders",
    component: DrinkOrdersComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 4 },
  },
  {
    path: "instellingen/stock",
    component: StockComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 3 },
  },
  {
    path: "personeel/staffdrinks",
    component: StaffDrinksComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "personeel/weeklygift",
    component: WeeklyGiftComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 4 },
  },
  {
    path: "personeel/stafforders",
    component: StaffOrdersComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 3 },
  },

  {
    path: "instellingen/vipRooms",
    component: VipRoomsComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 3 },
  },
  {
    path: "instellingen/tests",
    component: TestersComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "instellingen/lostandfound",
    component: LostandfoundComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 2 },
  },
  {
    path: "instellingen/expenses",
    component: ExpensesComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "instellingen/tips",
    component: TipsComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 3 },
  },
  {
    path: "instellingen/devices",
    component: DevicesComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "tools/tutorials",
    component: TutorialsComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 1 },
  },
  {
    path: "tools/cctv",
    component: CCTVComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 5 },
  },
  {
    path: "instellingen/reviews",
    component: ReviewsComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 3 },
    canDeactivate: [ComponentCanDeactivateGuard],
  },
  {
    path: "customerapp",
    component: CustomerAppComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 1 },
  },
  {
    path: "customerapp/catalogue",
    component: CatalogueComponent,
    canActivate: [AuthGuard],
    data: {
      roleLevel: 5,
      accessEmail: "duncansaunders1997@gmail.com, mit-van-rijswijk@live.nl",
    },
  },
  {
    path: "customerapp/customers",
    component: CustomersComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 3 },
  },
  {
    path: "customerapp/live",
    component: LiveComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 3 },
  },
  {
    path: "customerapp/songpricing",
    component: SongpricingComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "customerapp/songemulating",
    component: EmulatorComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "dynamicvoucher",
    component: DynamicVoucherComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 3 },
  },
  {
    path: "customerapp/usage",
    component: UsageComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 4 },
  },
  {
    path: "customerapp/requests",
    component: SongRequestsComponent,
    canActivate: [AuthGuard],
    data: {
      roleLevel: 4,
      accessEmail: "duncansaunders1997@gmail.com, mit-van-rijswijk@live.nl",
    },
  },
  {
    path: "customerapp/canDutch",
    component: CanDutchComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "customerapp/statistics",
    component: StatisticsComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 1 },
  },
  {
    path: "testing",
    component: TestingComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "testing/bonus",
    component: BonusComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "testing/CCTV",
    component: CCTVComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "testing/theendtv",
    component: TheEndTVComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "testing/tipschooser",
    component: TipsSettingsComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 3 },
  },
  {
    path: "testing/competition",
    component: CompetitionComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "testing/creditSim",
    component: SongCreditSimulationComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "testing/inkoop",
    component: InkoopComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "instellingen/access",
    component: AccessLevelsComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "instellingen/invoicesplitter",
    component: InvoiceSplitterComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 5 },
  },
  {
    path: "testing/bookings",
    component: BookingAPIComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "testing/working",
    component: WorkingComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "testing/map",
    component: MapComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 2 },
  },
  {
    path: "testing/email",
    component: EmailComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "testing/detailedturnover",
    component: DetailedTurnoverComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 4 },
  },
  {
    path: "customerapp/wipecredit",
    component: WipeCreditComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "customerapp/voucher",
    component: VoucherComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "customerapp/flags",
    component: FlagsComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 4 },
  },
  {
    path: "personeel/payslipuploader",
    component: PayslipUploaderComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 4 },
  },
  {
    path: "tools/invoicemaker",
    component: InvoiceMakerComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 4 },
  },
  {
    path: "tools/ChangeRecords",
    component: ChangeRecordsComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "tools/createBar",
    component: CreateBarComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 6 },
  },
  {
    path: "tools",
    component: ToolsComponent,
    canActivate: [AuthGuard],
    data: { roleLevel: 1 },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

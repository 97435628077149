<h1>Live Song Pricing</h1>


<h2>Weights</h2>
<app-input type="number" name="Last 5 Minutes (previous 5)" [model]="model.model.customWeights.last5Minutes" (changed)="model.model.customWeights.last5Minutes = $event"></app-input>
<app-input type="number" name="Middle 5 Minutes (started 10 minutes ago)" [model]="model.model.customWeights.middle5Minutes" (changed)="model.model.customWeights.middle5Minutes= $event"></app-input>
<app-input type="number" name="First 5 Minutes (started 15 minutes ago)" [model]="model.model.customWeights.first5Minutes" (changed)="model.model.customWeights.first5Minutes = $event"></app-input>

<app-input type="number" name="Last 15 Minutes (previous 15)" [model]="model.model.customWeights.last15Minutes" (changed)="model.model.customWeights.last15Minutes = $event"></app-input>

<app-input type="number" name="Last Hour" [model]="model.model.customWeights.lastHour" (changed)="model.model.customWeights.lastHour = $event"></app-input>

<app-input [editable]="false" type="checkbox" name="Normalise" [model]="model.model.normalise" (changed)="model.model.normalise = $event"></app-input>

<app-input type="number" name="Minimum Price" [model]="model.model.minimumPrice" (changed)="model.model.minimumPrice = $event"></app-input>
<app-input type="number" name="Max Up" [model]="model.model.maxUp" (changed)="model.model.maxUp = $event"></app-input>
<app-input type="number" name="Max Down" [model]="model.model.maxDown" (changed)="model.model.maxDown = $event"></app-input>

<button (click)="saveModel()">Save</button>
<button (click)="dismiss()">Cancel</button>

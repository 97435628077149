import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep } from "lodash";

import { Observable } from "rxjs";
import { Artist } from "src/app/Classes/artist";
import { Song } from "src/app/Classes/song";
import { Firebase } from "src/app/Services/firebase";

import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/Helpers/directive/sort-event.directive";

import { EditArtistComponent } from "src/app/Packages/edit-artist/edit-artist.component";
import { EditSongComponent } from "src/app/Packages/edit-song/edit-song.component";
import { MoveSongComponent } from "src/app/Packages/move-song/move-song.component";

@Component({
  selector: "app-catalogue",
  templateUrl: "./catalogue.component.html",
  styleUrls: ["./catalogue.component.css"],
})
export class CatalogueComponent implements OnInit {
  lastClicked = "";

  countries$: Observable<Artist[]>;
  total$: Observable<number>;

  artist: Artist;
  song: Song;

  songForm = new FormControl();

  showSongsToAdd = false;
  songsToAdd: any[] = [];

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    public service: Firebase,
    private modal: NgbModal,
    private fb: FormBuilder
  ) {
    this.countries$ = service.countries$;
    this.total$ = service.total$;
  }
  ngOnInit(): void {
    this.service.init();
    this.getSongsToAdd();
  }

  async getSongsToAdd() {
    this.songsToAdd = await this.service
      .getSongsToAdd()
      .then((a) => a)
      .catch((e) => []);
    console.log(this.songsToAdd);
  }

  async added(request) {
    await this.service.addedSongToAdd(request);
    await this.getSongsToAdd();
  }
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  edit(artist: Artist) {
    console.log("editing " + artist.name);
    const modal = this.modal.open(EditArtistComponent, { size: "lg" });
    modal.componentInstance.artist = cloneDeep(artist);
    modal.result
      .then(
        (fulfilled) => {},
        (rejected) => {}
      )
      .catch((e) => {
        console.error(e);
      });
  }

  addArtist() {
    const artist = new Artist();
    const modal = this.modal.open(EditArtistComponent, { size: "lg" });
    modal.componentInstance.artist = artist;
    modal.result
      .then(
        (fulfilled) => {},
        (rejected) => {}
      )
      .catch((e) => {
        console.error(e);
      });
  }

  addSong(artist: Artist) {
    const song = new Song();
    console.log("creating song for artist " + artist.name);

    song.artist = artist.name;
    var nextIndex = artist.songs.length;
    for (var i = 0; i < artist.songs.length; i++) {
      if (Number(artist.songs[i].index) != i) {
        console.log(artist.songs);
        nextIndex = i;
        console.log("pre missing song", i);
        break;
      }
    }

    song.index = String(nextIndex);
    song.key = artist.key + "_" + nextIndex;

    console.log("adding " + artist.name);
    const modal = this.modal.open(EditSongComponent, { size: "lg" });
    modal.componentInstance.song = cloneDeep(song);
    modal.result
      .then(
        (fulfilled) => {},
        (rejected) => {}
      )
      .catch((e) => {
        console.error(e);
      });
  }

  editSong(content: any, artist: Artist, song: Song) {
    console.log("editing " + artist.name);
    const modal = this.modal.open(EditSongComponent, { size: "lg" });
    modal.componentInstance.song = cloneDeep(song);
    modal.result
      .then(
        (fulfilled) => {},
        (rejected) => {}
      )
      .catch((e) => {
        console.error(e);
      });
  }

  moveSong(content: any, artist: Artist, song: Song) {
    console.log("moving, artist => song", artist, song);
    console.log("editing " + artist.name);
    const modal = this.modal.open(MoveSongComponent, { size: "lg" });
    modal.componentInstance.song = cloneDeep(song);

    modal.result
      .then(
        (fulfilled) => {
          this.service.init();
        },
        (rejected) => {
          this.service.init();
        }
      )
      .catch((e) => {
        console.error(e);
      });
  }
}

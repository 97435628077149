<div class="flex-rows">
    <div class="flex-columns">
        <label>Date:</label>
        <input type="date" [ngModel]="date | date:'yyyy-MM-dd'" (ngModelChange)="date = $event">
        <div class="divider"></div>
        <label>Upload PDF:</label>
        <input type="file" accept="application/pdf" (change)="handleFileInput($event.target.files)" style="margin-bottom: 10px" />
        <button *ngIf="fileToUpload && !pageNames.length" (click)="upload()">Decipher</button>

        <button *ngIf="pageNames.length" (click)="distribute()">Distribute</button>
        <div class="divider"></div>
        <button (click)="cancel()">Cancel / Reset</button>
    </div>

    <div class="flex-columns" *ngIf="pageNames.length">
        <div class="pagenames">
            <div>Page No:</div>
            <div>IBAN</div>
            <div>Name?</div>
            <div>User</div>
            <div style="width: 150px;">Paid</div>
            <div>Status</div>
        </div>

        <div *ngFor="let page of pageNames" class="pagenames">
            <div>{{ page.index }}</div>
            <div>{{ page.iban }}</div>
            <div>{{ page.name }}</div>
            <div>
                <select [(ngModel)]="page.id" (change)="assignUser(page)">
      <option [value]="">Unknown</option>
      <option *ngFor="let user of staff" [value]="user.uid">
        <span *ngIf="user.firstName && user.lastName">
          {{ user.firstName.substr(0, 3) + ". " }} {{ user.lastName }}
        </span>
      </option>
    </select>
            </div>
            <input type="number" [(ngModel)]="page.paid" style="width: 150px;">
            <div class="status" [style.background-color]="!page.user ? 'red' : (page.uploaded ? 'green' :'orange') "></div>
        </div>
    </div>



</div>
<div class="page-header">
    <div>
        {{ date | date: "EEEE dd/MM/yy" }}
    </div>

    <button (click)="showCalendar = !showCalendar">
<i class="fas fa-chevron-right"></i>
</button>

    <div style="flex-grow: 1"></div>

    <div>Preview</div>
</div>

<div class="calendar-popup" [style.display]="showCalendar ? 'flex' : 'none'">
    <app-my-calendar></app-my-calendar>
</div>


<div class="generic-table-header">
    <div class="generic-table-header-item long">Name</div>
    <div class="generic-table-header-item long">Previous</div>
    <div class="generic-table-header-item short">Next</div>
    <div class="generic-table-header-item short">+/-</div>
</div>

<div class="generic-table" *ngIf="selectedDate && startCount">
    <div class="generic-table-row" *ngFor="let s of incorrect(startCount, all2)" [ngClass]="{ red: -(s.quantity - s.counted) != 0 }" (click)="openTip(tip)">
        <div class="generic-table-item long">
            {{ s.name }}
        </div>

        <div class="generic-table-item long">
            {{ s.quantity }}
        </div>

        <div class="generic-table-item short">
            {{ s.counted }}
        </div>
        <div class="generic-table-item short">
            {{ -(s.quantity - s.counted) }}
        </div>
    </div>
</div>


<!--
<div class="page-overview" *ngIf="selectedDate">

  <div class="page-box-out" style="width: 100%;">


      <div class="page-box-sub" style="flex-grow: 1;" *ngIf="selectedDate && startCount">
          <h3>Start {{ selectedDate }}</h3>
          <div class="even-row">
              <button (click)="all2 = !all2">
{{ all2 ? "Show Only Incorrect" : "Show All" }}
</button>
              <button (click)="moreInfo2 = !moreInfo2">
{{ moreInfo2 ? "Less Info" : "More Info" }}
</button>
          </div>




          <table *ngIf="startCount" class="table">
              <tr class="table-row">
                  <th class="table-column sticky-col">Name</th>
                  <th class="table-column">Previous</th>
                  <th class="table-column">Next</th>
                  <th class="table-column italic">Difference</th>
              </tr>



              <tr class="table-row">
                  <th class="table-column sticky-col">{{ s.name }}</th>

                  <th class="table-column">{{ s.quantity }}</th>
                  <th class="table-column">{{ s.counted }}</th>

                  <th class="table-column italic">{{ -(s.quantity - s.counted) }}</th>

              </tr>


          </table>
      </div>

  </div>




</div>
-->

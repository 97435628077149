import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toColor'
})
export class ToColorPipe implements PipeTransform {

  transform(str: string, ...args: unknown[]): unknown {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }

    if (!args[0]) {
      return colour;
    } else {
      return this.calculateInverseColor(colour);
    }


  }

  calculateInverseColor(hexColor: string): string {
    // Remove the "#" character and parse the color string to an integer
    const colorValue = parseInt(hexColor.substring(1), 16);

    // Invert the color by performing a bitwise NOT operation on the color value
    const invertedColorValue = 0xFFFFFF ^ colorValue;

    // Convert the inverted color value back to a hex string and pad with zeros if necessary
    const invertedHexColor = "#" + invertedColorValue.toString(16).padStart(6, "0");

    return invertedHexColor;
  }

}

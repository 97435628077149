import { merge } from "lodash";

export class RecurringExpense {
  key: string = '';
  name: string ='';
  price: number = 0;
  startDate: number = (new Date()).getTime();
  endDate: number = (new Date()).getTime();

  constructor(e = {}) {
    merge(this,e);
  }

  isValid(){
    console.log(this)
    if(this.name && !isNaN(this.price) && this.startDate < this.endDate) {
      return true;
    }
    return false;
  }
}

<div class="login-form">
  <h1>Login</h1>
  <input type="text" placeholder="Email" required autofocus #email style="width: 100%;">
  <input type="password" placeholder="Password" required #password style="width: 100%;">

  <div style="flex-grow: 1;"></div>

  <button class="text-button" (click)="reset()">Reset Password</button>

  <button class="green full-button" (click)="login()">Login </button>



</div>

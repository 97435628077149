import { Component, OnInit } from '@angular/core';
import { CalendarManager } from 'src/app/Classes/my-calendar/calendar-manager';
import { BarInfo } from 'src/app/Helpers/functions/BarInfo';
import { AlertsService } from 'src/app/Packages/alerts/alerts.service';
import { LoadingService } from 'src/app/Services/loading.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';

@Component({
  selector: 'app-shift-check',
  templateUrl: './shift-check.component.html',
  styleUrls: ['./shift-check.component.css']
})
export class ShiftCheckComponent implements OnInit {
  date: Date = new Date();
  endDate: Date = new Date();
  shifts: ShiftReport[] = [];
  sortDirection: number = 1;
  total = {
    shifts: 0,
    shirts: 0,
    onTime: 0,
  }
  sortingOn: string = "date";
  constructor(public db: StaffAppDatabase, public alert: AlertsService, public loading: LoadingService,public bars: BarInfo, public cal: CalendarManager) {
    this.date.setHours(13,0,0,0)
    this.endDate.setHours(13,0,0,0)
    this.date.setDate(1);
    this.endDate.setMonth(this.date.getMonth()+1);
    this.endDate.setDate(0);
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(){
    this.cal.destroy();
  }

  async getData(){
    this.loading.show();
    var shifts = (await this.db.getShiftReport(this.bars.bar,this.date, this.endDate));
    this.shifts = shifts.map(shift => {
      shift.date = new Date(shift.dateAdded);
      return shift;
    }).filter(a => {
      if(this.inclusiveRangeDates(a.date,this.date, this.endDate)) return a;
    })




    this.total = {
      shifts: 0,
      shirts: 0,
      onTime: 0,
    }
    this.shifts.forEach(shift => {
      if(shift.arriveOnTime) this.total.onTime++;
      if(shift.shirts) this.total.shirts++;
      this.total.shifts++;
    });

    console.log(this.shifts);
    this.loading.hide();
  }

  dateChanged(isStart:boolean, event) {
    if(isStart) this.date =new Date(event);
    else this.endDate = new Date(event);

    console.log(this.date,this.endDate);
  }

  inclusiveRangeDates(date: Date, start: Date, end: Date){
    //is same day as start or end
    if(this.sameDay(date,start) || this.sameDay(date,end)) return true;
    //is between start and end
    return date > start && date < end;

  }

  sameDay(date1: Date, date2: Date){
    return date1.getFullYear() == date2.getFullYear() && date1.getMonth() == date2.getMonth() && date1.getDate() == date2.getDate();
  }

  sortBy(str) {

  }

}

export class ShiftReport {
  arriveOnTime: boolean | null = null;
  comment: string = "";
  dateAdded: Date | null = null;
  shirts: boolean | null= null;
  date: Date = null;
}

import { Component, OnInit } from "@angular/core";
import { take, takeWhile } from "lodash";
import { takeUntil } from "rxjs/operators";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { LoadingService } from "src/app/Services/loading.service";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-inkoop",
  templateUrl: "./inkoop.component.html",
  styleUrls: ["./inkoop.component.css"],
})
export class InkoopComponent implements OnInit {
  start = new Date();
  result: any;
  bar: string = "";
  subs = [];
  constructor(
    private db: StaffAppDatabase,
    private bi: BarInfo,
    private info: AlertsService,
    private loading: LoadingService
  ) {}

  ngOnInit(): void {
    this.getBar();
  }

  async getBar() {
    this.subs.push(
      this.bi.barObserver.subscribe((var2) => {
        this.bar = var2;
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((a) => a.unsubscribe());
  }

  async getData() {
    if (!this.bar) {
      this.info.nextEmit(AlertsService.error("No bar", ""));
      console.error("no bar");
      return;
    }

    this.loading.nextEmit("on");

    var res;
    try {
      console.log(this.bar, this.start);
      res = await this.db
        .testInkoop(this.bar, this.start)
        .then((a) => a)
        .catch((e) => e);
      console.log(res);
      this.result = res;
    } catch (e) {
      this.result = e;
      console.log(e);
      this.info.nextEmit(
        AlertsService.error("Error Testiing Inkoop", e.toString())
      );
    }

    this.loading.nextEmit("");
  }
}

/**
 * API
 * # Introduction  Welcome to the TPAPI-POS API! unTill Server provides a REST interface for 3rd party applications which may be used for remotely working with POS: reading configuration, making orders, etc.  You can view code samples in the dark area to the right.  In case you might have any questions about our API, please create a new support [**ticket**](http://integrations.untill.com/support/tickets/new). If you don't have an account yet but already signed an [**NDA**](https://untill.com/nda), please send an email to [**systemintegration@untill.com**](mailto:systemintegration@untill.com). Please do not use this email address for support questions.  # User permissions  Each API function should be invoked with appropriate user permission. Please see function description. The following permissions can be assigned to a user in unTill Backoffice - Backoffice view - Backoffice modify - Ordering - Ordering: override price - Apply discounts - Payment - Client management - Reporting - Reservations - Misc
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ActiveOrder } from '../model/activeOrder';
import { ActiveTable } from '../model/activeTable';
import { Order } from '../model/order';
import { Table } from '../model/table';
import { TableCourse } from '../model/tableCourse';

import { BASE_PATH } from 'src/variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OrderingService {

    protected basePath = 'http://82.73.157.201:{port}/api/v1/{dbname}';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * List active orders
     * List active orders. Required user permission: Ordering
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activeOrderGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ActiveOrder>>;
    public activeOrderGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ActiveOrder>>>;
    public activeOrderGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ActiveOrder>>>;
    public activeOrderGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ActiveOrder>>('get',`${this.basePath}/active-order`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find active table
     * Find active table by parameters. Required user permission: Ordering
     * @param tableNumber Serach active table by specified table number
     * @param tablePart Serach active table by specified table part
     * @param smartCardUid Serach active table by specified smart card UID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activeTableGet(tableNumber?: number, tablePart?: string, smartCardUid?: string, observe?: 'body', reportProgress?: boolean): Observable<ActiveTable>;
    public activeTableGet(tableNumber?: number, tablePart?: string, smartCardUid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ActiveTable>>;
    public activeTableGet(tableNumber?: number, tablePart?: string, smartCardUid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ActiveTable>>;
    public activeTableGet(tableNumber?: number, tablePart?: string, smartCardUid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tableNumber !== undefined && tableNumber !== null) {
            queryParameters = queryParameters.set('tableNumber', <any>tableNumber);
        }
        if (tablePart !== undefined && tablePart !== null) {
            queryParameters = queryParameters.set('tablePart', <any>tablePart);
        }
        if (smartCardUid !== undefined && smartCardUid !== null) {
            queryParameters = queryParameters.set('smartCardUid', <any>smartCardUid);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ActiveTable>('get',`${this.basePath}/active-table`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create an order
     * Create an order. Required user permission: Ordering
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orderPost(body: Order, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public orderPost(body: Order, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public orderPost(body: Order, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public orderPost(body: Order, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling orderPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/order`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set table course
     * Set table course. Required user permission: Ordering
     * @param body Set table course by ID. If course ID is absent then next table course is set
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tableCoursePost(body: TableCourse, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public tableCoursePost(body: TableCourse, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public tableCoursePost(body: TableCourse, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public tableCoursePost(body: TableCourse, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling tableCoursePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/table-course`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List items on the active table
     * This function returns a consolidated list of ordered items on the table, in the same way they are displayed in the order screen at POS. Each item has a signature which can be used in per-items operations, e.g. when applying discounts. Required user permission: Ordering
     * @param tableNumber Serach table by specified table number
     * @param tablePart Serach table by specified table part
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tableGet(tableNumber: number, tablePart: string, observe?: 'body', reportProgress?: boolean): Observable<Table>;
    public tableGet(tableNumber: number, tablePart: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Table>>;
    public tableGet(tableNumber: number, tablePart: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Table>>;
    public tableGet(tableNumber: number, tablePart: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tableNumber === null || tableNumber === undefined) {
            throw new Error('Required parameter tableNumber was null or undefined when calling tableGet.');
        }

        if (tablePart === null || tablePart === undefined) {
            throw new Error('Required parameter tablePart was null or undefined when calling tableGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tableNumber !== undefined && tableNumber !== null) {
            queryParameters = queryParameters.set('tableNumber', <any>tableNumber);
        }
        if (tablePart !== undefined && tablePart !== null) {
            queryParameters = queryParameters.set('tablePart', <any>tablePart);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Table>('get',`${this.basePath}/table`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

import { Injectable } from "@angular/core";
@Injectable()
export class UserAccessLevels{
    readonly SYSTEM_ADMIN = 5;
    readonly ADMIN = 4;
    readonly MANAGER = 3;
    readonly STAFF = 2;
    readonly GUEST = 1;
    readonly BLOCKED = 0;

    pretty = ['Blocked','Guest','Staff','Manager','Admin','System Admin'];

    printLevel(level){
        return this.pretty[level];
    }

    getLevels(){
        return [0,1,2,3,4,5];
    }
}
<div class="staff-info-title">
    <div class="action-side"></div>
    <h3 style="margin: 5px 0px 2px 0px">{{ drink.name | capitalize }}</h3>
    <button class="action-side close-button red" (click)="activeModal.dismiss()">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="actions actions-header">
    <button class="reactive-button-title" (click)="tab = 0" [ngClass]="tab == 0 ? 'green' : ''">
    General
  </button>
    <button class="reactive-button-title" (click)="tab = 1" [ngClass]="tab == 1 ? 'green' : ''">
    Bar
  </button>
    <button class="reactive-button-title" (click)="tab = 2" [ngClass]="tab == 2 ? 'green' : ''">
    Invoice
  </button>
</div>

<ng-container *ngIf="tab == 0">
    <div class="edit-row">
        <div class="edit-desc">Drink Name:</div>
        <input class="edit" [(ngModel)]="drink.name" name="name" />
    </div>

    <div class="edit-row">
        <div class="edit-desc">Description:</div>
        <input class="edit" [(ngModel)]="drink.description" name="name" />
    </div>

    <div class="edit-row">
        <div class="edit-desc">Category:</div>
        <select *ngIf="!category" [(ngModel)]="drink.category" [disabled]="!!drink.category">
      <option *ngFor="let c of categories" [ngValue]="c">{{ c }}</option>
    </select>
        <p>{{ category }}</p>
    </div>

    <div class="edit-row">
        <div class="edit-desc">Server Id:</div>
        <div class="edit" *ngIf="barName !== 'Daga Beheer'">
            {{ drink.serverId ? drink.serverId : drink.id }}
        </div>
        <input *ngIf="barName === 'Daga Beheer'" class="edit" [(ngModel)]="drink.serverId" />
    </div>
</ng-container>

<ng-container *ngIf="tab == 1">
    <div class="edit-row">
        <div class="edit-desc">Price (€)</div>

        <input *ngIf="drink.price" class="edit" [(ngModel)]="drink.price" name="price" />
        <input *ngIf="drink.prices" class="edit" type="number" [(ngModel)]="drink.prices[0].amount" name="price" />
        <input *ngIf="barName === 'Daga Beheer'" class="edit" type="number" [(ngModel)]="drink.price" name="price" />
    </div>

    <div class="edit-row" *ngIf="barName === 'Daga Beheer'">
        <div class="edit-desc">Hoofdkantoor Quantity</div>

        <input class="edit" type="number" type="number" [(ngModel)]="drink.tillQuantity" name="price" />
    </div>

    <div class="edit-row">
        <div class="edit-desc">Crate Size</div>
        <input class="edit" type="number" [(ngModel)]="drink.crateSize" name="defaultCrateSize" />
    </div>

    <div class="edit-row">
        <div class="edit-desc">Default Fridge Stock</div>
        <input class="edit" type="number" [(ngModel)]="drink.defaultFridgeStock" name="defaultFridgeStock" />
    </div>

    <div class="edit-row">
        <div class="edit-desc">Default Quantity</div>
        <input class="edit" type="number" [(ngModel)]="drink.defaultQuantity" name="defaultQuantity" />
    </div>

    <div class="edit-row" *ngIf="drink.category == 'crates'">
        <div class="edit-desc">Offset</div>
        <div style="display: flex; flex-direction: row; align-items: center;width: 100%;">
            <input class="edit" style="width: auto !important; flex-grow: 1;" [disabled]="auth.accessUser.access < 5" type="number" [(ngModel)]="drink.offset" name="Offset" />
            <button *ngIf="auth.accessUser.access >= 5" (click)="saveOffset()">   Save
          </button>
        </div>


    </div>

    <div class="edit-row" style="display: flex; flex-direction: column; align-items: center;">
        <div style="align-self: flex-start;" class="edit-desc">Till Category:</div>

        <ng-select bindLabel="tillCategory" ngDefaultControl placeholder="Select Category(ies)">
            <ngx-bootstrap-multiselect [options]="tillCategories" [(ngModel)]="drink.tillCategory" (ngModelChange)="tillCategoryChanged($event)"></ngx-bootstrap-multiselect>
        </ng-select>
    </div>
</ng-container>

<ng-container *ngIf="tab == 2">
    <div class="edit-row">
        <div class="edit-desc" (click)="
        editVariable(category, {
          key: 'embellage',
          name: 'Embellage',
          drink: drink,
          type: 'number'
        })
      ">
            Emballage
        </div>
        <input class="edit" type="number" [(ngModel)]="drink.embellage" name="embellage" />
    </div>

    <div class="edit-row">
        <div class="edit-desc">
            Delivery Price (per CrateSize) (PPU) (€)
        </div>

        <input class="edit" disabled ngbTooltip="Edit from InkoopKosten" [(ngModel)]="drink.pricePerUnit" name="price2" />
    </div>
    <div class="edit-row">
        <div class="edit-desc">
            Percentage Increase (%)
        </div>

        <input class="edit" disabled ngbTooltip="Edit from InkoopKosten" [(ngModel)]="drink.percentage" name="price2" />
    </div>


    <div class="edit-row">
        <div class="edit-desc">
            Price Per Resale Unit (per CrateSize) (PPRU) (€)
        </div>

        <input class="edit" disabled ngbTooltip="Edit from InkoopKosten" [(ngModel)]="drink.resalePerUnit" name="price2" />
    </div>

    <div class="edit-row">
        <div class="edit-desc">
            Units (per cratesize / bottle):
        </div>

        <input class="edit" ngbTooltip="Edit from InkoopKosten" [ngModel]="drink.units" disabled name="price2" />
    </div>

    <div class="edit-row">
        <div class="edit-desc">
            Inkoop per unit (0 for none) (€):
        </div>

        <input class="edit" disabled ngbTooltip="Edit from InkoopKosten" [(ngModel)]="drink.singlePrice" disabled name="price2" />
    </div>

    <div class="edit-row">
        <div class="edit-desc" *ngIf="!drink.prices" (click)="
        editVariable(category, {
          key: 'vat',
          name: 'VAT',
          drink: drink,
          type: 'number'
        })
      ">
            VAT
        </div>
        <input class="edit" *ngIf="!drink.prices" [(ngModel)]="drink.vat" name="vat" />
        <input *ngIf="drink.prices" class="edit" type="number" [(ngModel)]="drink.prices[0].vat" name="vat" />
    </div>

    <div class="edit-row">
        <div class="edit-desc">Emballage Type:</div>
        <select [(ngModel)]="drink.emballageType">
      <option *ngFor="let c of emballageTypes" [ngValue]="c">{{ c }}</option>
    </select>
    </div>

    <div class="edit-row">
        <div class="edit-desc">Invoice Category:</div>
        <select [(ngModel)]="drink.invoiceCategory">
      <option *ngFor="let c of invoiceCategories" [ngValue]="c">
        {{ c }}
      </option>
    </select>
    </div>
</ng-container>

<div class="actions actions-footer">
    <button class="reactive-button-title green" ngbTooltip=" Save will update, change category, correctly modify the order, only saves to the Database not the till!" (click)="save(drink, drink.category)">
    Save
  </button>
    <button class="reactive-button-title green" ngbTooltip=" Save will update, change category, correctly modify the order, only saves to the Database not the till!" (click)="save(drink, drink.category,true)">
    Save And Next
  </button>
    <button class="reactive-button-title red" ngbTooltip=" Delete will only remove a drink from the Database not the till, i.e. if a drink is no longer available or has incorrect serverID you delete it, and can reimport it from the till drinks" (click)="delete(drink, drink.category)">
    Delete
  </button>
    <button class="reactive-button-title orange" (click)="dismiss()">
    Cancel
  </button>
</div>
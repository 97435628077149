import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';



const multiavatar = require('@multiavatar/multiavatar');

@Injectable({
  providedIn: 'root'
})
export class AvatarServiceService {
  theme = "A";
  part = "01";
  defaultTheme  = "A";
  defaultPart = "01";
  defaultSeed = "WellThisIsAMightyCoolSeed";
  seed = this.defaultSeed + "";
  defaultBackground = false;
  private uid;

  private source = new BehaviorSubject<any>(null);
  sourceObserver = this.source.asObservable();

  private editedSource = new BehaviorSubject<boolean>(false);
  editedObserver = this.editedSource.asObservable();


  constructor(public firebase: StaffAppDatabase,private sanitizer: DomSanitizer, private auth: AngularFireAuth) {

    this.auth.onAuthStateChanged(values => {
      if(values && values.uid){
        //console.log('gonna get avaar',values)
        this.uid = values.uid;
      } else {
        this.uid = null;
      }
    })
   }

   forget(){
     this.part = this.defaultPart;
     this.theme = this.defaultTheme;
     this.reinitAvatar();
   }


   cancel (){
     if(this.uid){
       this.getConfig(this.uid);

     }
     this.editedSource.next(true);
   }

  reinitAvatar(){
    //console.log('changing');
    let svg2= multiavatar(this.seed, this.defaultBackground, {'part': this.part, 'theme': this.theme});
    this.source.next(this.sanitizer.bypassSecurityTrustHtml(svg2));
  }

  nextPart(){
    switch(this.theme) {
      case 'A':
        this.theme = 'B';
        break;
      case 'B':
        this.theme = 'C';
        break;
      case 'C':
        this.theme = 'A';
    }
    this.reinitAvatar();
  }

  nextTheme(){
    let temp = Number(this.part);
    temp++;
    if(temp > 15){
      temp = 1;
    }
    this.part = (temp < 10 ? '0' : '') + temp + '';
    //console.log(this.part);
    this.reinitAvatar();
  }

  getConfig(uid){
    //console.log(uid, this.firebase);
    this.firebase.getAvatarConfig(uid).subscribe((values: any[]) => {
      //console.log(values.length == 2);
      if(values && values.length == 2){
        this.part = values[0];
        this.theme = values[1];
        //console.log("got values!");
      }
      this.reinitAvatar();
    })
  }

  saveConfig(){
    if(this.uid){
      //console.log(this.part, this.theme);
      this.firebase.saveAvatarConfig(this.uid,{'part': this.part, 'theme': this.theme})
    }
    this.editedSource.next(true);

  }







}

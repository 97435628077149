<div class="paginator">
    <input type="file" multiple="true" (change)="onFilesSelected($event)" accept="application/pdf">
    <button (click)="clear()">X</button>
    <button style="margin-left: 5px;" (click)="reviewAndSend()" [disabled]="!checkViews()">Review And Send</button>
</div>


<div class="paginator">
    <div *ngFor="let f of selectedFiles; let i = index" (click)="pageChanged(i)" [class]="{'green':f.assignedBar && f.email != 'n/a', 'orange': !f.assignedBar, 'red': f.assignedBar && f.email == 'n/a'}">{{i}}</div>
</div>
<div id="pdfDecider" #pdfDecider style="width: 100%; height: 0%;"></div>
<div class="pdf-decider" *ngIf="currentPDF">
    <pdf-viewer [src]="currentPDF.pdfUrl" [render-text]="true" [original-size]="false" [style.width]="pdfOptions.width" [style.height]="pdfOptions.height"></pdf-viewer>

    <i style="font-size: xx-large; margin: 0px 10px; align-self: center;" class="fas fa-chevron-right"></i>

    <div class="bar-list">
        <button class="action-button" *ngFor="let bar of bars" [class.selected]="currentPDF.assignedBar == bar || currentPDF.assignedBar == '*'+ bar" (click)="barClicked(bar)"> {{bar}}</button>
    </div>
</div>
<div class="flex-columns">
  <h2>Hours</h2>
  <app-input type="date" label="Choose Month" (changed)="dateChanged($event)"></app-input>
</div>

<div class="flex-columns"
     style="width: 90%; flex-grow:1; display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;">
  <h2>Hours for: {{date | date:'MMMM yyyy'}}</h2>
  <div class="calendar">
    <div class="weekdays">

      <div class="weekday">Mon</div>
      <div class="weekday">Tue</div>
      <div class="weekday">Wed</div>
      <div class="weekday">Thu</div>
      <div class="weekday">Fri</div>
      <div class="weekday">Sat</div>
      <div class="weekday">Sun</div>
    </div>

    <div class="days">
      <!-- Generate the calendar days here -->
      <!-- You can use Angular directives or JavaScript logic to dynamically generate the days -->
      <div class="day" *ngFor="let day of calendarDays"
           [ngClass]="{'disabled':!day.isCurrentMonth,'today':day.isToday ,'bordered': day.hours > 0}">
        <div>{{ day.date | date:'dd-MM' }}</div>
        <div>Hours: {{day.hours}}</div>
      </div>
    </div>
  </div>

  <div> Total Hours for {{date | date:'MMMM yyyy'}} = {{total}}</div>

</div>

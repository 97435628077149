import { Discount } from "./discount";
import { Omzet } from "./omzet";
import { Verbruik } from "./verbruik";

export class Overview {
    omzet;
    worked = [];
    recurring =  [];
    oneoff= [];
    orders=[];
    inout= [];
    kosten={};
    verbruik=new Verbruik();
    personel =  {totalHours: 0, totalCost: 0};
    bonus =  {
      net: 0,
      afterPercent: 0,
      perHour: 0,
      total: 0,
      changedTotal: 0
    };
    totalKosten = 0;

    constructor(forceChecks= false){
      this.omzet = new Omzet(forceChecks)
    }
}

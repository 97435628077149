import { Injectable } from "@angular/core";
@Injectable()
export class MyUnorg{

    viewportToPixels(value : string){
        var parts = value.match(/([0-9\.]+)(vh|vw)/)
    var q = Number(parts[1])
    var side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
    
    return side * (q/100)
    }

    static getArtistKeySongKeyArray(key){
        let str = '';
        let count = 0;
        let start = key;
        while(count < 100 && start.charAt(start.length-1) !== '_'){
          count++;
          str =start.charAt(start.length-1) + str;
          start = start.slice(0,start.length-1);
         
        }
        start= start.slice(0,start.length-1);
        console.log(start,str);
        return [start,str];
      }

      static validateEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
}
<button routerLink="bonus" routerLinkActive="true" class="button-primary">
  Bonus
</button>

<button routerLink="inkoop" routerLinkActive="true" class="button-primary">
  Inkoop
</button>

<button routerLink="bookings" routerLinkActive="true" class="button-primary">
  Booking API
</button>

<button routerLink="map" routerLinkActive="true" class="button-primary">
  Map
</button>

<button
  routerLink="detailedturnover"
  routerLinkActive="true"
  class="button-primary"
>
  Detailed Turnover
</button>

<button routerLink="working" routerLinkActive="true" class="button-primary">
  Working
</button>

<button routerLink="creditSim" routerLinkActive="true" class="button-primary">
  Credit Simulator
</button>

<button routerLink="competition" routerLinkActive="true" class="button-primary">
  Competition
</button>

<!--<button routerLink="CCTV" routerLinkActive="true" class="button-primary">
  CCTV
</button>-->

<button routerLink="tipschooser" routerLinkActive="true" class="button-primary">
  Tips
</button>

<button routerLink="email" routerLinkActive="true" class="button-primary">
  Email
</button>

<button routerLink="theendtv" routerLinkActive="true" class="button-primary">
  TheEndTV
</button>
<button (click)="testCheckContracts()" class="button-primary">
  Test Check Contracts
</button>

<div class="expandable-box">



    <div class="expandable-box-header">
        {{title}} {{subtitle}}
        <button (click)="expanded = !expanded">Expand</button>
    </div>
    <div *ngIf="expanded" class="expandable-box-content" [style.position]="isAbsolute ? 'absolute' : 'relative'" [style.top]="isAbsolute ? '50px' : '0px'">
        <ng-content></ng-content>
    </div>

</div>
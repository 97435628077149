<ng-template #tipSettings class="tip-settings-modal">
  <div *ngIf="tip" class="modal-header" style="color: black !important">
    <h2 class="modal-title" id="modal-basic-title">
      {{ tip.type | capitalize }}
    </h2>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="dismiss('Cross click')"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
      "
    >
      <i class="fas fa-close"></i>
    </button>
  </div>
  <div *ngIf="tip" class="modal-body" style="color: black !important">
    <h4>Type:</h4>
    <div
      style="
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      "
    >
      <button
        class="action-button"
        [class.selected]="tip.type === 'absolute'"
        ngbTooltip="An absolute value such as 0.5, 1, 2, 5 and 10"
        (click)="tip.type = 'absolute'"
      >
        Absolute
      </button>
      <button
        class="action-button"
        [class.selected]="tip.type === 'percentage'"
        ngbTooltip="A percentage value such as 5%, 10%, 15% and 20%"
        (click)="tip.type = 'percentage'"
      >
        Percentage
      </button>
      <button
        class="action-button"
        [class.selected]="tip.type === 'round'"
        ngbTooltip="Round to a number such as 0.5, 1, 5 and 10"
        (click)="tip.type = 'round'"
      >
        Round
      </button>
    </div>

    <h4>Amount:</h4>
    <app-input
      [model]="tip.amount"
      ngbTooltip="The amount to tip to"
      (changed)="setAmount(tip, $event)"
    ></app-input>
    <br />
    <h4>Examples (Order Amount ↦ Tip)</h4>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      "
    >
      <div *ngFor="let example of examples" class="tip-example">
        <div>{{ example | currency : "EUR" }}</div>
        <div>↦</div>
        <div>
          {{
            tip.amount | tipCalculator : tip.type : example | currency : "EUR"
          }}
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal-footer"
    style="
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: nowrap;
    "
  >
    <button class="action-button selected red" (click)="dismiss()">
      Cancel
    </button>
    <button class="action-button selected green" (click)="save()">Save</button>
  </div>
</ng-template>

<h3>Tip Options</h3>
<div class="tip-options">
  <button
    class="action-button selected"
    *ngFor="let tiptype of tipTypes"
    (click)="chooseTip(tiptype, tipSettings)"
  >
    {{ tiptype.type | capitalize }}:
    {{ tiptype.amount | tipType : tiptype.type }}
  </button>
</div>

<span
  >You have 6 default options, you can set them how you want. Customer can
  always type a number as well</span
>

<div class="count">

    <div class="count-name">Name</div>


    <div class="count-number">(Till </div>
    <div class="count-number"> + Offset)</div>
    <div class="count-number">=> Stock</div>
    <div class="count-number">+/-</div>







</div>

<div class="count-data-scrollable">
    <div *ngFor="let item of count" class="count">

        <div class="count-name">{{item.name}}</div>


        <div class="count-number">{{item.till}}</div>
        <div class="count-number">{{item.currentOffset == 0 ? "" : "-"}}{{item.currentOffset}}</div>
        <div class="count-number">{{item.counted - item.currentOffset}}</div>
        <div class="count-number">{{item.difference}}</div>


    </div>
</div>
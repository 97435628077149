export class Giftable {
    total: number;
    reason: string;
    type: 'Credit'
    to: string;
    from: string;

    constructor(){
        this.total = 0;
        this.reason = '';
        this.type = 'Credit';
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService } from '../alerts/alerts.service';
import { LoadingService } from 'src/app/Services/loading.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';

@Component({
  selector: 'app-edit-stock-order',
  templateUrl: './edit-stock-order.component.html',
  styleUrls: ['./edit-stock-order.component.css']
})
export class EditStockOrderComponent implements OnInit {
  categories = ["crates", "glasses", "wine", "kegs", "liqueur", "other"];
  selectedCategory = "crates";
  list: any[] = [];
  edited= false;
  @Input('stock') stock: any[];
  constructor(public activeModal: NgbActiveModal, public alert: AlertsService, public loading: LoadingService, public db: StaffAppDatabase) { }

  ngOnInit(): void {
    this.categoryChanged(this.selectedCategory);
  }

  categoryChanged(val) {
    if(this.edited && !confirm("You have unsaved changes, are you sure you want to change category?")) {
      return;
    }
    this.selectedCategory = val;
    this.list = this.stock.filter(x => x.category == val).sort((a, b) => a.id - b.id);
  }

  cancel() {
    if(this.edited && !confirm("Are you sure you want to cancel? You have unsaved changes")) {
      return;
    }
  }

  savePositioning(cat) {
    //save all positions for this category
    if(!this.edited) {
      this.alert.nextEmit(AlertsService.warning("No changes to save"));
      return;
    }

    if(!confirm("Are you sure you want to save the new positioning?")) {
      return;
    }

    this.loading.show();

    let promises = [];

    this.list.forEach((x, index) => {
      promises.push(this.db.updateStockOrderCat(x.serverId, index, cat));
    });

    Promise.all(promises).then(() => {
      this.loading.hide();
      this.alert.nextEmit(AlertsService.success("Positioning saved"));
      this.edited = false;

    }).catch(err => {
      this.loading.hide();
      this.alert.nextEmit(AlertsService.error("Failed to save positioning"));
    });


  }

  moveUp(index) {
    console.log(index);
    if (index == 0) return;
    this.edited = true;
    let temp = this.list[index - 1];
    this.list[index - 1] = this.list[index];
    this.list[index] = temp;
  }

  moveDown(index) {
    if (index == this.list.length - 1) return;
    this.edited = true;
    let temp = this.list[index + 1];
    this.list[index + 1] = this.list[index];
    this.list[index] = temp;
  }

}

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { NgbModal, NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";
import { clone, cloneDeep, concat, isBoolean, isNumber, keyBy, merge, mergeWith } from "lodash";
import { build } from "pdfjs-dist";
import { Subscription } from "rxjs";

import { BarInfo } from "src/app/Helpers/functions/BarInfo";

import { OverviewService } from 'src/app/Packages/overview.service';
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: 'app-bonus',
  templateUrl: './bonus.component.html',
  styleUrls: ['./bonus.component.css']
})
export class BonusComponent implements OnInit {

  subs: Subscription[] = [];
  bar = "";

  constructor(
    public overview: OverviewService,
    private bI: BarInfo,
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.bI.barObserver.subscribe((val) => {
        this.bar = val;
        if (val) {
          this.overview.getRange(this.bar);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((a) => a.unsubscribe());
  }
}

import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TipType } from "src/app/Classes/tip-type";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-tips",
  templateUrl: "./tips.component.html",
  styleUrls: ["./tips.component.css"],
})
export class TipsSettingsComponent implements OnInit {
  tipTypes: TipType[] = [];

  examples: number[] = [1, 5, 10, 15, 0.5, 2.5, 7.5];

  tip: TipType;
  constructor(
    public db: StaffAppDatabase,
    public alert: AlertsService,
    public loading: LoadingService,
    public modal: NgbModal
  ) {
    console.log(this.tipTypes);
  }

  ngOnInit(): void {
    this.getTips();
  }

  async getTips() {
    this.loading.nextEmit("on");
    this.tipTypes = await this.db.getTipTypes();
    if (this.tipTypes.length == 0) {
      this.alert.nextEmit(
        AlertsService.warning(
          "Some Tip Types Not Found",
          "Using default tip types"
        )
      );

      for (let i = 0; i < 6; i++) {
        var t = new TipType("absolute", this.examples[i]);
        t.position = i;
        await this.db.saveTipType(t);
      }

      return this.getTips();
    }

    this.tipTypes = this.tipTypes.sort((a, b) => {
      return a.position - b.position;
    });

    for (let i = 0; i < this.tipTypes.length; i++) {
      this.tipTypes[i].position = i;
    }

    console.log(this.tipTypes);
    this.loading.nextEmit(null);
  }

  chooseTip(t: TipType, template: any) {
    this.tip = Object.assign({}, t);
    console.log(this.tip, t);
    this.modal.open(template).result.then(
      (res) => {},
      (dis) => {
        console.log(dis);
      }
    );
  }

  dismiss() {
    this.modal.dismissAll();
  }

  save() {
    //save the modaled tip then dismiss
    console.log(this.tip);

    this.db
      .saveTipType(this.tip)
      .then((a) => {
        this.alert.nextEmit(
          AlertsService.good("Tip Type Saved", "Saved Successfully")
        );
        this.getTips();
      })
      .catch((e) => {
        this.alert.nextEmit(AlertsService.error("Error Saving Tip Type", e));
      });

    this.modal.dismissAll();
  }

  setAmount(t: TipType, amount: number) {
    t.amount = Number(amount);
  }
}

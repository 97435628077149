import { Component, OnInit } from "@angular/core";
import { promises } from "dns";
import { Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { Firebase } from "src/app/Services/firebase";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { LoadingService } from "src/app/Services/loading.service";
import { CustomerPipe } from "src/app/Pipes/customer.pipe";
import { SongPipe } from "src/app/Pipes/song.pipe";

@Component({
  selector: "app-live",
  templateUrl: "./live.component.html",
  styleUrls: ["./live.component.css"],
})
export class LiveComponent implements OnInit {
  subs: Subscription[] = [];
  demands: any[] = [];
  demandSub: Subscription;
  bar: string = "";
  constructor(
    private db: Firebase,
    private bi: BarInfo,
    private loading: LoadingService,
    private songPipe: SongPipe
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    this.loading.nextEmit("on");
    const bar = await this.bi.barObserver
      .pipe(take(1))
      .toPromise()
      .then((a) => a)
      .catch((e) => null);
    if (bar) {
      this.bar = bar;
    }

    this.play();
  }

  ngOnDestroy() {
    this.subs.forEach((a) => a.unsubscribe());
    this.demandSub.unsubscribe();
  }

  play() {
    if (this.bar) {
      this.demandSub = this.db
        .getSongDemands(this.bar)
        .subscribe(async (val) => {
          var temp = [];
          const promises: Promise<any>[] = [];

          for (let a of val) {
            const data = a.payload.val(); // DB Questions
            const user = a.payload.key;
            const songs = [];
            console.log(data)
            const userData = await new CustomerPipe(this.db).transform(user,[]);
            for (let key of Object.entries(data)) {
              songs.push({ key: key[0], time: (key[1] as any).timeRequested });
            }

            console.log(songs);
            for (let s = 0; s < songs.length; s++) {
              console.log(s)
              var splits = this.db.getArtistKey(songs[s].key);
              songs[s] = await new SongPipe(this.db).transform(splits[0], splits[1]);
              try {
                temp.push({ userKey: user, user: userData, song: songs[s], request: userData.waitingList[songs[s].key]});
              } catch(e) {
                alert(e);
              }

            }

            console.log({ user, songs })



          }
          this.demands = temp;
          console.log(this.demands);
          this.loading.nextEmit("");
        });
    }
  }

  pause() {
    this.demandSub.unsubscribe();
  }
}

<div class="flex-rows">


  <div class="flex-columns" style="min-width: 350px">
    <h1>Weekly Drinks</h1>
    <p style="max-width: 340px;">
      Default days are sunday to saturday, both dates must be selected. This is an irreversible operation so
      make sure dates are correct!
    </p>
    <div class="edit-row">
      <div class="edit-desc">
        Start Date
      </div>
      <input class="edit" [ngModel]="startDate | date:'yyyy-MM-dd'" (ngModelChange)="setDate(true,$event)" type="date"
             name="startDate" [disabled]="staffDrinks">
    </div>
    <div class="edit-row">
      <div class="edit-desc">
        End Date
      </div>
      <input class="edit" [ngModel]="endDate | date:'yyyy-MM-dd'" (ngModelChange)="setDate(false,$event)" type="date"
             name="endDate" [disabled]="staffDrinks">
    </div>

    <div class="divider"></div>

    <div class="button-row">
      <button class="datchev" style="background-color: red;" (click)="staffDrinks = null">Cancel</button>
      <button class="datchev" style="background-color: orange;" (click)="getStaffDrinks()"
              [disabled]="staffDrinks">Get</button>
      <button class="datchev" style="background-color: green;" (click)="gift()" [disabled]="!staffDrinks">Gift</button>

    </div>

    <div class="divider"></div>

    <div *ngIf="staffDrinks && staffDrinks.length && staff">
      Staff Drinks

      <div *ngFor="let drink of staffDrinks">
        {{staff[drink[0]] | name}} {{drink[1] | number:'1.2-2' | currency: 'EUR'}}
      </div>


    </div>
  </div>

  <div class="flex-columns" style="width: 400px; ">
    <h1>Past Credits</h1>

    <div class="button-row">
      <button class="datchev" style="background-color: red;" (click)="past = null">Cancel</button>
      <button class="datchev" style="background-color: green;" (click)="getPast()">Get</button>


    </div>
    <div class="divider">

    </div>
    <div *ngIf="past"
         style="max-height: 800px; overflow: auto; justify-content: flex-start !important; display: flex; flex-direction: column; align-items: flex-start;">
      <div *ngFor="let gift of past" class="flex column" style="width: 360px">
        <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between">
          <div>{{gift.date | date:'EEE dd LLL yyyy'}}</div>
          <div> {{gift.total | currency:'EUR'}}</div>

        </div>
        <div>
          {{gift.reason}}
        </div>

        <div
             style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start; overflow: auto;">
          <div *ngFor="let name of gift.staffNames" style="margin-right: 5px;">{{name}}</div>


        </div>

        <div class="divider"></div>


      </div>
    </div>


  </div>





</div>

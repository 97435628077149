import { Component, OnInit } from "@angular/core";
import { take } from "rxjs";
import { CreditInformation } from "src/app/Classes/CreditInformation";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { MyDate } from "src/app/Helpers/functions/MyDate";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-weekly-gift",
  templateUrl: "./weekly-gift.component.html",
  styleUrls: ["./weekly-gift.component.css"],
})
export class WeeklyGiftComponent implements OnInit {
  subs = [];

  barname;
  def: CreditInformation = new CreditInformation();
  barDef: CreditInformation = new CreditInformation();

  defOG = new CreditInformation();
  barOG = new CreditInformation();
  dates = new MyDate();
  managers = [];
  managersOg = [];
  staff = [];

  //weekly drinks
  startDate: Date = new Date();
  endDate: Date = new Date();
  staffDrinks: any[];
  past: any[];

  constructor(
    private db: StaffAppDatabase,
    private bI: BarInfo,
    private loading: LoadingService,
    private alert: AlertsService
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.bI.barObserver.subscribe((bar) => {
        if (bar) {
          this.barname = bar;

          this.subs.push(
            this.db
              .getStaffDrinksForBar(bar)
              .valueChanges()
              .subscribe((def: CreditInformation) => {
                if (def) {
                  this.barDef = def;
                  this.barOG = def;
                }
              })
          );
          this.subs.push(
            this.db
              .getStaffDrinksDefault()
              .valueChanges()
              .subscribe((def: CreditInformation) => {
                if (def) {
                  this.def = def;
                  this.defOG = def;
                }
              })
          );
          this.getStaff();
          this.subs.push(
            this.db.getStaff().subscribe((values) => {
              this.managers = [];
              values.forEach((s: any) => {
                if (Number(s.access) >= 3) {
                  this.managers.push(Object.assign({}, s));
                  this.managersOg.push(Object.assign({}, s));
                }
              });
            })
          );
        }
      })
    );
  }

  async getStaff() {
    this.staff = await (
      await this.db
        .getStaff()
        .pipe(take(1))
        .toPromise()
        .then((a) => a)
        .catch((e) => [])
    ).reduce((obj: any, item) => {
      return {
        ...obj,
        [item["uid"]]: item,
      };
    }, {});
  }

  getPast() {
    var tempStaff = Object.values(this.staff).filter(
      (a) => a.bar === this.barname
    );
    console.log(tempStaff);
    this.getStaffOrders(tempStaff);
  }

  async getStaffOrders(staff: any[]) {
    this.loading.nextEmit("on");
    var past: any = {};
    for (var i = 0; i < staff.length; i++) {
      let pastOrders = await this.db.getStaffOrders(staff[i].uid, "credit");
      const pastOrders2 = await this.db.getStaffOrders(staff[i].uid, "Credit");
      pastOrders = pastOrders.concat(pastOrders2);
      for (var j = 0; j < pastOrders.length; j++) {
        const date = (pastOrders[j].key / 10000).toFixed(0);
        console.log(date, pastOrders[j].value.reason);
        if (!past[date]) {
          past[date] = {
            reason: pastOrders[j].value.reason,
            staff: [],
            total: 0,
          };
        }
        past[date].staff.push(this.staff[staff[i].uid]);
        past[date].total += pastOrders[j].value.total;
      }
    }

    console.log(past);
    this.past = Object.entries(past)
      .map(([key, val]: [string, any]) => {
        return {
          reason: val.reason,
          staff: val.staff.length,
          staffNames: val.staff.map((a) => a.firstName),
          date: Number(key) * 10000,
          total: val.total,
        };
      })
      .sort((a, b) => {
        return b.date - a.date;
      })
      .slice(0, 9);
    console.log(this.past);
    this.loading.nextEmit(null);
  }
  clearDefault() {
    this.def = this.defOG;
  }

  clearBarDefault() {
    this.barDef = this.barOG;
  }

  saveDefault() {
    this.def.rate = Number(this.def.rate);
    this.def.targetRate = Number(this.def.targetRate);
    this.def.targetHours = Number(this.def.targetHours);
    this.def.newStaff = Number(this.def.newStaff);
    this.db.updateStaffDrinksDefault(this.def);
  }

  saveBarDefault() {
    this.barDef.rate = Number(this.barDef.rate);
    this.barDef.targetRate = Number(this.barDef.targetRate);
    this.barDef.targetHours = Number(this.barDef.targetHours);
    this.barDef.newStaff = Number(this.barDef.newStaff);
    this.db.updateStaffDrinksForBar(this.barname, this.barDef);
  }

  saveBarGlobalDefault() {
    this.def.rate = Number(this.def.rate);
    this.def.targetRate = Number(this.def.targetRate);
    this.def.targetHours = Number(this.def.targetHours);
    this.def.newStaff = Number(this.def.newStaff);
    this.db.updateStaffDrinksForBar(this.barname, this.def);
  }

  ngOnDestroy() {
    this.subs.forEach((a) => a.unsubscribe());
  }

  changedDefault(index, event) {
    if (Number(event.target.value)) {
      this.def.months[index] = Number(event.target.value);
    }
    event.target.value = null;
  }

  changedBar(index, event) {
    if (Number(event.target.value)) {
      this.barDef.months[index] = Number(event.target.value);
    }
    event.target.value = null;
  }

  clearManagers() {
    this.managers = this.managersOg;
  }

  saveManager(manager) {
    if (manager.allowance) {
      manager.allowance = Number(manager.allowance);
    } else {
      manager.allowance = 0;
    }

    if (manager.currentAllowance) {
      manager.currentAllowance = Number(manager.currentAllowance);
    } else {
      manager.currentAllowance = 0;
    }
    console.log(manager);
    this.db.saveStaff(manager);
  }

  setDate(isStart, date) {
    if (isStart) {
      this.startDate = new Date(date);
    } else {
      this.endDate = new Date(date);
    }
  }

  getStaffDrinks(isFake = true) {
    if (this.startDate >= this.endDate) {
      this.alert.nextEmit(
        AlertsService.error(
          "Invalid Dates",
          "StartDate must be atleast 1 day before End Date"
        )
      );
      return;
    }
    this.staffDrinks = [];
    this.loading.nextEmit("on");
    this.db
      .testStaffDrinks(
        this.barname,
        this.startDate.getTime(),
        this.endDate.getTime(),
        isFake,
        true
      )
      .then((a: any) => {
        console.log(a);
        //const obj = JSON.parse(a);
        this.staffDrinks = JSON.parse(a[0].data);
        this.loading.nextEmit("");
        if (!isFake) {
          this.alert.nextEmit(
            AlertsService.good("Credit Added", "Credit Added")
          );
        }
      })
      .catch((e) => {
        console.log(e);
        this.loading.nextEmit("");
      });
  }

  gift() {
    if (
      confirm(
        "Are you sure the dates and bar are correct and credit has not already been gifted for this time period?"
      )
    ) {
      this.getStaffDrinks(false);
    } else {
      this.alert.nextEmit(
        AlertsService.warning("User declined", "Gifting declined by user")
      );
    }
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  bar: any;

  @Output('countryChosen') county = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }


  hovering(location, start: boolean = true) {
    console.log(location,start)
    if(start) {
      this.bar = {name: location}
    } else {
      this.bar = null;
    }
  }

  clicked(location) {
    this.county.emit(location)
  }
}

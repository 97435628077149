export class Shirts {
  id: string = Math.random().toString(36).substr(2, 9);
  type: string; // "shirt" or 'vest'
  size: string; // "S", "M", "L", "XL", "XXL"

  constructor(type: string, size: string) {
    this.type = type;
    this.size = size;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stockName'
})
export class StockNamePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return null;
  }

}

<div class="flex-rows">
  <div class="flex-columns">
    <h1>
      Options
    </h1>
    <div style="display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: stretch;
      align-content: stretch;">
    </div>
    <br>


    <button (click)="add()">Add</button>
    <div *ngFor="let e of messages">
      <h3 (click)="messageSelected(e)">{{e.title}}</h3>
    </div>
  </div>

  <div class="flex-columns " *ngIf="selectedMessage">

    <div class="button-row">
      <button mat-raised-button (click)="save()"> Save</button>
      <button mat-raised-button (click)="cancel()">Cancel</button>
      <button mat-raised-button (click)="delete()">Delete</button>
    </div>

    <form>
      <div class="edit-row">
        <div class="edit-desc">
          Title:
        </div>
        <input class="edit" [(ngModel)]="selectedMessage.title" name="title">
      </div>
      <div class="edit-row">
        <div class="edit-desc">
          Message
        </div>
        <input class="edit" [(ngModel)]="selectedMessage.message" name="message">
      </div>

      <div class="edit-row">

        <div class="edit-desc">
          End Shift
        </div>
        <input class="edit" type="radio" value="selectedMessage.endShift" [checked]="selectedMessage.endShift"
               (click)="radioClicked()" name="time">
      </div>


      <div class="edit-row">
        <div class="edit-desc">
          Days
        </div>
        <div class="edit-row">
          <button (click)="removeDay('1')" [ngClass]="{
                          'highlighted' : selectedMessage.days.includes('1')
                        }">Sun</button>
          <button (click)="removeDay('2')" [ngClass]="{
                          'highlighted' : selectedMessage.days.includes('2')
                        }">Mon</button>
          <button (click)="removeDay('3')" [ngClass]="{
                          'highlighted' : selectedMessage.days.includes('3')
                        }">Tue</button>
          <button (click)="removeDay('4')" [ngClass]="{
                          'highlighted' : selectedMessage.days.includes('4')
                        }">Wed</button>
          <button (click)="removeDay('5')" [ngClass]="{
                          'highlighted' : selectedMessage.days.includes('5')
                        }">Thu</button>
          <button (click)="removeDay('6')" [ngClass]="{
                          'highlighted' : selectedMessage.days.includes('6')
                        }">Fri</button>
          <button (click)="removeDay('7')" [ngClass]="{
                          'highlighted' : selectedMessage.days.includes('7')
                        }">Sat</button>
        </div>
      </div>
      <div class="edit-row">
        <div class="edit-desc">
          Start
        </div>
        <input class="edit" type="date" [ngModel]="selectedMessage.start | date:'yyyy-MM-dd'"
               (ngModelChange)="changeDate(true,$event)" name="start">
      </div>
      <div class="edit-row">
        <div class="edit-desc">
          End
        </div>
        <input class="edit" type="date" [ngModel]="selectedMessage.end | date:'yyyy-MM-dd'"
               (ngModelChange)="changeDate(false,$event)" name="end">
      </div>
    </form>
  </div>

</div>

<div class="generic-modal-header">
  <div class="generic-modal-title">Tip</div>
  <button class="generic-modal-close-button" (click)="modal.dismiss()">
    <i class="fas fa-times"></i>
  </button>
</div>

<div class="generic-modal-content">
  <app-input
    [editable]="false"
    name="Date"
    [model]="tip.dateTime | date : 'yyyy-MM-dd HH:mm'"
  >
  </app-input>
  <app-input
    [editable]="false"
    name="Amount"
    [model]="tip.amount | currency : 'EUR'"
  >
  </app-input>
  <app-input [editable]="false" name="Paid" type="checkbox" [model]="tip.paid">
  </app-input>
  <app-input [editable]="false" name="PaymentId" [model]="tip.paymentId">
  </app-input>

  <pre style="max-width: 100%">{{ tip.receiptId }}</pre>
</div>

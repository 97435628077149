
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Auth } from 'src/app/auth/auth';

import { UserAccessLevels } from 'src/app/Classes/user-access-levels';
import { BarInfo } from 'src/app/Helpers/functions/BarInfo';
import { ChatService } from 'src/app/Helpers/functions/chat.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';


@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  bar;
  contacts = [];
  contact;
  message = "";
  messages = [];
  uid;
  accessLevel = 0;
  constructor(private firebase: StaffAppDatabase, private authInfo: Auth, private barInfo: BarInfo, public access: UserAccessLevels, public auth: AngularFireAuth, private chat: ChatService) {
    this.barInfo.barObserver.subscribe(values => {
      if(values){
        this.bar = values;

      }
    })
    this.accessLevel = Number(this.authInfo.accessUser.access);
    this.chat.chatObserver.subscribe((values: any[]) => {
      if(values){
        console.log(values);
        this.messages = values;
      }




    })
    this.auth.authState.subscribe(values => {
      if(values && values.uid){
        this.uid = values.uid;

        this.getContacts();
      }

    })



  }

  ngOnInit(): void {
  }

  getContacts(){
    let sub = this.firebase.getStaff().subscribe(values => {
      let list = [];
      values.forEach((d:any) => {
        let temp = d;
        if(temp.key){
          temp.uid =temp.key;
        }
        if(temp.uid != this.uid && ( temp.bar == this.bar || Number(temp.access) > 3 || this.accessLevel > 3)){
          temp.notifications = 0;
          list.push(temp);
        }

      })
      list = list.sort((a,b) => - Number(a.access) + Number(b.access));
      this.contacts = list;
      console.log(list);
      sub.unsubscribe();
      this.getNotifications();
    })
  }

  contactSelected(con){
    this.chat.clearChat();
    if(this.uid){
      this.contact = con;
      console.log(this.contact);
      if(this.contact.notificationKey){
        this.firebase.deleteNotification(this.uid, con.uid);
      }

      this.chat.getChat(this.uid, con.uid);
    }

  }

  sendMessage(){
    if(this.message){
      let pack : any = {};
      pack.message = this.message;
      pack.date = new Date().getTime();
      pack.sender = this.uid;

      this.chat.sendMessage(this.uid, this.contact.uid,pack);
      this.message = '';
    }

  }

  ngOnDestroy(){
    this.chat.clearChat();
  }

  getNotifications(){
    this.firebase.getNotifications(this.uid).subscribe(values => {
      console.log(values);

        console.log('removing notifications')
        this.contacts.forEach(a => {

            a.notifications = 0;



        });


      let list = values.map((d:any) => {
        let temp: any = {};
        temp.notifications = d.payload.val();
        temp.key = d.key;
        console.log(temp);
        if(temp.notifications){
          let keys = Object.values(temp.notifications);
          let keys2 = Object.keys(temp.notifications);
          console.log(keys);
          for(let key = 0; key < keys.length; key++){
            this.contacts.forEach(a => {
              console.log(a,key)
              if(a.uid === keys[key]){
                a.notifications++;
                a.notificationKey = keys[key];
              }
            })
          }

        }
        return temp
      })
      console.log(list);

    })
  }
}

import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  private emit = new EventEmitter();
  constructor() { }

  listen(){
    return this.emit.asObservable();
  }


  nextEmit(vari: any) {
    this.emit.emit(vari);
  }

  static success(title: string,message: string = ''){
    return {title:title,"level": 1, message: message};
  }

  static good(title: string,message: string = ''){
    return {title:title,"level": 2, message: message};
  }

  static error(title: string,message: string = ''){
    return {title:title,"level": 5, message: message};
  }

  static warning(title: string,message: string = ''){
    return {title:title,"level": 4, message: message};
  }

  static info(title: string,message: string = '',level: 3){
    return {title:title,"level": level, message: message};
  }

}

<button (click)="notification('IncorrectDrinks')">Test Stock Notification</button>


<br>
<input [ngModel]="start | date:'yyyy-MM-dd'" (ngModelChange)="setDate(true,$event)" type="date" name="startDate" />
<input [ngModel]="end | date:'yyyy-MM-dd'" (ngModelChange)="setDate(false,$event)" type="date" name="endDate" />
<br>
<button (click)="notification('TestDrinks')">Get Staff Drinks</button>
<button (click)="force = !force">{{force ? 'forcing drinks without review' : 'only if reviewed'}}</button>
<button *ngIf="staffDrinks.length && staff" (click)="setDrinks()">Set Staff Drinks</button>
<br>
<button (click)="testDiscounts(start,end)">Test Discount</button>
<button (click)="testInOutCash()">Test In Out Cash</button>


<div *ngIf="staffDrinks.length && staff">
    Staff Drinks

    <div *ngFor="let drink of staffDrinks">
        {{staff[drink[0]] | name}} {{drink[1]}}
    </div>


</div>

<div *ngIf="discounts.length && stock" style="max-height: 400px; overflow: auto;">
    discounts
    <div *ngFor="let discount of discounts" style="width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: flex-start; margin: 5px;" [style.background-color]="discount.reason.indexOf('Medewerker') != -1 ? 'green' : (discount.reason === 'Wisselen Bier' ? 'orange' : 'red')">
        <div class="flex-even" style="width: 100%;">
            {{discount.dateTime | date:'yyyy-MM-dd HH-mm'}} {{discount.reason}}
        </div>

        <div class="flex-even" style="width: 100%;">
            {{discount.totalAmount | currency:'EUR'}}
        </div>



        <div class="flex-even" style="width: 100%;">
            <div *ngFor="let dr of discount.drinks">
                {{stock[dr] ? stock[dr].name : ''}}
            </div>
        </div>


    </div>

</div>


<div style="height: 500px; width: 90%; overflow: auto" class="fix">
    <ngx-json-viewer [json]="result" [expanded]="false"></ngx-json-viewer>
</div>

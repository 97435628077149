import { Component, OnInit } from "@angular/core";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { Voucher } from "../voucher/voucher.component";
import { Auth } from "src/app/auth/auth";

@Component({
  selector: "app-dynamic-voucher",
  templateUrl: "./dynamic-voucher.component.html",
  styleUrls: ["./dynamic-voucher.component.css"],
})
export class DynamicVoucherComponent implements OnInit {
  subs: any[] = [];
  date: Date = new Date();
  voucher: Voucher;
  currentTime: number = Date.now();

  qrOptions: any = {
    width: 256,
  };
  constructor(
    public alert: AlertsService,
    public loading: LoadingService,
    public db: StaffAppDatabase,
    public bar: BarInfo,
    public auth: Auth
  ) {}

  ngOnInit(): void {
    if (this.date.getHours() < 6) {
      this.date.setDate(this.date.getDate() - 1);
    }

    if (localStorage.getItem("voucherDate")) {
      this.currentTime = new Date(
        Number(localStorage.getItem("voucherDate"))
      ).getTime();
      console.log(
        "Voucher Date: " + this.currentTime,
        localStorage.getItem("voucherDate")
      );
      if (Number.isNaN(this.currentTime)) {
        this.currentTime = Date.now();
      }
      if (this.currentTime < Date.now() - 1000 * 60 * 30) {
        console.log("Voucher is old");
        this.currentTime = Date.now();
      }
    }

    this.date.setHours(12, 0, 0, 0);

    this.subs.push(
      this.bar.barObserver.subscribe((bar) => {
        if (bar) {
          this.getData();
        }
      })
    );
  }

  ngAfterViewInit() {
    //get width of screen - 20px
    var width = window.innerWidth - 20;
    this.qrOptions.width = width;

    console.log("Width: " + width);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subs.forEach((sub) => {
      try {
        sub.unsubscribe();
      } catch (error) {
        console.log(error);
      }
    });

    this.destroyPrevious();
  }

  async getData() {
    console.log("Getting Data");
    //get vouchers that are available today
    var vouchers: any[] = [];
    vouchers = await this.db
      .getVouchersPipe()
      .then((a) =>
        a
          .map((b: any) => Object.assign(new Voucher(), b))
          .filter((b) => b.archived != true)
      )
      .catch((e) => {
        console.error(e);
        return [];
      });

    console.log(vouchers);
    this.voucher = null;
    if (vouchers.length == 0) {
      this.alert.nextEmit(AlertsService.warning("No Vouchers Available"));
    } else if (vouchers.length == 1) {
      this.alert.nextEmit(AlertsService.success("1 Voucher Available"));
      this.voucher = vouchers[0];
    } else {
      this.alert.nextEmit(
        AlertsService.success(
          vouchers.length + " Vouchers Available (Ask Duncan)"
        )
      );
    }
    this.refresh();
  }

  async refresh() {
    await this.destroyPrevious();
    console.log("Refreshing");
    this.currentTime = Date.now();
    const dateFormat = "YYYY-MM-DD";
    const timeFormat = "HH:mm";
    //get date and time seperately fwith formattimg
    const date = new Date(this.voucher.date);
    const dateStr = date.toISOString().split("T")[0];
    const timeStr = date.toISOString().split("T")[1].split(".")[0];

    console.log(dateStr, timeStr);
    console.log(this.auth.user.uid);
    localStorage.setItem("voucherDate", this.currentTime + "");
    const hash = await this.db.getEncryptedVoucher(
      this.voucher,
      true,
      this.currentTime,
      this.auth.user.uid
    );
    console.log(hash);
    if (hash && hash.status == "success" && hash.data) {
      var qrCode =
        "https://theendkaraoke.nl/app/?date=" +
        dateStr +
        "&time=" +
        timeStr +
        "&db=" +
        this.voucher.db +
        "&id=voucher___" +
        hash.data +
        "&location=" +
        this.voucher.location;
      console.log(qrCode);
      this.voucher.qrCode = qrCode;
    } else {
      this.alert.nextEmit(
        AlertsService.error(
          "Error generating QR code",
          hash && hash.error ? hash.error : "no error given"
        )
      );
    }
  }

  async destroyPrevious() {
    console.log("Destroying Previous QR:" + this.currentTime);
    //destroy previous qr code through db call of time voucher key and user

    if (localStorage.getItem("lastDestroyed") == this.currentTime + "") {
      console.log("Already Destroyed");
      return;
    }
    const res: any = await this.db.destroyEncryptedVoucher(
      this.voucher,
      this.auth.user.uid,
      this.currentTime
    );

    localStorage.setItem("lastDestroyed", this.currentTime + "");

    if (res && res.status == "success") {
      console.log("Destroyed Previous QR");
    } else {
      this.alert.nextEmit(
        AlertsService.error("Error destroying previous QR", res?.data || null)
      );
    }
  }
}

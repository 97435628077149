import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { CalendarManager } from './calendar-manager';

import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ColorSchemeService } from 'src/app/Helpers/functions/ColorSchemeService';


const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },

};


@Component({
  selector: 'app-my-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './my-calendar.component.html',
  styleUrls: ['./my-calendar.component.css', './dark-theme.scss'],
  encapsulation: ViewEncapsulation.None
})


export class MyCalendarComponent {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent<{ incrementsBadgeTotal: boolean }>[] = [/*
    {
      start: new Date(),
      end: new Date(),
      title: 'example event - count?',
      color: colors.red,
      actions: this.actions,
      allDay: true,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: false
    },*/

  ];

  activeDayIsOpen: boolean = false;
  private darkThemeClass = 'dark-theme';

  constructor(private modal: NgbModal, private calManager: CalendarManager, private c: ColorSchemeService, @Inject(DOCUMENT) private document) {
    this.calManager.eventObserver.subscribe(values => {
      try {
        if (values) {
          if (values === 'clear') {
            this.events = [];
            this.refresh.next(null);
            return;
          }


          if (this.events.filter(e => isSameDay(e.start, values.start) &&
            ((e.title === values.title))
          ).length == 0) {
            this.events.push({
              title: values.title,
              color: values.color,
              start: values.start,
              end: values.end

            },);
            this.refresh.next(null);
          }

        }
      } catch (e) {
        console.log(e);
      }

    });

    this.calManager.deleteEventObserver.subscribe(values => {
      if (values) {
        this.removeEvents();
        this.refresh.next(null);
      }
    })

  }

  ngOnInit(): void {
    this.c.currentThemeObserver.subscribe(values => {
      if (values === 'dark') {
        this.document.body.classList.add(this.darkThemeClass);
      } else {
        this.document.body.classList.remove(this.darkThemeClass);
      }
    })

  }





  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (this.viewDate.getMonth() === date.getMonth()) {
      this.calManager.nextDay(date)
    }

    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  monthClicked() {
    this.calManager.nextDay(this.viewDate)
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }
  removeEvents() {
    this.events = [];
  }
  handleEvent(action: string, event: CalendarEvent): void {
    if (action === 'Clicked') {
      this.calManager.nextNameClick(event.title);
    }

  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

}

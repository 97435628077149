<div class="content">
    <h1>{{ item.token }} - {{ item.title }}</h1>
    <label>Category:</label>
    <h3>{{ item.garment || item.item }}</h3>
    <label>Time Of Entry:</label>
    <p>{{ item.date | date : "EEE dd LLL yy" }}</p>
    <label>Description:</label>
    <span>{{ item.description }} </span>
    <label>Control:</label>
    <p style="padding-bottom: 0px"><b>Q:</b> {{ item.controlQuestion }}</p>
    <p><b>A:</b> {{ item.controlAnswer }}</p>
</div>


<i style="position: absolute; top: 20px; right: 20px" (click)="dismiss()" class="generic-icon fas fa-times red"></i>


<div class="images">
    <img class="image" ngbTooltip="Main Photo" [src]="mainPhoto$ | async" />
    <img class="image" ngbTooltip="Control Photo" [src]="controlPhoto$ | async" />
</div>

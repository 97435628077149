import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';


import { Chart, ChartConfiguration, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

import 'chartjs-adapter-date-fns';
import { de } from 'date-fns/locale';
import { PriceIntervalService } from '../price-interval.service';


@Component({
  selector: 'app-emulator',
  templateUrl: './emulator.component.html',
  styleUrls: ['./emulator.component.css']
})
export class EmulatorComponent implements OnInit {

  title = 'ng2-charts-demo';
  isDynamic = true;
  @Input('date') date: number = 0;
  @Input('dayData') public set value(data: any[]) {
    this.price.setDay(data);
  }
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  public lineChartData: ChartConfiguration['data'] = {

    labels: [],
    datasets: [
      {
        data: [],
        label: 'Price Of Song In Credits',
        fill: false,
        tension: 0,
        type: 'line',
        pointRadius: 0,
        borderColor: 'rgba(255,0,0,1)',

        yAxisID: 'y-axis-1',
        backgroundColor: 'rgba(255,0,0,1)',
      },
      {
        data: [],
        label: 'Last 30 Minutes In Euros',
        fill: false,
        type: 'line',
        tension:0.5,
        pointRadius: 0,
        yAxisID: 'y-axis-2',
        borderColor: 'rgba(0,255,0,1)',
        backgroundColor: 'rgba(0,255,0,1)',
      },
      {
        data: [],
        label: 'Songs Per HalfHour Limit (mins)',
        fill: false,
        type: 'line',
        tension: 0.5,
        pointRadius: 0,
        yAxisID: 'y-axis-2',
        borderColor: 'rgba(0,120,255,1)',
        backgroundColor: 'rgba(0,120,255,1)',
      },
      {
        data: [],
        label: 'Potential Songs Per HalfHour (mins)',
        fill: false,
        type: 'line',
        pointRadius: 0,
        tension: 0.5,
        yAxisID: 'y-axis-2',
        borderColor: 'rgba(0,250,250,1)',
        backgroundColor: 'rgba(0,250,250,1)',
      },
      {
        data: [],
        label: 'Credits Added',
        fill: false,
        tension: 0,
        type: 'scatter',

        borderColor: 'rgba(0,0,0,1)',
        yAxisID: 'y-axis-1',
        backgroundColor: 'rgba(0,0,0,1)',
      },
    ],
  };
  public lineChartOptions: ChartOptions = {

    responsive: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'hour',
        },
      },
      'y-axis-2': {


        position: 'right', // `axis` is determined by the position as `'y'`
      },
      'y-axis-1': {
        position: 'left', // `axis` is determined by the position as `'y'`
      },
    },
  };
  public lineChartLegend = true;
  constructor(private ngZone: NgZone, private price: PriceIntervalService) {}

  ngOnInit(): void {}

  goClicked() {
    this.lineChartData.labels = [];
    this.lineChartData.datasets.forEach(a => a.data = []);
    if (this.isDynamic) {
      this.go();
    } else {
      this.go30();
    }
  }

  async getPrice(id: string) {
    return this.price.price(id);

  }

  async go() {
    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
    console.log(this.date);
    const startDate = new Date(this.date);
    startDate.setHours(15);
    startDate.setMinutes(0);

    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 1);
    endDate.setHours(6);



    const night: any[] = Object.entries(this.price.august)
      .filter(([key, val]: [string, any]) => {
        const time = new Date(val.time);
        if (time > startDate && time < endDate) {
          return true;
        }
        return false;
      })
      .map(([key, val]) => val);

    console.log(night.length);
    for (var i = 0; i < night.length; i++) {
      await delay(50);
      const nextPrice: any = await this.getPrice(night[i])
        .then((a) => a)
        .catch((e) => {
          console.error(e);
          return null;
        });
      if (nextPrice) {
        setTimeout(() => {
          this.lineChartData.labels?.push(nextPrice.date);
          this.lineChartData.datasets[0].data.push(nextPrice.costInCredits);
          this.lineChartData.datasets[1].data.push(
            Number(nextPrice.last30Minutes)
          );
          this.lineChartData.datasets[2].data.push(30);
          this.lineChartData.datasets[3].data.push(
            (3.5 * Number(nextPrice.creditsLast30Minutes)) /
              Number(nextPrice.costInCredits)
          );
          console.log(nextPrice.date)
          this.lineChartData.datasets[4].data.push({
            x: nextPrice.date,
            y: Number(nextPrice.credits),
          });
          //this.lineChartData.datasets[5].data.push(Number(nextPrice.creditsLast30Minutes))
          this.chart?.update();
        });
      }
      console.log(night[i], ' gives price ', nextPrice);
    }
    console.log(this.lineChartData);
  }

  async getPrice30(id: number) {
    return this.price.interval(new Date(id));

  }

  async go30() {
    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
    const startDate = new Date(this.date);
    startDate.setHours(18);
    startDate.setMinutes(0);

    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 1);
    endDate.setHours(4);

    var date = new Date(startDate);
    let points: any = [];
    while (date <= endDate) {
      const nextPrice: any = await this.getPrice30(date.getTime());
      date.setMinutes(date.getMinutes() + 30);
      console.log(nextPrice);
      if (nextPrice) {

          this.lineChartData.labels?.push(nextPrice.date);
          this.lineChartData.datasets[0].data.push(nextPrice.costInCredits);
          this.lineChartData.datasets[1].data.push(
            Number(nextPrice.last30Minutes)
          );
          this.lineChartData.datasets[2].data.push(30);
          this.lineChartData.datasets[3].data.push(
            (3.5 * Number(nextPrice.creditsLast30Minutes)) /
              Number(nextPrice.costInCredits)
          );
          console.log(points, nextPrice.receipts)
            points = points.concat(nextPrice.receipts)
            this.lineChartData.datasets[4].data = points;

          this.chart?.update();

          }

      console.log(this.lineChartData.datasets[4].data);
    }



  }

}

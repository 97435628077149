import { EquationModel } from "./equation-model";

export class CurrentPrice {
  model: EquationModel = new EquationModel();
  currentPrice: number = 0;
  lastUpdate: number = 0;
  lastUpdatedSong: string = "";
  lastUpdatedSring: string = "";

  static clone(m:CurrentPrice){
    var cp = new CurrentPrice();
    cp.model =EquationModel.clone(m.model);
    cp.currentPrice = m.currentPrice;
    cp.lastUpdate = m.lastUpdate;
    cp.lastUpdatedSong = m.lastUpdatedSong;
    cp.lastUpdatedSring = m.lastUpdatedSring;
    return cp;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNumber'
})
export class IsNumberPipe implements PipeTransform {

  transform(value: any): any {


    if (!isNaN(value) && value != null) {
      console.log('value: ' + value + ' is number')
      return true;
    }
    return false;
  }

}

<div class="list">
    <div *ngFor="let dem  of demands" class="demand" [class]="{'green': dem.request && dem.request.isLocked == 'true'}">
        <div class="demand-col">
            <div class="edit-row">
                <div class="edit-desc">
                    User:
                </div>
                <div>{{dem.user.userName}}</div>

            </div>

            <div>
                <div class="edit-row">
                    <div class="edit-desc">
                        Song:
                    </div>
                    <div> {{dem.song.name}}</div>

                </div>
            </div>


        </div>
        <div class="demand-col">
            <div class="edit-row">
                <div class="edit-desc">
                    Status:
                </div>
                <div> {{dem.request && dem.request.isLocked == "true" ? 'Singing' : 'Waiting'}}</div>

            </div>

            <div class="edit-row">
                <div class="edit-desc">
                    Last Accepted Song Time:
                </div>
                <div> {{dem.user.lastAcceptedTime > 0 ? (dem.user.lastAcceptedTime*1000 | date: 'EEE LLL dd - HH:mm') : n/a }}</div>

            </div>

        </div>



    </div>
</div>

<div class="flex-columns" style="max-height: 90%; overflow: auto; justify-content: flex-start;">
  <div class="flex-row">
    <button [disabled]="viewType == 'ram'" (click)="viewType = 'ram'">
      Ram
    </button>
    <button [disabled]="viewType == 'cpu'" (click)="viewType = 'cpu'">
      CPU
    </button>
  </div>

  <div class="flex-row">
    <div *ngFor="let pc of computers" (click)="selected(pc)">
      {{ pc.PC }}
    </div>
  </div>

  <div class="divider"></div>

  <div class="flex column" *ngIf="computer" style="height: 80%">
    <h3>System Info</h3>

    <app-input type="text" [editable]="false" name="Server Name" titleClass="editDesc-Larger"
               [model]="computer.PC"></app-input>
    <app-input type="text" [editable]="false" name="Internal Name" titleClass="editDesc-Larger"
               [model]="computer.InternalComputerName"></app-input>

    <app-input type="text" [editable]="false" name="Bar" titleClass="editDesc-Larger"
               [model]="computer.Bar"></app-input>

    <app-input type="text" [editable]="false" name="Internal IP" titleClass="editDesc-Larger"
               [model]="computer.InternalIP"></app-input>

    <app-input type="text" [editable]="false" name="External IP" titleClass="editDesc-Larger"
               [model]="computer.ExternalIP"></app-input>

    <app-input type="text" [editable]="false" name="Last Update" titleClass="editDesc-Larger"
               [model]="computer.Time | date : 'dd-MM-yyyy HH:mm:ss'"></app-input>

    <app-input type="text" [editable]="false" name="Available RAM" titleClass="editDesc-Larger"
               [model]="computer.AvailableRam | sizeConversion : 'B,GB'"></app-input>
    <app-input type="text" [editable]="false" name="Total RAM" titleClass="editDesc-Larger"
               [model]="computer.TotalRam | sizeConversion : 'B,GB'"></app-input>

  </div>

</div>

<div class="usage-container" *ngIf="computer && viewType == 'cpu'" style="flex-direction: row">
  <!--


  <div class="pie-container">
    <h3>% Process CPU Usage</h3>
    <canvas baseChart class="ramChart" [data]="pieChartData" [type]="pieChartType" [options]="pieChartOptions2"
            [plugins]="pieChartPlugins">
    </canvas>
  </div>

  -->


  <div class="line-container">
    <h3>CPU History</h3>
    <canvas baseChart class="chart" [data]="barChartData" [options]="lineChartOptions2" type="bar"></canvas>
  </div>
</div>

<div class="usage-container" *ngIf="computer && viewType == 'ram'" style="flex-direction: row">

  <!--
 <div class="pie-container">
    <h3>RAM Usage</h3>
    <canvas baseChart class="ramChart" [data]="ramChartData" [type]="pieChartType" [options]="pieChartOptions"
            [plugins]="pieChartPlugins">
    </canvas>
  </div>


  -->

  <div class="line-container">
    <h3>RAM History</h3>
    <canvas baseChart class="chart" [data]="barChartData2" [options]="lineChartOptions" type="bar"></canvas>
  </div>
</div>

<div class="flex-columns">
  <h1>Tutorials</h1>
  <button class="list-item" style="margin-bottom: 10px;" (click)="createTutorial()"
          *ngIf="auth.accessUser.access > 1">Create</button>
  <div class="list">
    <div *ngFor="let tut of tutorials" class="list-item" (click)="tutorialClicked(tut)">
      <span>{{tut.title}}</span>
      <div class="label">{{tut.type}}</div>

    </div>
  </div>

</div>

<div class="flex-columns" *ngIf="tutorial">
  <app-input [model]="tutorial.title" [editable]="editing" (changed)="edited = true; tutorial.title = $event"
             placeholder="A Short Descriptive Title" name="Title"></app-input>
  <app-input type="checkbox" [model]="tutorial.portrait" [editable]="editing" name="Is Portrait (Mobile View)"
             (changed)="edited = true; tutorial.portrait = $event"></app-input>
  <app-input [model]="tutorial.type" type="select" [list]="defaultTypes" name="Category"
             (changed)="edited = true; categorySelected($event)" [editable]="editing"></app-input>

  <app-input [model]="tutorial.message" [editable]="editing" type="multiline"
             (changed)="edited = true; tutorial.message = $event"
             placeholder="Writen overview of the tutorial / motivational messages / work friendly jokes"
             name="Description"></app-input>
  <div class="button-bar">
    <button (click)="getVideo(tutorial)">Refresh/Get
      Video</button>
    <input *ngIf="auth.accessUser.access > 1" type="file" accept="video/*" (change)="edited = true; uploadVideo($event)"
           (click)="value = null; this.file = null;">
  </div>

  <div class=" video" *ngIf="videoLink">
    <video [src]="videoLink" controls></video>

  </div>

  <div class="button-bar">
    <button [disabled]="editing" *ngIf="auth.accessUser.access > 1" (click)="editing = true">Edit</button>
    <button [disabled]="edited" *ngIf="auth.accessUser.access > 1" (click)="delete()">Delete</button>
    <button [disabled]="!edited" *ngIf="auth.accessUser.access > 1" (click)="save()">Save</button>
    <button [disabled]="!edited" (click)="cancel()">Cancel</button>
  </div>
</div>

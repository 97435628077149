<table *ngIf="worked && worked.length != 0" style="font-size: normal; font-weight: normal; width: 100%;">
    <tr>
        <th style="width: 40%">Name</th>
        <th style="width: 15%">Start</th>
        <th style="width: 15%">End</th>
        <th style="width: 15%;">Price</th>
        <th style="width: 15%">Total</th>
    </tr>
    <tr *ngFor="let s of worked">
        <th style="width: 40%">{{ s.name }}</th>
        <th style="width: 15%">{{ s.startTime }}</th>
        <th style="width: 15%">{{ s.endTime }}</th>
        <th style="width: 15%">{{ (s.price || 0) | currency:'EUR'}}</th>
        <th style="width: 15%">{{ s.total.toFixed(2) }}</th>
    </tr>
</table>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yearMonthCount'
})
export class YearMonthCountPipe implements PipeTransform {

  transform(val: any, ...args: any[]): number {
    const value = Object.assign({}, val);
    const searchType = args[0] || { year: -1, month: -1, type: 'all' }
    try {
      switch (searchType.type) {
        case 'all':
          return Number(Object.values(value).reduce((pre: number, acc: number[] | any) => { return pre + (Array.isArray(acc) ? acc.reduce((p, a) => { return p + a }, 0) : Object.values(acc).reduce((p: number, a: number) => { return p + a }, 0)) }, 0))
          break;
        case 'month':
          return value[String(searchType.year)][searchType.month] || 0
          break;
        case 'year':
          return value[String(searchType.year)].reduce((p, a) => { return p + a }, 0)
          break;
      }
    } catch (e) {
      console.error(e);
      return 0;
    }

    return -1;
  }

}


import { MultiSelectOption } from "src/app/Packages/multiselect/multiselect.component";
import { SurveyAnswer } from "./SurveyAnswer";
import { SurveyQuestion } from "./SurveyQuestion";
import { SurveyStatus } from "./SurveyStatus";

export class Survey {
  key: string;
  date: number = new Date().getTime();
  title: string = 'New Survey';
  questions: SurveyQuestion[] = [];
  status = SurveyStatus.statuses[0];
  answers: any = {};
  reward: number = 0;
  anonomous: boolean = true;
  bar: string = '';
  users: MultiSelectOption[] = [];
  description: string = ""


  constructor() {

  }

  getObject() {
    return { description: this.description, key: this.key, date: this.date, title: this.title, questions: this.questions, status: this.status, reward: this.reward, anonomous: this.anonomous, bar: this.bar, users: this.users }
  }

}

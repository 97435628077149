<div class="list">
    <div class="list-item" *ngFor="let app of appointments" style="padding: 0px 10px;">
        <div>
            {{app.StartTime}}
        </div>
        <br>
        <div style="text-align: right;">
            {{app.Name}}
        </div>


    </div>
</div>

<div *ngIf="agenda">
    AgendaId: {{agenda}}
</div>
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cctv',
  templateUrl: './cctv.component.html',
  styleUrls: ['./cctv.component.css']
})
export class CCTVComponent implements OnInit {

  constructor(private http: HttpClient) {}

  ngOnInit() {
    const username = 'Admin';
    const password = 'RmaL548Zsqi45';
    const cameraUrl = 'http://82.217.205.103ISAPI/Streaming/channels/1/picture'; // Change this to your camera's IP and port

    // Make sure to handle authentication securely, such as by encrypting the password during transmission
    const authHeader = 'Basic ' + btoa(`${username}:${password}`);

    // Request the video stream from the camera
    this.http.get(cameraUrl, {
      headers: {
        'Authorization': authHeader
      },
      responseType: 'blob' // Assume video stream is in binary format
    }).subscribe((response: Blob) => {
      // Convert the blob to a URL that can be used by the video element
      const videoUrl = URL.createObjectURL(response);

      // Get a reference to the video element and set its source
      const videoElement = document.querySelector('video');
      videoElement.setAttribute('src', videoUrl);
    });
  }

}

<h1>Clocked In Out Records</h1>

<div class="list">
  <div *ngFor="let record of records">

    {{record.name || record.uid}}
    <div style="margin-left: 20px" [class]="record.values && record.values.length > 2 ? 'red' :''">
      <div *ngFor="let update of record.values">

        {{update.type}} - {{update.date | date:'LLL dd HH:mm'}}
      </div>
    </div>
  </div>
</div>

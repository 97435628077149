import { Component, OnInit } from '@angular/core';
import { getDate } from 'date-fns';
import { Auth } from 'src/app/auth/auth';
import { BarInfo } from 'src/app/Helpers/functions/BarInfo';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';

@Component({
  selector: 'app-shifts',
  templateUrl: './shifts.component.html',
  styleUrls: ['./shifts.component.css']
})
export class ShiftsComponent implements OnInit {

  date = new Date();

  attempts = [];
  times = [];

  final = [];

  staffSub;

  worked: any[];

  start = 'start';
  attempt: string = '';

  startCount: any[] =[];
  endCount: any[] =[];


  constructor(private db: StaffAppDatabase, private bI: BarInfo, private auth: Auth) { }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(){
    this.staffSub.unsubscribe();
  }

  getAttempt() {
    try {
      const lis = this.attempts.filter(a => a.key === this.attempt)
      if(lis.length) {
        return lis[0].value;
      } else {
        return [];
      }

    } catch(e) {
      console.error(e)
      return [];
    }

  }

  async getData(){
    if(this.bI.bar) {
      this.attempts = await this.db.getStockAttempts(this.bI.bar, this.date);
      console.log(this.attempts);

      this.times = await this.db.getCountTimes(this.bI.bar, this.date);
      console.log(this.times);

      this.final = await this.db.getFinalCount(this.bI.bar, this.date);
      console.log(this.final)
      this.final.forEach(a => {
        switch(a.key) {
          case 'start': this.startCount = a.value;
          break;
          case 'end': this.endCount = a.value;
          break;
        }
      })
      console.log(this.startCount, this.endCount)
      this.staffSub = this.db.staffWorkedObserver.subscribe(values => {
        if(values){
          this.worked = values;
        } else {
          this.worked = null;
        }

      })

      this.db.getHoursForDate(this.bI.bar,this.date);
    }

  }

  dateChanged(date) {
    console.log(new Date(date));
    this.date = new Date(date);
    this.getData();
  }
}

import { Component, OnInit } from '@angular/core';
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';
import { TextContent } from 'pdfjs-dist/types/src/display/api';
import { take, takeWhile } from 'rxjs/operators';
import { BarInfo } from 'src/app/Helpers/functions/BarInfo';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';
import { PDFDocument } from 'pdf-lib'
import { HighlightSpanKind } from 'typescript';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { LoadingService } from 'src/app/Services/loading.service';
const pdfjsLib = require("pdfjs-dist/legacy/build/pdf.js");
@Component({
  selector: 'app-payslip-uploader',
  templateUrl: './payslip-uploader.component.html',
  styleUrls: ['./payslip-uploader.component.css']
})
export class PayslipUploaderComponent implements OnInit {
  bars: any[] = [];
  bar: string;
  fileToUpload: File | null = null;
  staff = [];
  pdf: any;

  date: '';

  pageNames: any[] = [];
  constructor(private db: StaffAppDatabase, private bI: BarInfo, private storage: AngularFireStorage, private loading: LoadingService) { }

  ngOnInit(): void {

    this.getData();

  }

  cancel() {
    this.pdf = null;
    this.pageNames = [];

  }

  async distribute() {

    if (this.date) {
      this.loading.nextEmit('on');
      const d = new Date(this.date);
      console.log(this.date);
      for (let i = 0; i < this.pageNames.length; i++) {
        try {
          const a = this.pageNames[i];

          console.log(a.paid);

          if (a.user) {
            console.log('payslip for', a.user.firstName, a.index);

            try {
              const res = await this.db.setPaid(d, this.bI.bar, a.user.uid, a.paid).then(a => a).catch(e => {
                console.log(e);
                throw new Error(e);
              })
              console.log(res);
              if (res) {
                alert(res);
              }
            } catch (e) {
              alert(e);
            }
            const page: PDFPageProxy = a.page;
            const srcDoc = await PDFDocument.load(await this.fileToUpload.arrayBuffer());
            const pdfDoc = await PDFDocument.create();
            const [otherDoc] = await pdfDoc.copyPages(srcDoc, [a.index - 1]);
            console.log(otherDoc)
            pdfDoc.addPage(otherDoc);
            const bytes = await pdfDoc.save();
            //console.log(bytes);
            const filePath = '/documents/' + a.user.uid + '/payslips/' + d.getTime();
            const ref = this.storage.ref(filePath);
            const task = ref.put(new Blob([new Uint8Array(bytes)]));
            await task.percentageChanges().pipe().toPromise();
            a.uploaded = true;

          }
        } catch (e) {
          alert('failed for page: ' + (i + 1))
        }


      }
      this.loading.nextEmit('off');
    } else {
      alert('date needed!');
    }

  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  async getData() {
    this.bars = await this.db.getBarsPromise().then(a => a).catch(e => []);
    this.staff = await this.db.getStaff().pipe(take(1)).toPromise().then(a => a.filter((b: any) => b.bar &&
      b.firstName != 'nan' && b.firstName && b.access > 0).sort((d: any, e: any) => e.lastName > d.lastName ? -1 : 1)).catch(e => []);

  }



  handleFileInput(files: FileList) {
    this.pdf = null;
    this.pageNames = [];
    this.fileToUpload = files.item(0);
  }

  async upload() {
    pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/build/pdf.worker.js';



    pdfjsLib.getDocument(URL.createObjectURL(new Blob([new Uint8Array(await this.fileToUpload.arrayBuffer())]))).promise.then((doc: PDFDocumentProxy) => {

      this.getPages(doc.numPages, doc)

    });

  }

  async getPages(numPages, doc) {
    for (var i = 1; i <= numPages; i++) {
      const page = await doc.getPage(i).then(a => a);
      //console.log(page)
      const res = await this.parsePage(page);
      //console.log(i,res);
      var pageName: any = { index: i, iban: res[0], user: null, id: '', name: '', page: page, paid: 0 };
      pageName.name = res[1];
      if (res[0]) {

        const users = this.staff.filter(a => a.iban && a.iban.replace(/\s+/g, '').toLowerCase() === res[0].toLowerCase());
        if (users.length) {
          pageName.user = users[0];
          pageName.id = users[0].uid;

        }

      }

      this.pageNames.push(pageName)
    }

  }

  async parsePage(page: PDFPageProxy) {
    var iban = '';
    var name = '';
    const text: TextContent = await page.getTextContent().then(a => a).catch(a => null);

    if (text) {
      const index = text.items.findIndex((a: any) => a.str === 'Per Bank IBAN:')
      if (index > 0) {
        iban = (text.items[index + 1] as any).str;


      }
    }
    if (text.items[3]) {
      name = (text.items[3] as any).str
    }
    return [iban, name];

  }

  assignUser(page) {

    if (page.id) {
      const u = this.staff.filter(a => a.uid === page.id);
      if (u.length) {
        page.user = u[0];
      }
    }


  }

}

<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Artist Update</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss('Cross click')"><i class="fas fa-close"></i></button>
</div>
<div class="modal-body">
    <form [formGroup]="artistForm">
        <div class="edit-row">
            <div class="edit-desc">
                Name:
            </div>
            <input class="form-control" class="edit" id="name" #name formControlName="name">
            <div *ngIf="!isValid('name')">Name is required between 1 and 200 characters</div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark warning" (click)="close('delete')">Delete</button>
    <button type="button" class="btn btn-outline-dark" (click)="close('save')">Save</button>
</div>

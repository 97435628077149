import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return value[0].toUpperCase() + value[1];
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrentPrice } from 'src/app/Classes/current-price';
import { PriceStatistics } from 'src/app/Classes/price-statistics';
import { Firebase } from 'src/app/Services/firebase';
import { LoadingService } from 'src/app/Services/loading.service';
import { AlertsService } from '../alerts/alerts.service';

@Component({
  selector: 'app-song-pricing-model',
  templateUrl: './song-pricing-model.component.html',
  styleUrls: ['./song-pricing-model.component.css']
})
export class SongPricingModelComponent implements OnInit {

  @Input() model: CurrentPrice = new CurrentPrice();
  @Input() bar: string;
  @Input() canSave: boolean = false;
  constructor(private modal: NgbActiveModal, private db: Firebase, private load: LoadingService, private alert: AlertsService) { }

  ngOnInit(): void {
  }

  async saveModel(){
    this.model.model.minimumPrice = Number(this.model.model.minimumPrice);
    this.model.model.maxDown = Number(this.model.model.maxDown);
    this.model.model.maxUp = Number(this.model.model.maxUp);
    this.model.model.customWeights.first5Minutes = Number(this.model.model.customWeights.first5Minutes);
    this.model.model.customWeights.last5Minutes = Number(this.model.model.customWeights.last5Minutes);
    this.model.model.customWeights.last15Minutes = Number(this.model.model.customWeights.last15Minutes);
    this.model.model.customWeights.lastHour = Number(this.model.model.customWeights.lastHour);
    this.model.model.customWeights.middle5Minutes = Number(this.model.model.customWeights.middle5Minutes);

    if(!this.canSave) {
      this.modal.close(this.model);
      return;
    }
    if(!window.confirm("Are you sure you want to save this model? This will affect the bar tonight!")) {
      return;
    }
    this.load.nextEmit("on");
    var success = true;
    try {



      const priceVal = await this.db.setEquationModel(this.bar,this.model.model).then(a => null).catch(e => e);
      if (priceVal) {
        success = false;
        this.alert.nextEmit(AlertsService.warning('Couldnt set price'))
      }
    } catch (e) {
      success = false;
      this.alert.nextEmit(AlertsService.warning('Couldnt set price'))
    }

    this.load.nextEmit("");
    if (success) {
      this.alert.nextEmit(AlertsService.good('All Saved', "All prices updated!"))
      this.modal.close();
    }

  }

  dismiss(){
    this.modal.dismiss();
  }

}

/**
 * API
 * # Introduction  Welcome to the TPAPI-POS API! unTill Server provides a REST interface for 3rd party applications which may be used for remotely working with POS: reading configuration, making orders, etc.  You can view code samples in the dark area to the right.  In case you might have any questions about our API, please create a new support [**ticket**](http://integrations.untill.com/support/tickets/new). If you don't have an account yet but already signed an [**NDA**](https://untill.com/nda), please send an email to [**systemintegration@untill.com**](mailto:systemintegration@untill.com). Please do not use this email address for support questions.  # User permissions  Each API function should be invoked with appropriate user permission. Please see function description. The following permissions can be assigned to a user in unTill Backoffice - Backoffice view - Backoffice modify - Ordering - Ordering: override price - Apply discounts - Payment - Client management - Reporting - Reservations - Misc
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Client } from '../model/client';
import { ClientDeposit } from '../model/clientDeposit';
import { ClientSavePoints } from '../model/clientSavePoints';
import { FormattedReceipt } from '../model/formattedReceipt';

import { BASE_PATH } from 'src/variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ClientManagementService {

    protected basePath = 'http://82.73.157.201:{port}/api/v1/{dbname}';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * List clients
     * List clients. Required user permission: Client management
     * @param lastUpdate Specify this value to return only clients added/updated since that date/time
     * @param active Search clients with specified state. When param absent then both active and inactive clients returns
     * @param smartCardUid Searches clients by smartcard UID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientGet(lastUpdate?: Date, active?: boolean, smartCardUid?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Client>>;
    public clientGet(lastUpdate?: Date, active?: boolean, smartCardUid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Client>>>;
    public clientGet(lastUpdate?: Date, active?: boolean, smartCardUid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Client>>>;
    public clientGet(lastUpdate?: Date, active?: boolean, smartCardUid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (lastUpdate !== undefined && lastUpdate !== null) {
            queryParameters = queryParameters.set('lastUpdate', <any>lastUpdate.toISOString());
        }
        if (active !== undefined && active !== null) {
            queryParameters = queryParameters.set('active', <any>active);
        }
        if (smartCardUid !== undefined && smartCardUid !== null) {
            queryParameters = queryParameters.set('smartCardUid', <any>smartCardUid);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Client>>('get',`${this.basePath}/client`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Client deposit
     * Makes deposit to client account. Required user permission: Client management
     * @param body
     * @param id Specify client ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientIdDepositPost(body: ClientDeposit, id: number, observe?: 'body', reportProgress?: boolean): Observable<FormattedReceipt>;
    public clientIdDepositPost(body: ClientDeposit, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FormattedReceipt>>;
    public clientIdDepositPost(body: ClientDeposit, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FormattedReceipt>>;
    public clientIdDepositPost(body: ClientDeposit, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling clientIdDepositPost.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientIdDepositPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<FormattedReceipt>('post',`${this.basePath}/client/${encodeURIComponent(String(id))}/deposit`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find client by ID
     * Find client by ID. Required user permission: Client management
     * @param id Specify to find client by ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public clientIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public clientIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public clientIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Client>('get',`${this.basePath}/client/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update client by ID
     * Update client by ID. Required user permission: Client management
     * @param id Specify to update client by ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientIdPut(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public clientIdPut(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public clientIdPut(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public clientIdPut(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientIdPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/client/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add save points
     * Add save points to client account. Required user permission: Client management
     * @param body
     * @param id Specify client ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientIdSavePointsPost(body: ClientSavePoints, id: number, observe?: 'body', reportProgress?: boolean): Observable<ClientSavePoints>;
    public clientIdSavePointsPost(body: ClientSavePoints, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientSavePoints>>;
    public clientIdSavePointsPost(body: ClientSavePoints, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientSavePoints>>;
    public clientIdSavePointsPost(body: ClientSavePoints, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling clientIdSavePointsPost.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientIdSavePointsPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClientSavePoints>('post',`${this.basePath}/client/${encodeURIComponent(String(id))}/save-points`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create client
     * Create client. When using this request, the fields &#x60;accountBalance&#x60; and &#x60;savePoints&#x60; are not updated. Use the &#x60;Client deposit&#x60; and &#x60;Add save points&#x60; for it. Required user permission: Client management
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPost(body: Client, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public clientPost(body: Client, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public clientPost(body: Client, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public clientPost(body: Client, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling clientPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Client>('post',`${this.basePath}/client`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

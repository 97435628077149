import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-walletpassthrough',
  templateUrl: './walletpassthrough.component.html',
  styleUrls: ['./walletpassthrough.component.css']
})
export class WalletpassthroughComponent implements OnInit {
  device: string = 'unknown';
  errors: string[] = [];
  constructor(private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
    var fullurl = localStorage.getItem("fullurl");
    console.log('fullurl: ' + fullurl);
    console.log(navigator.userAgent)
    console.log(this.deviceService.getDeviceInfo())
    this.device = this.getOS();
    var url: string;
    var queryParams = {
      'fullurl': fullurl
    }
    var queryString = "";
    var newUrl = "";
    try {

    } catch(error) {
      this.errors.push(error);
    }

    var now = Date.now();
    var lastCheck = localStorage.getItem("lastCheck") || 0;
    if(now - Number(lastCheck) < 2000){
      alert('Please wait a moment before trying again');
      return;
    } else {
      console.log('lastCheck: ' + lastCheck);
    }
    localStorage.setItem("lastCheck",Date.now()+ '');
    switch(this.deviceService.getDeviceInfo().os){
      case 'iOS':
        console.log('isIOS');
        url = 'com.duncansaunders.staff://'


        queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');
        newUrl = url + '?' + queryString;
        window.location.replace(newUrl);
        return;
        break;
      case 'Android':
      case 'Linux':
        console.log('isAndroid');
        url = 'com.duncansaunders.staff://'


        queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');
        newUrl = url + '?' + queryString;
        window.location.replace(newUrl);
        return;
        break;
      default:
        console.log('Unknown OS');
        alert('Unknown OS: Please copy link for manual pasting')
        return;

    }


  }

  copy(){
    var fullurl = localStorage.getItem("fullurl");
    console.log('fullurl: ' + fullurl);
    navigator.clipboard.writeText(fullurl).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  isAndroid(){
    return navigator.userAgent.match(/Android/i);
  }

  isIOS(){
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  }

  getOS() {
    var uA = navigator.userAgent || navigator.vendor ;
    if ((/iPad|iPhone|iPod/.test(uA)) || (uA.includes('Mac') && 'ontouchend' in document)) return 'iOS';

    var i, os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
    for (i = 0; i < os.length; i++) if (new RegExp(os[i],'i').test(uA)) return os[i];
  }

}

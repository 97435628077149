import { Pipe, PipeTransform } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { Firebase } from '../Services/firebase';



@Pipe({
  name: 'customer'
})
export class CustomerPipe implements PipeTransform {

  constructor(private db: Firebase) {

  }

  async transform(demand: any,args: any[]): Promise<any> {



    return await this.db.getUser(demand).snapshotChanges().pipe(take(1),map((a:any) => {
      const data: any = a.payload.val();
      const key = a.key;
      return {key,...data};
    })).toPromise()
  }

}

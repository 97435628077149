<div class="generic-row">
  <label>Start Date: </label>
  <input
    type="date"
    [ngModel]="startDate | date : 'yyyy-MM-dd'"
    (ngModelChange)="dateChanged('start', $event)"
  />

  <label>End Date: </label>
  <input
    type="date"
    [ngModel]="endDate | date : 'yyyy-MM-dd'"
    (ngModelChange)="dateChanged('end', $event)"
  />
  <button style="margin-left: 10px" (click)="go()">Get Tips</button>
</div>

<div class="generic-row">
  <button [disabled]="!hasTips" (click)="calculatePayment()">
    Calculate Payment
  </button>
</div>

<div class="generic-table-header">
  <div class="generic-table-header-item long">Date</div>
  <div class="generic-table-header-item long">Time</div>
  <div class="generic-table-header-item short">Amount</div>
</div>

<div class="generic-table">
  <div
    class="generic-table-row"
    *ngFor="let tip of tips"
    (click)="openTip(tip)"
  >
    <div class="generic-table-item long">
      {{ tip.dateTime | date : "yyyy-MM-dd" }}
    </div>

    <div class="generic-table-item long">
      {{ tip.dateTime | date : "HH:mm" }}
    </div>

    <div class="generic-table-item short">
      {{ tip.amount | currency : "EUR" }}
    </div>
  </div>
</div>

import { Component, Input, OnInit } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PDFViewerComponent } from "duncans-pdfviewer";
import { User } from "firebase/auth";
import { MyUser } from "src/app/Classes/user";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { Auth } from "src/app/auth/auth";
import { DeleteStaffDialog } from "../staff-info/staff-info.component";

@Component({
  selector: "app-staff-info-popup",
  templateUrl: "./staff-info-popup.component.html",
  styleUrls: ["./staff-info-popup.component.css"],
})
export class StaffInfoPopupComponent implements OnInit {
  @Input() person: MyUser;
  @Input() bar: string;
  editing: boolean = false;
  photos = -1;

  bankBack: any = null;
  bankFront: any = null;
  idBack: any = null;
  idFront: any = null;
  sh: any = null;
  payslips: any[] = [];
  contracts: any[] = [];
  loonheffing: any[] = [];

  documentType: number = 0;

  startDate = new Date();
  payslipDate = new Date();
  loonheffingDate = new Date();
  constructor(
    private storage: AngularFireStorage,
    public auth: Auth,
    public db: StaffAppDatabase,
    public alert: AlertsService,
    public loading: LoadingService,
    public activeModal: NgbActiveModal,
    public modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.getImg("Bankpas_Achterkant");
    this.getImg("Bankpas_Voorkant");
    this.getImg("ID Foto_Achterkant");
    this.getImg("ID Foto_Voorkant");
    this.getImg("Sociale Hygiene_Voorkant");
    this.getImgFromPath(
      "documents/" + this.person.uid + "/contract",
      "contract"
    );
    this.getImgFromPath(
      "documents/" + this.person.uid + "/payslips",
      "payslip"
    );
  }

  approve() {
    this.person.complete = !this.person.complete;
  }

  save() {
    if (!this.person.firstName || !this.person.lastName || !this.person.email) {
      console.log(
        "denied",
        this.person.firstName,
        this.person.lastName,
        this.person.email
      );

      this.alert.nextEmit(
        AlertsService.info(
          "First name and Last name required",
          "Please fill them in",
          3
        )
      );
      return;
    }
    var formBirthdate: number | null;

    if (isNaN(Number(this.person.birthdate))) {
      //this.person.birthday is a number

      formBirthdate = 0;
    }

    if (isNaN(Number(this.person.eoc))) {
      //is a number
      this.person.eoc = 0;
    }

    if (isNaN(Number(this.person.soc))) {
      //is a number

      this.person.soc = 0;
    }

    this.person.birthdate = this.person.birthdate + "";
    console.log("saving");
    this.db
      .saveStaff(this.person)
      .then((a) => {
        this.editing = false;
        this.alert.nextEmit(AlertsService.good("Staff saved", "Success"));
      })
      .catch((e) => {
        this.alert.nextEmit(
          AlertsService.warning("Staff NOT saved", e.message)
        );
      });

    try {
      this.db.changedRecordOld(
        this.bar,
        this.auth.user.uid,
        this.person,
        "staffinfo",
        this.person.uid || this.person.email || null
      );
    } catch (e) {}
  }

  getImg(name) {
    if (!this.person.uid) {
      this.loading.nextEmit(null);
      console.log("no uid");
      return;
    }
    const ref = this.storage.ref(
      "/bars/" + this.bar + "/staff/" + this.person.uid + "/" + name
    );
    console.log("/bars/" + this.bar + "/staff/" + this.person.uid + "/" + name);
    ref.getDownloadURL().subscribe(
      (values) => {
        console.log(values);
        if (values) {
          switch (name) {
            case "Bankpas_Achterkant":
              this.bankBack = values;
              return;
            case "Bankpas_Voorkant":
              this.bankFront = values;
              return;
            case "ID Foto_Achterkant":
              this.idBack = values;
              return;
            case "ID Foto_Voorkant":
              this.idFront = values;
              return;
            case "Sociale Hygiene_Voorkant":
              this.sh = values;
              return;
          }
        }
        this.loading.nextEmit(null);
      },
      (error) => {
        this.loading.nextEmit(null);
      }
    );
  }

  getImgFromPath(name, type) {
    if (type === "payslip") {
      this.payslips = [];
    }
    if (type === "contract") {
      this.contracts = [];
    }

    const ref = this.storage.ref(name);
    ref.listAll().subscribe((result) => {
      let latest = 0;

      for (const item of result.items) {
        if (type === "contract") {
          this.contracts.push({ data: {}, key: Number(item.name) });
        } else if (type === "payslip") {
          this.payslips.push({ data: {}, key: Number(item.name) });
        } else if (type === "loonheffing") {
          this.loonheffing.push({ data: {}, key: Number(item.name) });
        }
      }
      this.contracts.sort((b, a) => {
        return a.key - b.key;
      });
      this.payslips.sort((b, a) => {
        return a.key - b.key;
      });

      this.loonheffing.sort((b, a) => {
        return a.key - b.key;
      });
      if (ref) {
        if (type === "contract") {
          this.contracts.forEach((a) => this.getDownloadUrl(a.key, type));
        } else if (type === "payslip") {
          this.payslips.forEach((a) => this.getDownloadUrl(a.key, type));
        } else {
          this.loonheffing.forEach((a) => this.getDownloadUrl(a.key, type));
        }
      } else {
        this.loading.nextEmit(null);
      }
    });
  }

  getDownloadUrl(ref, type) {
    let refString;
    switch (type) {
      case "contract":
        refString = "documents/" + this.person.uid + "/contract/" + ref;
        break;
      case "payslip":
        refString = "documents/" + this.person.uid + "/payslips/" + ref;
        break;
      case "loonheffing":
        refString = "documents/" + this.person.uid + "/loonheffing/" + ref;
        break;
    }
    if (!refString) {
      this.loading.nextEmit(null);
      return;
    }

    this.storage
      .ref(refString)
      .getDownloadURL()
      .subscribe(
        (values) => {
          if (values) {
            switch (type) {
              case "contract":
                const index2 = this.contracts.findIndex((a) => a.key === ref);
                if (index2 >= 0) {
                  this.contracts[index2].ref = refString;
                  this.contracts[index2].data = values;
                  this.contracts[index2].type = "contract";
                  var date = new Date(Number(ref));
                  this.contracts[index2].date = date
                    .toISOString()
                    .split("T")[0];
                  this.contracts[index2].name =
                    this.person.firstName +
                    "-" +
                    this.person.lastName +
                    "-" +
                    this.person.uid;
                }

                break;
              case "loonheffing":
                const index3 = this.loonheffing.findIndex((a) => a.key === ref);
                if (index3 >= 0) {
                  this.loonheffing[index3].ref = refString;
                  this.loonheffing[index3].data = values;
                  this.loonheffing[index3].type = "loonheffing";
                  var date = new Date(Number(ref));
                  this.loonheffing[index3].date = date
                    .toISOString()
                    .split("T")[0];
                  this.loonheffing[index3].name =
                    this.person.firstName +
                    "-" +
                    this.person.lastName +
                    "-" +
                    this.person.uid;
                }
                break;
              case "payslip":
                const index = this.payslips.findIndex((a) => a.key === ref);
                if (index >= 0) {
                  this.payslips[index].ref = refString;
                  this.payslips[index].data = values;
                  this.payslips[index].type = "payslip";
                  var date = new Date(Number(ref));
                  this.payslips[index].date = date.toISOString().split("T")[0];
                  this.payslips[index].name =
                    this.person.firstName +
                    "-" +
                    this.person.lastName +
                    "-" +
                    this.person.uid;
                }
            }
            this.loading.nextEmit(null);
          }
        },
        (error) => {
          this.loading.nextEmit(null);
        }
      );
  }
  upload(event, name) {
    this.loading.nextEmit("on");
    try {
      if (name === "contract") {
        console.log(event, name);
        const file = event.target.files[0];

        console.log(file);
        console.log(this.startDate);
        const filePath =
          "/documents/" +
          this.person.uid +
          "/contract/" +
          this.startDate.getTime();
        const ref = this.storage.ref(filePath);
        const task = ref.put(file);
        task.percentageChanges().subscribe((values) => {
          console.log(values);
          if (values === 100) {
            setTimeout(() => {
              this.loading.nextEmit(null);
              this.getImgFromPath(
                "documents/" + this.person.uid + "/contract",
                "contract"
              );
            }, 2000);
          }
        });
      } else if (name === "payslip") {
        console.log(event, name);
        const file = event.target.files[0];

        console.log(file);
        const filePath =
          "/documents/" +
          this.person.uid +
          "/payslips/" +
          this.payslipDate.getTime();
        const ref = this.storage.ref(filePath);
        const task = ref.put(file);
        task.percentageChanges().subscribe((values) => {
          console.log(values);
          if (values === 100) {
            setTimeout(() => {
              this.loading.nextEmit(null);
              this.getImgFromPath(
                "documents/" + this.person.uid + "/payslips",
                "payslip"
              );
            }, 2000);
          }
        });
      } else if (name === "loonheffing") {
        console.log(event, name);
        const file = event.target.files[0];

        console.log(file);
        const filePath =
          "/documents/" +
          this.person.uid +
          "/loonheffing/" +
          this.loonheffingDate.getTime();
        const ref = this.storage.ref(filePath);
        const task = ref.put(file);
        task.percentageChanges().subscribe((values) => {
          console.log(values);
          if (values === 100) {
            setTimeout(() => {
              this.loading.nextEmit(null);
              this.getImgFromPath(
                "documents/" + this.person.uid + "/loonheffing",
                "loonheffing"
              );
            }, 2000);
          }
        });
      } else {
        console.log(event, name);
        const file = event.target.files[0];

        console.log(file);
        const filePath =
          "/bars/" + this.bar + "/staff/" + this.person.uid + "/" + name;
        const ref = this.storage.ref(filePath);
        const task = ref.put(file);
        task.percentageChanges().subscribe((values) => {
          console.log(values);
          if (values === 100) {
            this.loading.nextEmit(null);
            this.getImg(name);
          }
        });
      }
    } catch (e) {
      console.error(e);
      this.alert.nextEmit(AlertsService.error("Error", e.message));
    }
  }

  saveBirthday(d) {
    console.log(d);
    //d is a string like 2024-02-20
    var date = new Date(d);

    console.log(date);
    this.person.birthdate = date.getTime() + "";
  }

  saveEOC(date) {
    this.person.eoc = new Date(date).getTime();
  }

  saveSOC(date) {
    this.person.soc = new Date(date).getTime();
  }

  savePayslipDate(date) {
    this.payslipDate = date;
    this.getImgFromPath(
      "documents/" + this.person.uid + "/payslips",
      "payslip"
    );
  }

  onFileChange(event) {
    console.log(event);
  }

  download(name) {
    const link = document.createElement("a");
    switch (name) {
      case "Bankpas_Achterkant":
        link.href = this.bankBack;
        break;
      case "Bankpas_Voorkant":
        link.href = this.bankFront;
        break;
      case "ID Foto_Achterkant":
        link.href = this.idBack;
        break;
      case "ID Foto_Voorkant":
        link.href = this.idFront;
        break;
      case "Sociale Hygiene_Voorkant":
        link.href = this.sh;
        break;
    }
    console.log(link.href);
    if (!link.href.includes("null")) {
      link.download = name + ".jpeg";
      link.target = "_blank";
      link.click();
    }
  }

  removeStaff() {
    const u = this.person;

    this.modal
      .open(DeleteStaffDialog, {
        size: "lg",
        backdrop: "static",
        keyboard: false,
      })
      .result.then((result) => {
        if (result) {
          this.db
            .deleteStaff(u)
            .then((a) => {
              this.editing = false;
              this.alert.nextEmit(
                AlertsService.good("Staff moved to Old Staff Bar ", "Success")
              );
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            })
            .catch((e) => {
              this.alert.nextEmit(
                AlertsService.warning("Staff NOT deleted", e.message)
              );
            });
        }
      });
  }

  saveStartDate(date) {
    this.startDate = date;
  }

  saveLoonheffingDate(date) {
    this.loonheffingDate = date;
  }
  resetPassword(person) {
    this.alert.nextEmit(AlertsService.good("Resetting password", "Success"));
    this.auth.reset(person.email);
  }

  reset() {
    this.activeModal.dismiss();
  }

  deleteContract(p, co) {
    if (!window.confirm("Are you sure you want to delete this contract?")) {
      return;
    }
    console.log(p, co);
    this.loading.nextEmit("on");
    this.db
      .deleteContract(p, co)
      .then((a) => {
        this.contracts = this.contracts.filter((b) => b.key !== co.key);
        this.loading.nextEmit(null);
      })
      .catch((e) => {
        alert(e);
        this.loading.nextEmit(null);
      });
  }

  deletePayslip(p, co) {
    if (!window.confirm("Are you sure you want to delete this payslip?")) {
      return;
    }
    this.loading.nextEmit("on");
    this.db
      .deletePayslip(p, co)
      .then((a) => {
        this.payslips = this.payslips.filter((b) => b.key !== co.key);
        this.loading.nextEmit(null);
      })
      .catch((e) => {
        alert(e);
        this.loading.nextEmit(null);
      });
  }

  deleteLoonheffing(p, co) {
    if (!window.confirm("Are you sure you want to delete this document?")) {
      return;
    }
    this.loading.nextEmit("on");
    this.db
      .deleteLoonheffing(p, co)
      .then((a) => {
        this.loonheffing = this.loonheffing.filter((b) => b.key !== co.key);
        this.loading.nextEmit(null);
      })
      .catch((e) => {
        alert(e);
        this.loading.nextEmit(null);
      });
  }

  openPDF(p, type, date) {
    console.log(p);
    var m = this.modal.open(PDFViewerComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    p.type = type;
    p.date = date;
    p.name =
      this.person.firstName +
      "_" +
      this.person.lastName +
      "_" +
      this.person.uid;
    m.componentInstance.pdfData = p;
    m.componentInstance.init();
    m.componentInstance.followClose().subscribe((a) => {
      m.close();
    });
  }

  openConsole() {
    if (this.auth.accessUser.access > 6) {
      var url =
        "https://console.firebase.google.com/project/staffapp-b0578/database/staffapp-b0578/data/staff/" +
        this.person.uid;
      window.open(url, "_blank");
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AvatarServiceService } from '../avatar-service.service';
const multiavatar = require('@multiavatar/multiavatar');
@Component({
  selector: 'app-avatar-maker',
  templateUrl: './avatar-maker.component.html',
  styleUrls: ['./avatar-maker.component.css']
})
export class AvatarMakerComponent implements OnInit {

  source;
  constructor(private sanitizer: DomSanitizer, public service: AvatarServiceService) {

  }

  ngOnInit(): void {
    this.service.sourceObserver.subscribe(values => {
      this.source = values;
    })
    this.service.reinitAvatar();
  }


  }


import { Component, Input, OnInit } from "@angular/core";
import { GenericMessage } from "src/app/Classes/generic-message";

@Component({
  selector: "app-generic-message",
  templateUrl: "./generic-message.component.html",
  styleUrls: ["./generic-message.component.css"],
})
export class GenericMessageComponent implements OnInit {
  @Input("message") message: GenericMessage;
  constructor() {}

  ngOnInit(): void {
    console.log(this.message);
  }
}

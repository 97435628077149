import { Component, OnInit, inject } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Table } from 'src/app/Classes/table';

import { AlertsService } from 'src/app/Packages/alerts/alerts.service';
import { LoadingService } from 'src/app/Services/loading.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.css']
})
export class TablesComponent implements OnInit {

  date: NgbDateStruct = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());

  tables: Table[] = [];
  dateVisible: boolean = false;
  constructor(public db: StaffAppDatabase, public alert: AlertsService, public loading: LoadingService, public modal: NgbModal) {

  }

  ngOnInit(): void {
  }

  async getTill() {
    this.loading.nextEmit('on')
    ////console.log(new Date(this.selectedDate).toISOString(),this.selectedDate,this.barName);
    this.db.getTillForDate(new Date(new Date(this.date.year, this.date.month - 1,this.date.day)).toLocaleString(), this.db.bar).then(a => {
      console.log(a);
      this.alert.nextEmit(AlertsService.good("Getting Till", "Success"));
      this.loading.nextEmit('null')
      this.go();
    }).catch(e => {
      console.error(e)
      this.alert.nextEmit(AlertsService.error("Error Getting Till", JSON.stringify(e, null, 2)));
      this.loading.nextEmit('null')
    });
  }


  async go(){
    this.loading.nextEmit('on');
    this.tables = await this.db.getTables( new Date(this.date.year, this.date.month - 1,this.date.day)).then((res) => {
      this.loading.nextEmit('off');
      console.log(res)
      return res.sort((a,b) => { return Number(a.name) - Number(b.name)});
    }).catch((err) => {
      this.loading.nextEmit('off');
      console.error(err);
      this.alert.nextEmit(AlertsService.error("Error", "Error getting tables"))
      return []
    })
  }

}

<div style="
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  ">
  <div *ngIf="listHideAll">{{ selectedLength() || "All" }} Selected</div>
  <div (click)="dropdown = !dropdown">
    <i [class]="'fas fa-chevron-' + (!dropdown ? 'down' : 'up')"></i>
  </div>
</div>

<div *ngIf="dropdown" class="dropdown">
  <p *ngIf="hint">{{hint}}</p>
  <div *ngFor="let user of list" class="dropdown-item">
    {{ user.name }}
    <input type="checkbox" [checked]="user.selected" (change)="user.selected = !user.selected; change(list)" />
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countdown'
})
export class CountdownPipe implements PipeTransform {

  transform(time: number, ...args: unknown[]): unknown {
    const t = time / 1000 / 60;
    const days = Math.floor(t / 60 / 24);
    const hours = Math.floor((t / 60) % 24);
    const minutes = (Math.floor(t) % 60)
    const string = (days > 0 ? days + "d " : "") + (hours > 0 ? hours + "h " : "");
    return string;
  }

}

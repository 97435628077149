import { Component, Input, OnInit } from "@angular/core";
import { InvoiceData } from "../../Instellingen/invoice-splitter/invoice-splitter.component";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { AngularFireFunctions } from "@angular/fire/compat/functions";

@Component({
  selector: "app-invoice-splitter-preview",
  templateUrl: "./invoice-splitter-preview.component.html",
  styleUrls: ["./invoice-splitter-preview.component.css"],
})
export class InvoiceSplitterPreviewComponent implements OnInit {
  @Input("files") files: InvoiceData[] = [];

  constructor(
    public alert: AlertsService,
    public loading: LoadingService,
    public http: HttpClient,
    public modal: NgbActiveModal,
    private db: StaffAppDatabase
  ) {}

  ngOnInit(): void {

  }

  close() {
    this.modal.dismiss();
  }

  async email() {
    this.loading.show();


    for (var i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      if(!file.email) {
        file.status = "error";
        continue;
      }

      if (!this.validateEmail(file.email)) {
        file.status = "error";
        continue
      }

        file.status = "sending";

        const [r,e] = await this.sendEmail(this.files[i]);

        if(e) {
          console.log(e);
          file.status = "error";
          this.loading.hide();
          return;
        }

    }

    // if we get here, all emails were sent
    this.loading.hide();
    this.modal.close();



  }

  async sendEmail(file: InvoiceData) {


    var [result, error] = await this.db.sendEmail(file);

    if (error) {
      console.log(error);
      file.status = "error";
      this.alert.nextEmit(AlertsService.error("Error sending email:" + error));
    }
    if (result) {
      file.status = "success";
      this.alert.nextEmit(AlertsService.success("Email sent"));
    }

    return [result, error];
  }

  validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stock-count',
  templateUrl: './stock-count.component.html',
  styleUrls: ['./stock-count.component.css']
})
export class StockCountComponent implements OnInit {
  @Input('count') count: any[] = [];
  constructor() { }

  ngOnInit(): void {
  }

}

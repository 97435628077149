import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'myDate'
})
export class MyDatePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): string {
    try {
      const date = new Date(value);

      return date.getFullYear() + "/" + date.getMonth() + "/" + date.getDate();
    } catch(e) {
      console.error(e);
      return null;

    }
  }

}

import { Component, OnInit } from '@angular/core';
import { QueryFn } from '@angular/fire/compat/database';
import { firstValueFrom } from 'rxjs';
import { AlertsComponent } from 'src/app/Packages/alerts/alerts.component';
import { AlertsService } from 'src/app/Packages/alerts/alerts.service';
import { Firebase } from 'src/app/Services/firebase';
import { LoadingService } from 'src/app/Services/loading.service';

@Component({
  selector: 'app-wipe-credit',
  templateUrl: './wipe-credit.component.html',
  styleUrls: ['./wipe-credit.component.css']
})
export class WipeCreditComponent implements OnInit {
  aboveZeroUsers: number | null = null;
  constructor(private api: Firebase, private loading: LoadingService, private alert: AlertsService) { }

  ngOnInit(): void {

  }

  async getPositiveCredits() {
    this.loading.nextEmit('on');
    try {
      const query: QueryFn = ref => (ref.orderByChild('credits').startAfter(0));
      const result = await firstValueFrom(this.api.getUsersFiltered(query));
      this.aboveZeroUsers = result.length
    } catch (e: any) {
      console.error(e);
      this.alert.nextEmit(AlertsService.error('Failed to get positive credits', e.toString()))
    }

    this.loading.nextEmit('')

  }

  async fixCredits() {
    this.loading.nextEmit('on');
    const res = await this.api.fixCredits().then(a => null).catch(e => e);
    if (res) {
      this.alert.nextEmit(AlertsService.error('Failed to get positive credits', res.toString()))
    } else {
      this.alert.nextEmit(AlertsService.good('Fixed Credits'));
    }
    this.getPositiveCredits();
  }

}

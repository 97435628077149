import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BackofficeViewService } from 'src/app/Helpers/functions/Untill/Swagger/api/backofficeView.service';
import { Configuration } from 'src/app/Helpers/functions/Untill/Swagger/configuration';
import { LoadingService } from 'src/app/Services/loading.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';
import { AlertsService } from '../alerts/alerts.service';

@Component({
  selector: 'app-select-new-stock',
  templateUrl: './select-new-stock.component.html',
  styleUrls: ['./select-new-stock.component.css']
})
export class SelectNewStockComponent implements OnInit {
  tillDrinks: any[] = [];
  filteredTillDrinks: any[] = [];
  @Input('barName') barName: string;
  @Input('stock') stock: any[]
  tillInfo: any;
  constructor(public activeModal: NgbActiveModal, public db: StaffAppDatabase, public loading: LoadingService,  private http: HttpClient,public alert: AlertsService) { }

  ngOnInit(): void {
    this.loading.show();
    this.getTillInformation().then(a => {
      this.addDrinkByName("");
    })
  }

  addStock(s) {
    this.activeModal.close({command: "addStock", stock: s});
  }

  async updateAllPrices(){
    this.loading.show();
    var changed = 0;
      console.log(this.stock);
      const promises = [];
      for (var i = 0; i < this.tillDrinks.length; i++) {
        const till = this.tillDrinks[i];
        for (var j = 0; j < this.stock.length; j++) {
          const stock = this.stock[j];

          if (stock.serverId == till.id) {
            console.log(
              "updating price: " +
                stock.name +
                ", from: " +
                stock.price +
                " to " +
                till.prices[0].amount
            );
            if(stock.price != till.prices[0].amount) {
              changed++;
            }
            promises.push(
              this.db.updatePrice(
                this.barName,
                stock.serverId,
                till.prices[0].amount,
                stock.category
              )
            );
            continue;
          }
        }
      }

      Promise.all(promises)
        .then((a) => {
          this.activeModal.dismiss();
          this.alert.nextEmit(AlertsService.good("Uploaded Prices! Changed: " + changed));
          this.loading.hide();
        })
        .catch((e) => {
          this.alert.nextEmit(AlertsService.error("Errrrrr", e.getMessage()));
          this.loading.hide();
        });

  }



  async getTillInformation() {
    return new Promise((res,rej) => {this.db.getTillInformation(this.barName).subscribe((values: any) => {
      console.log(values);
      this.tillInfo = new Configuration({
        username: values.username,
        password: values.password,
        accessToken: values.token,
        basePath: values.path + values.database,
        withCredentials: true,
      });
      return res(true);
    });});

  }

  addDrinkByName(name) {
    if (this.tillInfo != null) {
      this.loading.nextEmit("on");
      const backView = new BackofficeViewService(
        this.http,
        null,
        this.tillInfo,
        this.db
      );
      const obss = backView.articleGet(null, true, null);
      obss.subscribe(
        (values) => {
          //console.log(values);
          this.loading.nextEmit("");
          try {
            this.tillDrinks = JSON.parse(values).filter((a) => a.active).map((a) => {
              if(this.stock.find(x => x.serverId == a.id)) {
                a.exists = true;
              } else {
                a.exists = false;
              }
              return a;
            })
            this.filteredTillDrinks = this.tillDrinks;
            console.log(this.tillDrinks);
          } catch (e) {
            console.log(values)
            this.alert.nextEmit({
              title: "Failed",
              message: values.split("<h2>")[1].split("</h2>")[0],
              level: 5,
            });
          }
        },
        (error) => {
          this.alert.nextEmit({ title: "Failed", message: error, level: 5 });
          console.error(error);
          this.loading.nextEmit("");
        }
      );
    }
  }

  filteredDrinks(s) {
    //console.log("filtering on s: ", s);

    this.filteredTillDrinks = this.tillDrinks.filter(
      (a: any) =>
        (a.name.toLowerCase().includes(s.toLowerCase()) ||
          String(a.id).toLowerCase().includes(s.toLowerCase())) &&
        a.active
    );
  }



}

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">X</button>
    </div>
    <div class="modal-body">


        <div class="mb-3">
            <label for="dateOfBirth">Key</label>
            <div class="input-group">
                <input id="dateOfBirth" class="form-control" type="text" disabled="true" [(ngModel)]="user.key">

            </div>
        </div>

        <div class="mb-3">
            <label for="dateOfBirth">Username</label>
            <div class="input-group">
                <input id="dateOfBirth" class="form-control" type="text" [(ngModel)]="user.userName">

            </div>
        </div>

        <div class="mb-3">
            <label for="dateOfBirth">Credits</label>
            <div class="input-group">
                <input id="dateOfBirth" class="form-control" type="number" [(ngModel)]="user.credits" min="0" max="1000">

            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div>
</ng-template>

<div style="display: flex; flex-direction: row; align-items: center; justify-content: space-evenly">
    <div>Search</div>
    <select [(ngModel)]="searchType" (change)="load()">
      <option value="email">Email</option>
      <option value="key">UID</option>
      <option value="userName">Username</option>
    </select>
    <input type="text" (keyup)="load()" [(ngModel)]="searchKey" placeholder="name@email.com">
</div>



<div class="list" style="max-height: 700px; overflow:scroll; width: 90%;">
    <div *ngFor="let us of users | async" (click)="user = us; open(content)" class="box-row">
        <div class="key">{{us.key}}</div>
        <div> {{us.email}}</div>
    </div>

</div>

import { Component, OnInit } from '@angular/core';
import { CalendarManager } from 'src/app/Classes/my-calendar/calendar-manager';
import { BarInfo } from 'src/app/Helpers/functions/BarInfo';
import { MyDate } from 'src/app/Helpers/functions/MyDate';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';

@Component({
  selector: 'app-ndcounting-hq',
  templateUrl: './ndcounting-hq.component.html',
  styleUrls: ['./ndcounting-hq.component.css']
})
export class NDCountingHQComponent implements OnInit {


  barSub = null;
  sub = null;
  calSub = null;
  staffSub = null;
  date = new Date();
  barName;
  values = [];
  selectedDate = "";
  start = true;
  stockCount = [];

  hide = false;
  lastCount = false;

  showCalendar = false;

  endCount;
  startCount;
  all1 = false;
  all2 = false;

  moreInfo1 = false;
  moreInfo2 = false;

  reasons = [];

  reason$: any;
  constructor(private calManager : CalendarManager, private db: StaffAppDatabase, private bI: BarInfo) {
    this.calManager.destroy();
    this.sub= this.db.countedNDStockObserver.subscribe((values: any[]) => {
      //console.log(values);
      this.values = values.sort((a,b) => {
        return a.id -b.id;
      })
      if(values) {
        values.forEach(element => {
          let day = element.day;
          let startIncorrections = 0;
          let endIncorrections = 0;
          let event : any = {};
          let d = new Date();
          d.setMonth(this.date.getMonth());
          d.setFullYear(this.date.getFullYear())
          d.setDate(day);
          event.allDay = false;
          event.start = d;
          event.end = d;

          event.meta = {
            incrementsBadgeTotal: false,
          };
          if(element.start){
            element.start.list.forEach(stock => {
              startIncorrections += (stock.difference != 0 ? 1 : 0);
            })
            if(startIncorrections){

              event.title =  'Stock Incorrections: ' + startIncorrections;
              event.color =  this.calManager.colors.yellow;



            } else {
              event.title =  'Stock: No Incorrections';
              event.color =  this.calManager.colors.blue;
            }

          } else {
            event.title = 'Stock: No count!';
            event.color = this.calManager.colors.red;
          }
          this.calManager.nextEvent(event);



        });



      }
     })
    this.barSub = this.bI.barObserver.subscribe(values => {
      if(values) {
        this.barName = values;
      this.db.getNDStock(new Date().getMonth(), new Date().getFullYear(), this.barName);
      }

    })


  }

  isFinite(number) {
    return Number.isInteger(number);
  }

  ngOnInit(): void {

    this.calSub = this.calManager.daysObserver.subscribe((values:Date) => {
      this.all1 = false;
      this.all2 = false;
      this.moreInfo1 = false;
      this.moreInfo2 = false;

      if(values){

        //console.log("get count for date: " + values);
      if(values && this.date.getMonth() != values.getMonth() && this.barName){
        this.db.getStock(values.getMonth(),values.getFullYear(), this.barName);
      }
      this.date = values;

      this.stockCount = null;
      this.selectedDate = null;
      this.values.forEach(val => {
        if(values.getDate() && Number(val.day) === values.getDate()){


          //console.log(val);
          this.selectedDate = MyDate.dateToString(values);
          this.startCount = val.start.list.sort((a,b)=> {

            return a.id - b.id
          });




          this.isLastCount();
        }

      })
      this.showCalendar = false;
    }})



  }

  ngOnDestroy(){
    if(this.barSub){
      this.barSub.unsubscribe();
    }
    if(this.sub){
      this.sub.unsubscribe();
    }
    if(this.calSub){
      this.calSub.unsubscribe();
    }
    if(this.staffSub){
      this.staffSub.unsubscribe();
    }
  }





  incorrect(list: any[], all = false){
    if(all) {
      return list;
    }
    return list.filter(a => a.difference != 0 || (a.difference == 0 && a.currentOffset != 0));
  }

  isLastCount(){
    //console.log('called isLastCount')
    const d = new Date();
    if(d.getHours() < 12){
      d.setDate(d.getDate() -1);
    }

    if(!this.selectedDate || !MyDate.isSameDay(this.date,d) || !this.start){
      this.lastCount =   false;
      return false;
    }
    this.lastCount = true;
    return true;

  }

  setZero(s){
    const offset = 0;
    this.db.updateOffset(s.key,offset,this.barName);

  }



  setZeroAll(){
    this.startCount.forEach(a => {
      this.setZero(a);
    })
  }
}

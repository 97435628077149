import { DecimalPipe } from '@angular/common';
import { SelectorMatcher } from '@angular/compiler';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Directive, EventEmitter, Input, NgZone, OnInit, Output, PipeTransform, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, pipe } from 'rxjs';
import { map, startWith, take, tap } from 'rxjs/operators';
import { AlertsService } from 'src/app/Packages/alerts/alerts.service';
import { Firebase } from 'src/app/Services/firebase';
var ukkonen = require("ukkonen");
import { LoadingService } from 'src/app/Services/loading.service';
import { StaffAppDatabase } from 'src/app/Services/staffappdatabase';
const LanguageDetect = require('languagedetect');
export type SortColumn = keyof any | '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { 'asc': 'desc', 'desc': '', '': 'asc' };

const compare = (v1: string | number | boolean, v2: string | number | boolean) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})
export class NgbdSortableHeader {

  @Input() sortable: SortColumn = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}



@Component({
  selector: 'app-song-requests',
  templateUrl: './song-requests.component.html',
  styleUrls: ['./song-requests.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SongRequestsComponent implements OnInit {


  showDutch = false;
  showBlacklisted = false;
  showBought = false;
  showWished = false;
  showOther = true;
  requests: any[] = [];
  actualRequests: any[] = [];
  results: Observable<any[]>;
  filter = new FormControl('');
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  artists: any[] = [];
  showCleanup = false;
  cleanups = { possibleOwned: [], markedDutch: [], toRemove: [] }
  constructor(public db: Firebase, private loading: LoadingService, public pipe: DecimalPipe, public alert: AlertsService, private zone: NgZone, private change: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.loading.nextEmit('on')
    this.getRequests();
    this.db.getArtists().pipe(take(1), map(snap => {
      return snap.map(doc => {
        return doc.payload.val();
      }
      )
    })).toPromise().then((artists) => {
      this.artists = artists;
      //  console.log(this.artists)
    })

    this.results = this.filter.valueChanges.pipe(startWith(''), map(text => this.search(text, this.pipe), tap(() => {
      this.collectionSize = this.requests.length;
    })));

    setTimeout(() => {
      this.loading.nextEmit(null)
    }, 3000)
  }

  async saveCleanup() {
    this.loading.nextEmit('on')
    for (let i = 0; i < this.cleanups.toRemove.length; i++) {
      await this.db.removeSongRequest(this.cleanups.toRemove[i].request.ref, this.cleanups.toRemove[i].request)
    }
    for (let i = 0; i < this.cleanups.possibleOwned.length; i++) {
      await this.db.buyRequest(this.cleanups.possibleOwned[i].request)
    }
    for (let i = 0; i < this.cleanups.markedDutch.length; i++) {
      await this.db.markDutch(this.cleanups.markedDutch[i].ref, this.cleanups.markedDutch[i])
    }
    this.refresh();
    this.loading.nextEmit(null)
    this.showCleanup = false;
  }
  async setProgress(on: boolean, progress: number | null) {
    return await new Promise((resolve, reject) => {

      this.loading.nextEmit(on ? 'on' : null, null, 'song-request');
      return resolve(null);
    });
  }


  async cleanup() {

    this.alert.nextEmit(AlertsService.good('Cleaning up requests...', 'This may take a while'));
    setTimeout(async () => {
      await this.cleanup2();

    }, 500)

  }
  async cleanup2() {
    var toRemove = [];
    var markedDutch = [];
    var possibleOwned = [];
    for (var i = 0; i < this.actualRequests.length; i++) {

      if (i % 50 == 0) {
        await this.setProgress(true, (i / this.actualRequests.length) * 100)

      }


      var request = this.actualRequests[i];
      if (request.blacklisted) {
        continue;
      }

      if (request.bought) {
        continue;
      }

      if (request.dutch) {
        continue;
      }


      if (!request.name || !request.artist) {
        request.blacklisted = true;
        toRemove.push({ request: request, reason: 'No name or artist' });
        continue
      }
      for (var j = i + 1; j < this.actualRequests.length; j++) {

        var secondRequest = this.actualRequests[j];
        if (secondRequest.blacklisted) {
          continue;
        }
        if (!secondRequest.name || !secondRequest.artist) {
          continue;
        }
        if (request.ref == secondRequest.ref) {
          continue;
        }

        try {
          if (ukkonen(request.name + ' ' + request.artist, secondRequest.name + ' ' + secondRequest.artist) < 3) {
            //.log('possible duplicates', request.name + ' ' + request.artist, secondRequest.name + ' ' + secondRequest.artist)
            if (!isNaN(request.count) && !isNaN(secondRequest.count)) {
              request.count = parseInt(request.count) + parseInt(secondRequest.count);

            }
            secondRequest.blacklisted = true;
            toRemove.push({ request: secondRequest, reason: 'Duplicate with: ' + request.name + ' ' + request.artist });
          }
        } catch (e) {
          console.log(request, secondRequest)
        }


      }

      const languageDect = new LanguageDetect();
      const lang = languageDect.detect(request.name + ' ' + request.artist, 1);
      // console.log(lang[0])
      if (lang[0][0] == 'dutch' && lang[0][1] > 0.3) {
        request.dutch = true;
        markedDutch.push(request);
      }


      for (var j = 0; j < this.artists.length; j++) {
        if (!this.artists[j].songs || !this.artists[j].name) {
          // console.log('no songs or name', this.artists[j])
          continue;
        }
        if (ukkonen(this.artists[j].name, request.artist) < 3) {
          //possible same artist
          for (var s = 0; s < this.artists[j].songs.length; s++) {

            if (!this.artists[j].songs[s] || !this.artists[j].songs[s].name || !this.artists[j].songs[s].artist) {
              //console.log('no song or name or artist', this.artists[j].songs[s])
              continue;
            }
            // console.log('comparing: ' + request.name + ' ' + request.artist + ' with ' + this.artists[j].songs[s].name + ' ' + this.artists[j].songs[s].artist, ukkonen(this.artists[j].songs[s].name, request.name))
            if (ukkonen(this.artists[j].songs[s].name, request.name) < 3) {
              //possible same song
              // console.log('possible same song', this.artists[j].songs[s].name, request.name)
              possibleOwned.push({ request, song: this.artists[j].songs[s] });
              request.bought = true;
            }
          }
        }
      }

    }

    this.zone.run(() => {
      //console.log('Possible owned', possibleOwned);
      // console.log('Dutch', markedDutch)
      // console.log('toRemove', toRemove)
      this.alert.nextEmit(AlertsService.good('Cleaning up requests...', 'Success!'));
      this.loading.nextEmit(null);
      this.cleanups = { possibleOwned, markedDutch, toRemove }
      this.showCleanup = true;
      console.log(this.cleanups)
      this.change.detectChanges();
    })

  }
  async getRequests() {
    const temp = await this.db.getSongRequests().then(a => a).catch(e => []);
    this.actualRequests = temp.map((req) => {
      var edited = false;
      return req;
    })


    this.onSort({ column: 'count', direction: 'desc' })
    // console.log(this.requests)
  }


  async removeSongRequest(request) {
    request.blacklisted = !request.blacklisted
    await this.db.removeSongRequest(request.ref, request);
    if (!this.showBlacklisted) {
      this.refresh();
    }

  }

  async markDutch(request) {
    request.dutch = !request.dutch
    await this.db.markDutch(request.ref, request);
    if (!this.showDutch) {
      this.refresh();
    }
  }

  async markWished(request) {
    request.wished = !request.wished
    await this.db.markWished(request.ref, request);
    if (!this.showWished) {
      this.refresh();
    }

  }

  async addToCatalogue(request) {
    request.bought = !request.bought
    await this.db.buyRequest(request);
    if (!this.showBought) {
      this.refresh();
    }

  }

  refresh() {
    console.log('refreshing', this.showDutch, this.showBlacklisted, this.showBought, this.showWished)

    this.requests = this.actualRequests.filter((req) => {

      if (!this.showDutch && req.dutch) {
        // console.log(this.showDutch, req.dutch)
        return false;
      }

      if (!this.showBlacklisted && req.blacklisted) {
        // console.log(this.showBlacklisted, req.blacklisted)
        return false;
      }

      if (!this.showBought && req.bought) {
        //console.log(this.showBought, req.bought)
        return false;
      }

      if (!this.showWished && req.wished) {
        //console.log(this.showWished, req.wished)
        return false;
      }

      if (!this.showOther && !req.dutch && !req.blacklisted && !req.bought && !req.wished) {
        return false;
      }

      return true;
    })

    this.collectionSize = this.requests.length;
    this.filter.setValue(this.filter.value);
  }

  queryString(str: string): string {
    if (!str) {
      return "";
    }
    return str.split(" ").join("+");
  }

  search(text: string, pipe: PipeTransform): any[] {
    return this.requests.filter(country => {

      const term = text.toLowerCase();
      try {
        return country.name.toLowerCase().includes(term)
          || country.artist.toLowerCase().includes(term)
      } catch (e) {
        return false;
      }





    }).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);;

  }



  onSort({ column, direction }: SortEvent) {
    console.log('sorting')
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction === '' || column === '') {
      this.actualRequests = this.actualRequests;
    } else {
      this.actualRequests = [...this.actualRequests].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
    this.refresh();
  }
}

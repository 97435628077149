<div *ngFor="let item of items" class="item">
    <div class="item-row">
        <label class="item-label">Name:</label>
        <div class="item-value2" *ngIf="item.name">{{item.name}}</div>
        <div class="item-value2" *ngIf="!item.name && item.reason">{{item.reason}}</div>

    </div>
    <div class="item-row">
        <label class="item-label">Price: </label>
        <div class="item-value">{{item | price | currency: "EUR" }}</div>

    </div>











</div>

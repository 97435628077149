import { Component, OnInit } from '@angular/core';
import { colorSets } from '@swimlane/ngx-charts';
import { CalendarEvent } from 'angular-calendar';
import { CalendarEventActionsComponent } from 'angular-calendar/modules/common/calendar-event-actions.component';
import { Calendar } from 'calendar-base';
import { timeStamp } from 'console';
import { Observable } from 'rxjs';
import { CalendarManager } from 'src/app/Classes/my-calendar/calendar-manager';

import { BarInfo } from '../../../Helpers/functions/BarInfo';
import { MyDate } from '../../../Helpers/functions/MyDate';
import { StaffAppDatabase } from '../../../Services/staffappdatabase';

@Component({
  selector: 'app-counting',
  templateUrl: './counting.component.html',
  styleUrls: ['./counting.component.css']
})
export class CountingComponent implements OnInit {
  barSub = null;
  sub = null;
  calSub = null;
  staffSub = null;
  date = new Date();
  barName;
  values = [];
  selectedDate = "";
  start = true;
  stockCount = [];
  worked = null;
  hide = false;
  lastCount = false;

  showCalendar = false;

  endCount;
  startCount;

  all1 = false;
  all2 = false;

  moreInfo1 = false;
  moreInfo2 = false;

  reasons = [];

  reason$: any;
  constructor(private calManager : CalendarManager, private db: StaffAppDatabase, private bI: BarInfo) {
    this.calManager.destroy();
    this.sub= this.db.countedStockObserver.subscribe((values: any[]) => {
      //console.log(values);
      if(values) {
        this.values = values;
        values.forEach(element => {
          let day = element.day;
          let startIncorrections = 0;
          let endIncorrections = 0;
          let event : any = {};
          let d = new Date();
          d.setMonth(this.date.getMonth());
          d.setFullYear(this.date.getFullYear())
          d.setDate(day);
          event.allDay = false;
          event.start = d;
          event.end = d;

          event.meta = {
            incrementsBadgeTotal: false,
          };
          if(element.start){
            element.start.forEach(stock => {
              startIncorrections += (stock.difference != 0 ? 1 : 0);
            })
            if(startIncorrections){

              event.title =  'Start Incorrections: ' + startIncorrections;
              event.color =  this.calManager.colors.yellow;



            } else {
              event.title =  'Start: No Incorrections';
              event.color =  this.calManager.colors.blue;
            }

          } else {
            event.title = 'Start: No count!';
            event.color = this.calManager.colors.red;
          }
          this.calManager.nextEvent(event);
          let event2 : any = {};
          event2.allDay = false;
          event2.start = d;
          event2.end = d;
          event2.meta = {
            incrementsBadgeTotal: false,
          };

          if(element.end){
            element.end.forEach(stock => {
              endIncorrections += (stock.difference != 0 ? 1 : 0);
            })



            if(endIncorrections){
              event2.title =  'End Incorrections: ' + endIncorrections;
              event2.color =  this.calManager.colors.yellow;
            } else {
              event2.title =  'End: No Incorrections';
              event2.color =  this.calManager.colors.blue;
            }

          }else {
            event2.title = 'End: No count!';
            event2.color = this.calManager.colors.red;
          }
          this.calManager.nextEvent(event2);


        });

      }
     })
    this.barSub = this.bI.barObserver.subscribe(values => {
      if(values) {
        this.barName = values;
      this.db.getStock(new Date().getMonth(), new Date().getFullYear(), this.barName);
      }

    })


  }

  isFinite(number) {
    return Number.isInteger(number);
  }

  ngOnInit(): void {

    this.calSub = this.calManager.daysObserver.subscribe((values:Date) => {
      this.all1 = false;
      this.all2 = false;
      this.moreInfo1 = false;
      this.moreInfo2 = false;
      this.worked = null;
      if(values){

        this.getStaff(values);
        this.getReason(values)
        //console.log("get count for date: " + values);
      if(values && this.date.getMonth() != values.getMonth() && this.barName){
        this.db.getStock(values.getMonth(),values.getFullYear(), this.barName);
      }
      this.date = values;

      this.stockCount = null;
      this.selectedDate = null;
      this.values.forEach(val => {
        if(values.getDate() && Number(val.day) === values.getDate()){


          //console.log(val);
          this.selectedDate = MyDate.dateToString(values);
          this.startCount = val.start;
          this.endCount = val.end;

          if(this.startCount && this.endCount) {
            this.endCount.map(drink => {
              const s= this.startCount.filter(a => a.key === drink.key)[0];
              if(s) {
                drink.startTotal = -(s.till - (s.counted));
                drink.offsetSet = s.offsetSet;
              }
              return drink;
            })
          }

          this.isLastCount();
        }

      })
      this.showCalendar = false;
    }})


    this.staffSub = this.db.staffWorkedObserver.subscribe(values => {
      if(values){
        this.worked = values;
      } else {
        this.worked = null;
      }

    })
  }

  ngOnDestroy(){
    if(this.barSub){
      this.barSub.unsubscribe();
    }
    if(this.sub){
      this.sub.unsubscribe();
    }
    if(this.calSub){
      this.calSub.unsubscribe();
    }
    if(this.staffSub){
      this.staffSub.unsubscribe();
    }
  }



  getStaff(date: Date){

    this.db.getHoursForDate(this.barName,date);
  }

  async getReason(date: Date) {
    //console.log('getting reason')
    this.reason$ = null;
    await this.db.getIncorrectionReason(this.barName,date).then(a => {
      this.reason$ = a;
      //console.log(this.reason$)
    });
  }

  incorrect(list: any[], all = false){
    if(all) {
      return list;
    }
    return list.filter(a => a.difference != 0 || (a.difference == 0 && a.currentOffset != 0));
  }

  isLastCount(){
    //console.log('called isLastCount')
    const d = new Date();
    if(d.getHours() < 12){
      d.setDate(d.getDate() -1);
    }

    if(!this.selectedDate || !MyDate.isSameDay(this.date,d) || !this.start){
      this.lastCount =   false;
      return false;
    }
    this.lastCount = true;
    return true;

  }

  setZero(s){
    const offset = 0;
    this.db.updateOffset(s.key,offset,this.barName);

  }



  setZeroAll(){
    this.startCount.forEach(a => {
      this.setZero(a);
    })
  }




}

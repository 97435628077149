import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { GenericMessage } from "src/app/Classes/generic-message";
import { AlertsService } from "../alerts/alerts.service";
import { GenericMessageGroup } from "src/app/Classes/generic-message-group";

@Component({
  selector: "app-create-message",
  templateUrl: "./create-message.component.html",
  styleUrls: ["./create-message.component.css"],
})
export class CreateMessageComponent implements OnInit {
  @Input("message") message: GenericMessage;
  @Input("messages") messages: GenericMessageGroup;
  constructor(public modal: NgbActiveModal, public alert: AlertsService) {}

  ngOnInit(): void {
    console.log(this.messages);
  }

  close() {
    if (
      this.message.message == "" ||
      this.message.message == null ||
      this.message.message == undefined
    ) {
      this.alert.nextEmit(AlertsService.error("Message is required"));
      return;
    }
    this.modal.close(this.message);
  }

  dismiss() {
    this.modal.dismiss();
  }
}

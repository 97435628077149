import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-verbruik-popover",
  templateUrl: "./verbruik-popover.component.html",
  styleUrls: ["./verbruik-popover.component.css"],
})
export class VerbruikPopoverComponent implements OnInit {
  @Input("items") items: any[] = [];
  @Input("stock") stock: any[] = [];
  staff: any[] = [];
  names: any = {};
  constructor(public db: StaffAppDatabase) {}

  ngOnInit(): void {
    this.getStaff();
  }

  async getStaff() {
    this.staff = (await this.db.getStaffNamesAsync(this.db.bar)).map(
      (a: any) => {
        return {
          uid: a.uid,
          name: a.firstName + " " + a.lastName,
          medewerker: a.medewerkernumber,
        };
      }
    );

    if (this.items) {
      this.items.forEach((item: any) => {
        console.log(item);
        if (item.reason && item.reason.toLowerCase().includes("medewerker")) {
          //formatted like "StringMedewerker 1"
          var lastSplit = item.reason.split(" ").pop();
          console.log(lastSplit);
          var id = parseInt(lastSplit);
          console.log(id);

          var staff = this.staff.filter((a) => a.medewerker == String(id));
          if (staff.length) {
            item.reason += " " + staff.map((a) => a.name).join(", ");
          }
        }
      });
    }
    console.log(this.staff);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.names = {};
    this.stock.forEach((a) => {
      this.names[a.serverId] = a.name;
    });

    this.items.forEach((item: any) => {
      console.log(item);
      if (item.reason && item.reason.toLowerCase().includes("medewerker")) {
        //formatted like "StringMedewerker 1"
        var lastSplit = item.reason.split(" ").pop();
        console.log(lastSplit);
        var id = parseInt(lastSplit);
        console.log(id);

        var staff = this.staff.filter((a) => a.medewerker == String(id));
        if (staff.length) {
          item.reason += " " + staff.map((a) => a.name).join(", ");
        }
      }
    });
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PriceIntervalService {
  august: any[] = [];
  constructor() {
    this.genTable();
  }

  creditF(bill: number) {
    return Math.max(Math.floor(bill / 0.29) * 10, 2);
  }
  table: any = [];

  genTable() {
    var supplyDemand = [100, 95, 90, 85, 80, 75, 70, 65, 60, 55];
    this.table.push({
      index: 1,
      unitPrice: 1.45,
      thirtyMin: 12.33,
      costInCredits: 50,
    });
    for (var i = 2; i <= 15; i++) {
      const unitPrice =
        (this.table[0].unitPrice * i * (supplyDemand[i - 1] || 55)) / 100;
      var entry = {
        index: i,
        unitPrice,
        thirtyMin: (this.table[0].unitPrice * i * 17) / 2,
        costInCredits: Math.ceil(unitPrice / 0.029),
      };
      this.table.push(entry);
    }
  }

  baselog(x: number, y: number) {
    return Math.log(y) / Math.log(x);
  }

  async price(transaction: any) {
    const time = transaction.time + 1000 * 60 * 10;
    const time2 =
      transaction.time +
      1000 * 60 * 10 -
      new Date().getTimezoneOffset() * 1000 * 60;
    const date = new Date(time2);
    console.log(
      this.creditF(transaction.amount) +
        ' credits added: ' +
        ' at ' +
        date.toUTCString()
    );
    const [last30Minutes, scanned] = await this.last30Minutes(
      new Date(Number(time))
    );

    var index = 0;
    for (index = 0; index < this.table.length; index++) {
      if (this.table[index].thirtyMin > last30Minutes) {
        break;
      }
    }

    if (index >= this.table.length) {
      index = this.table.length - 1;
    }

    var result = {
      costInCredits: this.table[index].costInCredits,
      last30Minutes: last30Minutes,
      scanned: scanned,
      credits: this.creditF(transaction.amount),
      date: new Date(transaction.time).getTime(),
      creditsLast30Minutes: this.creditF(last30Minutes),
    };
    if (index > 0) {
      const prev = this.table[index - 1];
      const next = this.table[index];
      console.log(
        next.thirtyMin,
        next.costInCredits,
        prev.thirtyMin,
        prev.costInCredits
      );
      //get ratio of prev to next
      const ratio =
        (last30Minutes - prev.thirtyMin) / (next.thirtyMin - prev.thirtyMin);
      console.log(ratio);
      const nextCost =
        next.costInCredits - (prev.costInCredits - next.costInCredits) * ratio;
      console.log(nextCost);
      result.costInCredits = nextCost;
    }
    console.log(
      'last 30 minutes: ' + last30Minutes + 'e' + ' and scanned ' + scanned
    );
    console.log(this.table[index].costInCredits, ' credits needed ');

    console.log('\n');
    return result;
  }

  async interval(endTime: Date) {
    const [last30Minutes, scanned, receipts] = await this.last30Minutes(
      endTime
    );
    console.log(endTime);
    var index = 0;
    for (index = 0; index < this.table.length; index++) {
      if (this.table[index].thirtyMin > last30Minutes) {
        break;
      }
    }

    if (index >= this.table.length) {
      index = this.table.length - 1;
    }

    var result = {
      costInCredits: this.table[index].costInCredits,
      last30Minutes: last30Minutes,
      scanned: scanned,
      date: endTime.getTime(),
      receipts: receipts.map((a: any) => {
        return { x: a.time, y: this.creditF(a.amount) };
      }),
      creditsLast30Minutes: this.creditF(last30Minutes),
    };
    console.log(result.receipts);
    if (index > 0) {
      const prev = this.table[index - 1];
      const next = this.table[index];
      console.log(
        next.thirtyMin,
        next.costInCredits,
        prev.thirtyMin,
        prev.costInCredits
      );
      //get ratio of prev to next
      const ratio =
        (last30Minutes - prev.thirtyMin) / (next.thirtyMin - prev.thirtyMin);
      console.log(ratio);
      const nextCost =
        next.costInCredits - (prev.costInCredits - next.costInCredits) * ratio;
      console.log(nextCost);
      result.costInCredits = nextCost;
    }
    console.log(
      'last 30 minutes: ' + last30Minutes + 'e' + ' and scanned ' + scanned
    );
    console.log(this.table[index].costInCredits, ' credits needed ');

    console.log('\n');
    return result;
  }

  setDay(data: any[]) {
    console.log(data);
    this.august = data;
  }

  async last30Minutes(date: Date): Promise<[number, number, any]> {
    const startDate = new Date(date);
    startDate.setMinutes(startDate.getMinutes() - 30);

    const data = this.august.filter((a: any) => {
      const time = new Date(a.time);
      if (time >= startDate && time <= date) {
        return true;
      }
      return false;
    });
    //console.log(data);
    var total = 0;
    data.forEach((a: any) => {
      total += a.amount;
    });
    var total = Number(Number(total).toFixed(2));
    return [total, data.length, data];
  }
}

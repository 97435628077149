<div class="staff-info-title">
    <div class="action-side"></div>
    <h3 style="margin: 5px 0px 2px 0px">Till Actions</h3>
    <button class="action-side close-button red" (click)="activeModal.dismiss()">
  <i class="fas fa-times"></i>
</button>
</div>

<div class="actions actions-header">
    <button class="reactive-button-title green " (click)="updateAllPrices()" [disabled]="tillDrinks.length == 0">
    Update All Prices
</button>





</div>


<app-input style="width: 100%;" name="Search Stock" placeholder="Coca Cola" (changed)="filteredDrinks($event)"></app-input>

<div class="stock-table-header ">
    <div class="stock-item-large ">
        <div>Name</div>
    </div>
    <div class="stock-item-imp ">
        <div>ServerId</div>
    </div>

    <div class="stock-item-imp ">
        <div>Price</div>
    </div>

    <button style="opacity: 0; "><i class="fas fa-chevron-up "></i></button>
    <button style="opacity: 0; "><i class="fas fa-chevron-down "></i></button>
</div>

<div class="stock-table ">
    <div *ngFor="let s of filteredTillDrinks; let i=index " class="stock-item " [ngClass]="s.exists ? 'green' : 'orange'">
        <div class="stock-item-large ">{{s.name}}</div>
        <div class="stock-item-imp ">{{s.id}}</div>
        <div class="stock-item-imp ">{{s.prices[0].amount}}</div>

        <button (click)="$event.stopPropagation(); addStock(s) " ngbTooltip="Green means it already exists in the database">Add</button>

    </div>
</div>

import { Injectable, EventEmitter, NgZone } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from "rxjs";
import { LoadingComponent } from "../Packages/loading/loading.component";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  private emit = new BehaviorSubject<any>({
    vari: null,
    percent: null,
    location: null,
  });

  modalRefs = [];



  constructor(public modal: NgbModal) {}


  listen() {
    return this.emit.asObservable();
  }

  show() {
    this.nextEmit("on");
  }

  hide() {
    this.nextEmit(null);
  }

  nextEmit(vari: string | null = null, percent: number | null = null, location: string | null = null) {
    // console.log('next emit', vari, percent, location);
    console.log('next emit', vari, percent, location);
    console.log(this.modal.activeInstances.length)
    this.dismissAll();
    if (vari) {
      const modalRef = this.modal.open(LoadingComponent, {size: 'sm', centered: true, backdrop: 'static', keyboard: false, backdropClass: 'loading-backdrop', windowClass: 'loading-window',modalDialogClass: 'loading-modal-dialog'});
      this.modalRefs.push(modalRef);
      setTimeout(() => {
        this.emit.next({ vari: "off", percent: null, location: null });
        modalRef.close();
      }, 1000 * 60 * 30);


    }
    this.emit.next({ vari, location, percent });
  }

  dismissAll(){
    this.modalRefs.forEach((modalRef) => {
      try {modalRef.close();} catch (e) {}
    });
    this.modalRefs = [];
  }
}

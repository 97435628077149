/**
 * API
 * # Introduction  Welcome to the TPAPI-POS API! unTill Server provides a REST interface for 3rd party applications which may be used for remotely working with POS: reading configuration, making orders, etc.  You can view code samples in the dark area to the right.  In case you might have any questions about our API, please create a new support [**ticket**](http://integrations.untill.com/support/tickets/new). If you don't have an account yet but already signed an [**NDA**](https://untill.com/nda), please send an email to [**systemintegration@untill.com**](mailto:systemintegration@untill.com). Please do not use this email address for support questions.  # User permissions  Each API function should be invoked with appropriate user permission. Please see function description. The following permissions can be assigned to a user in unTill Backoffice - Backoffice view - Backoffice modify - Ordering - Ordering: override price - Apply discounts - Payment - Client management - Reporting - Reservations - Misc
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DiscountOnItems } from '../model/discountOnItems';
import { DiscountOnTable } from '../model/discountOnTable';

import { BASE_PATH } from 'src/variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ApplyDiscountsService {

    protected basePath = 'http://82.73.157.201:{port}/api/v1/{dbname}';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Apply discount on items
     * Apply discount to table items. This can be either in percent or absolute amount. When an absolute amount is used, the discount can only be applied if this amount is less than or equal to the total price of the items. Required user permission: Apply discounts
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public discountsOnItemsPost(body: DiscountOnItems, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public discountsOnItemsPost(body: DiscountOnItems, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public discountsOnItemsPost(body: DiscountOnItems, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public discountsOnItemsPost(body: DiscountOnItems, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling discountsOnItemsPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/discounts/on-items`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Apply discount on table
     * Apply discount on the complete table. This function can only accepts an abosolute discount amount. Required user permission: Apply discounts
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public discountsOnTablePost(body: DiscountOnTable, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public discountsOnTablePost(body: DiscountOnTable, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public discountsOnTablePost(body: DiscountOnTable, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public discountsOnTablePost(body: DiscountOnTable, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling discountsOnTablePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/discounts/on-table`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const nth = function(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}
export class MyDate {

    constructor() {

    }

    toMonth(number:number){
      return monthNames[number];
    }

    static toPrettyDay(date) {
        if (date.length == 8) {
          return date[0] + date[1] + '/' + date[2] + date[3] + '/' + date[4] + date[5] + date[6] + date[7];
        }
        return date;
    }

    static toPrettyTime(time) {
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / 1000 / 60) % 60);
        const hours = Math.floor((time / 1000 / 60 / 60));
        return hours + 'h:' + minutes + 'm:' + seconds + 's';
    }

    static getDate() {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2,'0'); // January is 0!
        const yyyy = today.getFullYear();
        return dd + mm + yyyy;
      }

    static getNowInMs() {
        return new Date().getDate();
    }

    static getValidDate() {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2,'0'); // January is 0!
        const yyyy = today.getFullYear();
        return yyyy + '-' +  mm + '-' + dd;
    }

    static hoi(){
        return "hoi";
    }


    static dateToString(d:Date){
      //console.log(("0" + this.getDate()).slice(-2));
      return d.getDate() + nth(d.getDate)+ " " +monthNames[d.getMonth()] +" " + d.getFullYear();
    }

    static isSameDay(d1: Date,d2:Date){
     //console.log(d1,d2)
      return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();

    }
}

import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-jsoncompare",
  templateUrl: "./jsoncompare.component.html",
  styleUrls: ["./jsoncompare.component.css"],
})
export class JSONCompareComponent implements OnInit {
  records: any[] = [];
  changes: any;
  showAll = true;
  constructor(public ngbActiveModal: NgbActiveModal) {}

  ngOnInit(): void {
    console.log(this.records);
    this.changes = findChangedKeys(this.records[0], this.records[1]);
  }

  handle() {
    this.showAll = !this.showAll;
  }
}

function findChangedKeys(
  obj1: Record<string, any>,
  obj2: Record<string, any>
): [Record<string, any>, Record<string, any>] {
  const changedKeysObj1: Record<string, any> = {};
  const changedKeysObj2: Record<string, any> = {};

  function deepCompare(
    o1: Record<string, any>,
    o2: Record<string, any>,
    path: string = ""
  ) {
    for (const key in o1) {
      const currentPath = path ? `${path}.${key}` : key;
      if (Object.prototype.hasOwnProperty.call(o1, key)) {
        if (!o2.hasOwnProperty(key)) {
          changedKeysObj1[currentPath] = o1[key];
        } else if (typeof o1[key] === "object" && typeof o2[key] === "object") {
          deepCompare(o1[key], o2[key], currentPath);
        } else if (o1[key] !== o2[key]) {
          changedKeysObj1[currentPath] = o1[key];
        }
      }
    }

    for (const key in o2) {
      const currentPath = path ? `${path}.${key}` : key;
      if (Object.prototype.hasOwnProperty.call(o2, key)) {
        if (!o1.hasOwnProperty(key)) {
          changedKeysObj2[currentPath] = o2[key];
        } else if (typeof o2[key] === "object" && typeof o1[key] === "object") {
          deepCompare(o1[key], o2[key], currentPath);
        } else if (o2[key] !== o1[key]) {
          changedKeysObj2[currentPath] = o2[key];
        }
      }
    }
  }

  deepCompare(obj1, obj2);

  return [changedKeysObj1, changedKeysObj2];
}

export class ScheduledTask {
  key: string;
  title: string;
  description: string;
  frequency: number;
  frequencyType: string;
  lastCompletedOn: { bar: string, date: number, name: string, uid: string }[];
  bars: string[];
  takePhoto: boolean;

  constructor() {
    this.key = '';
    this.title = '';
    this.description = '';
    this.frequency = 0;
    this.takePhoto = false;
    this.lastCompletedOn = [];
    this.frequencyType = "";
    this.bars = [];
  }
}

import { Page } from "./page";

export class Book {
  id: string =  '';
  bar: string = '';
  name: string = '';
  pages: Page[] = [new Page('')];

  constructor(id: string, bar: string, pages: Page[]) {
    this.id = id;
    this.bar = bar;
    this.pages = pages;
    this.name = '';
  }

}

<div *ngIf="data" [class]="'level-' + data.level ">
    <div class="alert-title">
        {{data.title}}
    </div>
    <div class="alert-message">

        {{data.message}}
    </div>

    <button (click)="close()" style="position: absolute; top: 20px;
    right: 20px; border: 0px !important; outline: 0px !important; background-color: transparent;"><i class="fas fa-close"></i></button>

</div>

<div *ngIf="isLoading" style="position: relative;">
  <section class="star-section">
    <span class="star"></span>
    <span class="star"></span>
    <span class="star"></span>
    <span class="star"></span>
    <span class="star"></span>
    <span class="star"></span>
    <span class="star"></span>
    <span class="star"></span>
    <span class="star"></span>
    <span class="star"></span>
    <span class="star"></span>
    <span class="star"></span>
  </section>
  <div class="loader"> </div>


</div>
<div *ngIf="percent"
     style="position: absolute; height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; font-size:xx-large; font-weight: bold">
  {{percent}}%</div>

export class Roles {
  roles = [RoleNames.NEW,RoleNames.COMPETENT, RoleNames.SOCIAL_HYGIENE];

}


export enum RoleNames {
  NEW = 'new',
  COMPETENT = 'competent',
  SOCIAL_HYGIENE = 'social hygiene'
}

<div class="flex-columns">
  <div>
    <div>Start:</div>
    <input
      type="date"
      [ngModel]="start | date : 'yyyy-MM-dd'"
      (ngModelChange)="start = $event"
      type="date"
      name="startDate"
    />
  </div>

  <button (click)="getData()">Get Inkoop</button>
</div>

<div
  class="flex-columns"
  *ngIf="result"
  style="
    max-height: 80%;
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-start;
  "
>
  <ngx-json-viewer [json]="result"></ngx-json-viewer>
</div>

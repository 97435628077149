/**
 * API
 * # Introduction  Welcome to the TPAPI-POS API! unTill Server provides a REST interface for 3rd party applications which may be used for remotely working with POS: reading configuration, making orders, etc.  You can view code samples in the dark area to the right.  In case you might have any questions about our API, please create a new support [**ticket**](http://integrations.untill.com/support/tickets/new). If you don't have an account yet but already signed an [**NDA**](https://untill.com/nda), please send an email to [**systemintegration@untill.com**](mailto:systemintegration@untill.com). Please do not use this email address for support questions.  # User permissions  Each API function should be invoked with appropriate user permission. Please see function description. The following permissions can be assigned to a user in unTill Backoffice - Backoffice view - Backoffice modify - Ordering - Ordering: override price - Apply discounts - Payment - Client management - Reporting - Reservations - Misc
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Reservation } from '../model/reservation';
import { ReservationStatus } from '../model/reservationStatus';


import { Configuration }                                     from '../configuration';
import { BASE_PATH } from 'src/variables';
import { AngularFireFunctions } from '@angular/fire/compat/functions';


@Injectable()
export class ReservationsService {

    protected basePath = 'http://82.73.157.201:{port}/api/v1/{dbname}';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string,
     @Optional() configuration: Configuration,private functions :AngularFireFunctions) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * List reservations
     * List reservations. Required user permission: Reservations
     * @param date Search reservations for specified date
     * @param updated Search reservations after specified date/time
     * @param tableNumber Search reservations by specified table number
     * @param externalId Search reservations by specified external ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reservationGet(date?: string, updated?: Date, tableNumber?: number, externalId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reservation>>;
    public reservationGet(date?: string, updated?: Date, tableNumber?: number, externalId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reservation>>>;
    public reservationGet(date?: string, updated?: Date, tableNumber?: number, externalId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reservation>>>;
    public reservationGet(date?: string, updated?: Date, tableNumber?: number, externalId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (updated !== undefined && updated !== null) {
            queryParameters = queryParameters.set('updated', <any>updated.toISOString());
        }
        if (tableNumber !== undefined && tableNumber !== null) {
            queryParameters = queryParameters.set('tableNumber', <any>tableNumber);
        }
        if (externalId !== undefined && externalId !== null) {
            queryParameters = queryParameters.set('externalId', <any>externalId);
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reservation>>('get',`${this.basePath}/reservation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress

            }
        );
    }

    /**
     * Find reservation by ID
     * Find reservation by ID. Required user permission: Reservations
     * @param id Specify to get reservation by ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reservationIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Reservation>;
    public reservationIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reservation>>;
    public reservationIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reservation>>;
    public reservationIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling reservationIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }



        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Reservation>('get',`${this.basePath}/reservation/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update reservation status by ID
     * Update reservation status by ID. Required user permission: Reservations
     * @param body
     * @param id Specify to update reservation status by ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reservationIdPatch(body: ReservationStatus, id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public reservationIdPatch(body: ReservationStatus, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public reservationIdPatch(body: ReservationStatus, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public reservationIdPatch(body: ReservationStatus, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling reservationIdPatch.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling reservationIdPatch.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/reservation/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update reservation by ID
     * Update reservation by ID. Required user permission: Reservations
     * @param body
     * @param id Specify to update reservation by ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reservationIdPut(body: Reservation, id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public reservationIdPut(body: Reservation, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public reservationIdPut(body: Reservation, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public reservationIdPut(body: Reservation, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling reservationIdPut.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling reservationIdPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/reservation/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create reservation
     * Create reservation. Required user permission: Reservations
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reservationPost(body: Reservation, observe?: 'body', reportProgress?: boolean): Observable<Reservation>;
    public reservationPost(body: Reservation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reservation>>;
    public reservationPost(body: Reservation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reservation>>;
    public reservationPost(body: Reservation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling reservationPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["AppToken"]) {
            headers = headers.set('AppToken', this.configuration.apiKeys["AppToken"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Reservation>('post',`${this.basePath}/reservation`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

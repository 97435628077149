<div>
  <button
    *ngIf="auth.accessUser.access >= 2"
    class="action-button selected"
    routerLink="/customerapp/catalogue"
    routerLinkActive="true"
  >
    Catalogue
  </button>

  <button
    *ngIf="auth.accessUser.access >= 3"
    class="action-button selected"
    routerLink="/customerapp/customers"
    routerLinkActive="true"
  >
    Guests
  </button>

  <button
    *ngIf="auth.accessUser.access >= 3"
    class="action-button selected"
    routerLink="/customerapp/live"
    routerLinkActive="true"
  >
    Live
  </button>

  <button
    *ngIf="auth.accessUser.access >= 3"
    class="action-button selected"
    routerLink="/customerapp/requests"
    routerLinkActive="true"
  >
    Requests
  </button>

  <button
    *ngIf="auth.accessUser.access >= 3"
    class="action-button selected"
    routerLink="/customerapp/songpricing"
    routerLinkActive="true"
  >
    Song Pricing
  </button>
  <button
    *ngIf="auth.accessUser.access >= 3"
    class="action-button selected"
    routerLink="/customerapp/voucher"
    routerLinkActive="true"
  >
    Voucher
  </button>
  <button
    *ngIf="auth.accessUser.access >= 3"
    class="action-button selected"
    routerLink="/customerapp/usage"
    routerLinkActive="true"
  >
    Usage
  </button>

  <button
    *ngIf="auth.accessUser.access >= 3"
    class="action-button selected"
    routerLink="/customerapp/songemulating"
    routerLinkActive="true"
  >
    Song Price Emulating
  </button>

  <button
    *ngIf="auth.accessUser.access >= 3"
    class="action-button selected"
    routerLink="wipecredit"
    routerLinkActive="true"
  >
    Customer App Credits
  </button>
  <button
    *ngIf="auth.accessUser.access >= 1"
    class="action-button selected"
    routerLink="statistics"
    routerLinkActive="true"
  >
    Song Sung Statistics
  </button>

  <button
    *ngIf="auth.accessUser.access >= 5"
    class="action-button selected"
    routerLink="canDutch"
    routerLinkActive="true"
  >
    CanDutch
  </button>
</div>

<!--<div *ngIf="!(db.user | async)">
    <div class="edit-row">
        <div class="edit-desc">
            Email:
        </div>
        <input class="edit" #email type="email" id="email">
    </div>

    <div class="edit-row">
        <div class="edit-desc">
            Password:
        </div>
        <input class="edit" #password type="password">
    </div>
    <button (click)="login(email.value,password.value)">Login</button>
</div>

<button (click)="db.setAuth(true)">AuthDunc</button>
<button (click)="signOut()">Sign Out</button>-->

import { Component, OnInit } from '@angular/core';
import { Auth } from 'src/app/auth/auth';

@Component({
  selector: 'app-peroneel',
  templateUrl: './peroneel.component.html',
  styleUrls: ['./peroneel.component.css']
})
export class PeroneelComponent implements OnInit {

  constructor(public auth : Auth) { }

  ngOnInit(): void {
  }

}

import { GenericMessage } from "./generic-message";

export class GenericMessageGroup {
  key: string;
  bar: string;
  lastMessage: number = new Date().getTime();
  messages: GenericMessage[] = [];
  active: string = "true";

  date: number = new Date().getTime();
  page: string = "";

  lastSender: string = "";

  constructor(
    key: string,
    bar: string,
    lastMessage: number,
    messages: GenericMessage[],
    chainDate: Date,
    page: string,
    lastSender: string
  ) {
    this.key = key;
    this.bar = bar;
    this.lastMessage = lastMessage;
    this.messages = messages;
    this.date = new Date(chainDate).getTime();
    this.page = page;
    this.lastSender = lastSender;
  }
}

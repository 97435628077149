import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Book } from 'src/app/Classes/book';
import { AlertsService } from 'src/app/Packages/alerts/alerts.service';
import { BookService } from 'src/app/Services/book.service';
import { LoadingService } from 'src/app/Services/loading.service';
import { TheEndTVPopupComponent } from 'src/app/Components/Testing/the-end-tvpopup/the-end-tvpopup.component';


@Component({
  selector: 'app-the-end-tv',
  templateUrl: './the-end-tv.component.html',
  styleUrls: ['./the-end-tv.component.css']
})
export class TheEndTVComponent implements OnInit {

  /**
   * The End TV Component
   * works per bar
   * list of books (slideshows) (can be used on any bar)
   * crud books
   *
   * for each book
   * crud pages
   *
   * live view of the book
   *
   * pages can be on with schedule (like not this page after 2am)
   */
  constructor(public loading: LoadingService, public alert: AlertsService, public bookService: BookService, public modal: NgbModal) { }

  ngOnInit(): void {
   this.getBooks();
  }

  getBooks(){
    this.loading.show();
    this.bookService.downloadBooks().then(() => {
      this.loading.hide();
    }).catch((err) => {
      this.loading.hide();
      this.alert.nextEmit(AlertsService.error('Error downloading books', err));
    });
  }

  openBook(book: Book){
    var modal = this.modal.open(TheEndTVPopupComponent, {size: 'xl', centered: true})
    modal.componentInstance.book = Object.assign({}, book);

    modal.result.then((res) => {
      //refresh
      this.getBooks();
    }, (dismiss) => {

    })

  }

  createBook(){
    var book = new Book('', '', []);
    book.id = Math.random().toString(36).substring(7);
    book.name = 'New Book';
    this.openBook(book);

  }

}

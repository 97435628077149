<!--
<div class="flex-rows">



</div>



-->

<div class="page-header">
    <div>
        {{ date | date: "EEEE dd/MM/yy" }}
    </div>

    <button (click)="showCalendar = !showCalendar">
    <i class="fas fa-chevron-right"></i>
  </button>

    <div style="flex-grow: 1"></div>

    <div>Preview</div>
</div>

<div class="calendar-popup" [style.display]="showCalendar ? 'flex' : 'none'">
    <app-my-calendar></app-my-calendar>
</div>

<div class="page-overview" *ngIf="selectedDate">

    <div class="page-box-out">
        <div class="page-box-sub" style="flex-shrink: 0;" *ngIf="selectedDate && (lastCount || (worked && worked.length) || reason$)">
            <div *ngIf="lastCount">
                <h3>Offsets</h3>
                <button (click)="setZeroAll()">Set All Offsets To 0</button>

                <div></div>
            </div>
            <app-who-is-working [worked]="worked"></app-who-is-working>


            <h3 *ngIf="reason$" style="margin: 5px 0px">Incorrect Reason</h3>
            <p *ngIf="reason$">{{ reason$ }}</p>
        </div>

        <div class="page-box-sub" style="flex-grow: 1;" *ngIf="selectedDate && startCount">
            <h3>Start {{ selectedDate }}</h3>
            <div class="even-row">
                <button (click)="all2 = !all2">
    {{ all2 ? "Show Only Incorrect" : "Show All" }}
  </button>
                <button (click)="moreInfo2 = !moreInfo2">
    {{ moreInfo2 ? "Less Info" : "More Info" }}
  </button>
            </div>

            <div class="table-cont">
                <table *ngIf="startCount" class="tablenames">
                    <tr class="table-row">
                        <th class="table-column sticky-col">Name</th>
                    </tr>
                    <tr class="table-row" *ngFor="let s of incorrect(startCount, all2)" [ngClass]="{ blue: s.difference != 0 }">
                        <th class="table-column sticky-col">{{ s.name }}</th>
                    </tr>
                </table>

                <table *ngIf="startCount" class="table">
                    <tr class="table-row">
                        <th class="table-column">Till</th>
                        <th class="table-column">Stock</th>
                        <th class="table-column italic">Past</th>
                        <th class="table-column italic">Today</th>
                        <th class="table-column">Total</th>
                        <th *ngIf="moreInfo2" class="table-column" style="width: 5px !important">
                            &nbsp;
                        </th>
                        <th *ngIf="moreInfo2" class="table-column italic">Fridge</th>
                        <th *ngIf="moreInfo2" class="table-column italic">VIP</th>
                    </tr>
                    <tr class="table-row" *ngFor="let s of incorrect(startCount, all2)" [ngClass]="{ blue: s.difference != 0 }">
                        <th class="table-column">{{ s.till }}</th>
                        <th class="table-column">{{ s.counted - s.currentOffset }}</th>
                        <th class="table-column italic">{{ -s.currentOffset }}</th>
                        <th class="table-column italic">{{ -(s.till - s.counted) }}</th>
                        <th class="table-column">
                            {{ -(s.till - (s.counted - s.currentOffset)) }}
                        </th>
                        <th *ngIf="moreInfo2" class="table-column" style="width: 5px !important">
                            &nbsp;
                        </th>
                        <th *ngIf="moreInfo2" class="table-column italic">
                            {{ s.fridgeDifference }}
                        </th>
                        <th *ngIf="moreInfo2" class="table-column italic">
                            {{ s.privateRoomDifferences }}
                        </th>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="page-box-out">
        <div class="page-box-sub" *ngIf="selectedDate && endCount">
            <h3>End {{ selectedDate }}</h3>
            <div class="even-row">
                <div class="dot blue"></div>
                &nbsp; Incorrect at end of shift
            </div>
            <div class="even-row" style="width: 100%">
                <div class="even-row">
                    <div class="dot red"></div>
                    &nbsp; Offset Set
                </div>
                <div class="even-row">
                    <div class="dot yellow"></div>
                    &nbsp; Offset Ignored
                </div>
            </div>

            <div class="even-row">
                <button (click)="all1 = !all1">
      {{ all1 ? "Show Only Incorrect" : "Show All" }}
    </button>
                <button (click)="moreInfo1 = !moreInfo1">
      {{ moreInfo1 ? "Less Info" : "More Info" }}
    </button>
            </div>

            <div class="table-cont">
                <table *ngIf="endCount" class="tablenames">
                    <tr class="table-row">
                        <th class="table-column sticky-col">Name</th>
                    </tr>
                    <tr class="table-row" *ngFor="let s of incorrect(endCount, all1)" [ngClass]="{ blue: s.difference != 0 }">
                        <th class="table-column sticky-col">{{ s.name }}</th>
                    </tr>
                </table>

                <table *ngIf="endCount" class="table">
                    <tr class="table-row">
                        <th class="table-column">Till</th>
                        <th class="table-column">Stock</th>
                        <th class="table-column italic">Past</th>
                        <th class="table-column italic">Today Start</th>
                        <th class="table-column italic">Today End</th>
                        <th class="table-column">Total</th>
                        <th *ngIf="moreInfo1" class="table-column" style="width: 5px !important">
                            &nbsp;
                        </th>
                        <th *ngIf="moreInfo1" class="table-column italic">Fridge</th>
                        <th *ngIf="moreInfo1" class="table-column italic">VIP</th>
                    </tr>
                    <tr class="table-row" *ngFor="let s of incorrect(endCount, all1)" [ngClass]="{ blue: s.difference != 0 }">
                        <th class="table-column">{{ s.till }}</th>
                        <th class="table-column">{{ s.counted - s.currentOffset }}</th>
                        <th class="table-column italic">{{ -s.currentOffset }}</th>
                        <th class="table-column italic" [ngClass]="{
            yellow: s.offsetSet === 'ignored',
            red: s.offsetSet === 'set'
          }">
                            {{ isFinite(s.startTotal) ? s.startTotal : "na" }}
                        </th>
                        <th class="table-column italic">{{ -(s.till - s.counted) }}</th>
                        <th class="table-column">
                            {{ -(s.till - (s.counted - s.currentOffset)) }}
                        </th>
                        <th *ngIf="moreInfo1" class="table-column" style="width: 5px !important">
                            &nbsp;
                        </th>
                        <th *ngIf="moreInfo1" class="table-column italic">
                            {{ s.fridgeDifference }}
                        </th>
                        <th *ngIf="moreInfo1" class="table-column italic">
                            {{ s.privateRoomDifferences }}
                        </th>
                    </tr>
                </table>
            </div>
        </div>
    </div>



</div>

export class Bug {
  date: number;
  description: string;
  location: string;
  title: string;
  app = "TheEndAdmin"
  user: {
    bar?: string;
    key?: string;
    name?: string;
  } = {};
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Shirts } from 'src/app/Classes/shirts';
import { types } from 'util';

@Component({
  selector: 'app-add-shirt',
  templateUrl: './add-shirt.component.html',
  styleUrls: ['./add-shirt.component.css']
})
export class AddShirtComponent implements OnInit {
  types = ["Shirt", "Vest","Door Keys", "Safe Keys"];
  sizes = ["S", "M", "L", "XL", "XXL","na/other"];

  @Input('shirt') shirt: Shirts;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  cancel(){
    this.activeModal.dismiss('cancel');
  }

  save(){
    this.activeModal.close(this.shirt);
  }

}

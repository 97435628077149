import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class SwaggerInterceptor implements HttpInterceptor {
  constructor(){

  }
  //https://levelup.gitconnected.com/the-correct-way-to-make-api-requests-in-an-angular-application-22a079fe8413
  intercept(
    request: HttpRequest<any>, next: HttpHandler
  ): Observable<any> {
    console.log("request",request.url, request.headers);
    if(request.url.indexOf(':3064/api') != -1){
      console.log("swagger",request.urlWithParams);
      const authReq = request.clone({
        headers: new HttpHeaders({
          'accept':'application/json',
          'Authorization':'Basic QVBJOlloMzZAaEokckloaUQ2ZEY=',
          "AppToken": "nw0IxN1EZczg"
        })

      });
      console.log("swagger",request.urlWithParams, request.headers);
      return next.handle(authReq);
    }

    return next.handle(request);
  }
}

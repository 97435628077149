<h2>Wipe Credits</h2>
<button *ngIf="aboveZeroUsers == null" (click)="getPositiveCredits()">
  Check Users
</button>
<p *ngIf="aboveZeroUsers != null">
    There are {{ aboveZeroUsers }} user(s) with more than 0 credits
</p>

<div class="divider"></div>

<button (click)="fixCredits()">Reset Credits (Do Not Do After 8pm!)</button>
<button [useExistingCss]="true" [printTitle]="type + '-' + data.client.name + '-' + data.information.date" printSectionId="pdfTable" ngxPrint class="btn btn-primary" style="font-size: 20px !important;">Export To PDF</button>
<button class="btn btn-primary" style="font-size: 20px !important;" (click)="exportExcel()">Export to Excel</button>
<div id="pdfTable" #pdfTable class="pdfTable">
    <div class="pdfHeader">

        <img class="pdfLogo" [src]="data.image">

        <div class="pdfHeaderRight">
            <div style="flex-grow: 1;"></div>
            <div class="pdfTitle">FACTUUR</div>
            <div style="flex-grow: 2;"></div>

            <app-address [address]="data.sender" class="pdfAddress"></app-address>

            <div style="flex-grow: 1;"></div>

        </div>
    </div>
    <div class="pdfdivider"></div>
    <div class="pdfHeader" style="height: auto !important">
        <div class="pdfHeaderLeft">
            <app-address [address]="data.client" [align]="'left'" class="pdfAddress" [showPayment]="false"></app-address>
        </div>
        <div class="pdfHeaderRight" style="justify-content: center !important">
            <div class="pdfBoldVal">
                <b>Factuur Nummer:</b> &nbsp;
                <div>{{data.information.number}}</div>
            </div>
            <div class="pdfBoldVal">
                <b>Datum:</b> &nbsp;
                <div>{{data.information.date}}</div>
            </div>
            <div class="pdfBoldVal">
                <b>Vervaldatum:</b> &nbsp;
                <div>{{data.information['due-date']}}</div>
            </div>
        </div>


    </div>
    <div class="pdfdivider"></div>

    <table id="invoice-table">
        <tr class="pdfProduct pdfProductTitle">
            <th class="productVar" style="width: 120px ;">Artikel</th>

            <th class="productName">Description</th>

            <th class="productVar">Aantal</th>
            <th class="productVar">Prijs</th>
            <th class="productVar">Subtotaal</th>
            <th class="productVar">L/H</th>
            <th class="productVar">BTW</th>
            <th class="productVar">Totaal</th>
            <th class="productVar">Date</th>

            <th class="productVar" *ngIf="showEmbellage">emb.</th>
            <th class="productVar" *ngIf="showEmbellage">Emballage Totaal</th>
            <th class="productVar" style="width: 100px !important;">Cat.</th>
        </tr>

        <tr class="pdfProduct" *ngFor="let prod of data.products">
            <td class="productVar" style="width: 120px ; white-space: none; overflow: hidden; ">{{prod.name}}</td>

            <td class="productName">{{prod.description}}</td>
            <td class="productVar">{{prod.quantity}}</td>
            <td class="productVar">{{prod.price | currency:'EUR'}}</td>
            <td class="productVar">{{(prod.price * prod.quantity) | currency:'EUR'}}</td>
            <td class="productVar">{{prod[ 'tax-rate']}}%</td>
            <td class="productVar">{{(prod.price * prod.quantity) * (prod['tax-rate']/100) | currency:'EUR'}}</td>
            <td class="productVar">{{prod.total * (1 + (prod['tax-rate']/100)) | currency:'EUR'}}</td>
            <td class="productVar"> &shy;{{ data.information.datetime | date:'MM/dd/yyyy'}}</td>
            <td class="productVar" *ngIf="showEmbellage">{{prod.embellage | currency:'EUR'}}</td>
            <td class="productVar" *ngIf="showEmbellage">{{prod.embellage * prod.quantity | currency:'EUR'}}</td>
            <td class="productVar" style="width: 100px !important;">{{prod.category}}</td>
        </tr>
    </table>

    <div style="display: flex; align-items: flex-start; justify-content: flex-end; margin-top: 25px; width: 100%;">
        <table id="emballage-table" style="width: 40%; justify-self: flex-start;" *ngIf="showEmbellage">
            <tr class="pdfProduct">
                <th style="width: 50%;">Embellage Terug</th>
                <th style="width: 16%;">Aantal</th>
                <th style="width: 16%;">Prijs</th>
                <th style="width: 16%;">Totaal</th>

            </tr>
            <tr *ngFor="let tax of data.total.returnedEmbellage | keyvalue" class="pdfProduct">
                <td style="width: 50%;" class="productName">{{tax.value.name}}</td>
                <td style="width: 16%;" class="productVar">{{(tax.value.quantity) }} </td>
                <td style="width: 16%;" class="productVar"> {{(tax.value.price) | currency:'EUR'}} </td>
                <td style="width: 16%; font-weight: bold;" class="productVar">-{{(tax.value.price * tax.value.quantity) | currency:'EUR'}}</td>


            </tr>
            <tr class="pdfProduct">
                <td class="productName" style="font-weight: bold; width: 50%;">Totaal:</td>
                <td class=" productVar " style="width: 16%;">

                </td>
                <td class="productVar " style="width: 16%;">

                </td>
                <td class="productVar " style="font-weight: bold; width: 16%;">-{{data.total.embellageReturnedTotal | currency:'EUR'}}</td>
            </tr>

        </table>
        <div style="flex-grow: 1;"></div>

        <table id="total-table" style="width: 40%; border: 1px lightgray inset; justify-self: flex-end;">



            <tr *ngFor="let tax of data.total.categories " style="width: 100%;">

                <td class="productName " style="width: 40%;"> {{tax.name}} </td>
                <td style="width: 30%;">&nbsp;</td>
                <td class="productVar " style="width: 30%;">{{tax.total | currency:'EUR'}}</td>


            </tr>
            <tr style="width: 100%;">
                <td style="width: 40%;">&nbsp;</td>
                <td style="width: 30%;" style="width: 30%;">&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
            <tr>
                <td class="productName " style="font-weight: bold; width: 40%;">Totaal</td>
                <td class="productName " style="font-weight: bold;  width: 30%;">excl BTW</td>
                <td class="productVar " style="font-weight: bold;  width: 30%;">{{data.total.subtotal | currency:'EUR'}}</td>
            </tr>
            <tr *ngFor="let tax of data.total.vats | keyvalue ">

                <td class="productName " style="flex-grow: 1; width: 40% ">BTW</td>
                <td style="width: 30%;">{{tax.key}}%</td>
                <td style="width: 30%;" class="productVar ">{{tax.value | currency:'EUR'}}</td>

            </tr>
            <tr>
                <td style="width: 40%;">&nbsp;</td>
                <td style="width: 30%;">&nbsp;</td>
                <td style="width: 30%;">&nbsp;</td>
            </tr>
            <tr>
                <td class="productName " style="font-weight: bold; width: 40%">Totaal</td>
                <td style="width: 30%;">&nbsp;</td>
                <td class="productVar " style="font-weight: bold;">{{data.total.total | currency:'EUR'}}</td>
            </tr>
            <tr *ngIf="showEmbellage">
                <td class="productName " style="width: 40%;">Emballage</td>
                <td style="width: 30%;">&nbsp;</td>
                <td class="productVar ">{{data.total?.embellageTotal | currency:'EUR'}}</td>
            </tr>
            <tr *ngIf="showEmbellage">
                <td class="productName " style="width: 40%;">Emballage Terug</td>
                <td style="width: 30%;">&nbsp;</td>
                <td class="productVar ">-{{data.total.embellageReturnedTotal | currency:'EUR'}}</td>
            </tr>
            <tr>
                <td class="productName " style="font-weight: bold; width: 40%;">totaal te voldoen</td>
                <td style="width: 30%;">&nbsp;</td>
                <td class="productVar " style="font-weight: bold; width: 30%;">{{data.total.total + (data.total.embellageTotal || 0) - (data.total.embellageReturnedTotal || 0) | currency:'EUR'}}</td>
            </tr>



        </table>
    </div>









</div>
<div class="flex-columns">
  <h2>Vouchers</h2>
  <app-input
    name="Show Archived:"
    (changed)="showArchived = !showArchived; getData()"
    [model]="showArchived"
    type="checkbox"
  ></app-input>
  <button (click)="newVoucher()">Add Voucher</button>
  <div class="divider"></div>
  <div class="list">
    <div *ngFor="let v of vouchers" (click)="voucherClicked(v)">
      {{ v.name }}
    </div>
  </div>
</div>

<div class="flex-columns" *ngIf="voucher">
  <h2>Voucher</h2>
  <app-input
    name="Archived:"
    (changed)="voucher.archived = !voucher.archived"
    [model]="voucher.archived"
    type="checkbox"
  ></app-input>

  <app-input
    name="Is Dynamic:"
    (changed)="voucher.isDynamic = !voucher.isDynamic"
    [model]="voucher.isDynamic"
    type="checkbox"
  ></app-input>
  <app-input name="Key" [editable]="false" [model]="voucher.key"></app-input>
  <app-input
    name="Name"
    [model]="voucher.name"
    (changed)="voucher.name = $event"
  ></app-input>
  <app-input
    name="Description"
    [model]="voucher.description"
    (changed)="voucher.description = $event"
  ></app-input>
  <app-input
    name="Date"
    type="date"
    [model]="voucher.date | date : 'yyyy-MM-dd'"
    (changed)="dateChanged($event)"
  ></app-input>

  <app-input name="DB" [editable]="false" [model]="voucher.db"></app-input>
  <app-input
    name="Postcode"
    [editable]="false"
    [model]="voucher.location"
  ></app-input>

  <app-input
    name="Song Credits"
    [model]="voucher.songCredits"
    (changed)="voucher.songCredits = $event"
  ></app-input>
  <app-input
    name="Drink Credits"
    [model]="voucher.drinkCredits"
    (changed)="voucher.drinkCredits = $event"
  ></app-input>

  <app-input
    type="checkbox"
    name="One Per Person"
    [model]="voucher.onePerPerson"
    (changed)="voucher.onePerPerson = $event"
  ></app-input>
  <app-input
    type="checkbox"
    name="New Guests Only"
    [model]="voucher.newOnly"
    (changed)="voucher.newOnly = $event"
  ></app-input>
  <app-input
    type="number"
    name="Max Uses"
    [model]="voucher.maxUses"
    (changed)="voucher.maxUses = $event"
  ></app-input>
  <app-input
    type="number"
    [editable]="false"
    name="Usages"
    [model]="voucher.usages"
  ></app-input>

  <app-input
    type="multiselect"
    name="Equivalent QR's (so scan one counts as any)"
    [model]="voucher.equivalent"
    [list]="voucherListOptions"
    [hint]="'What Qrs are the same as this?'"
    (changed)="equivalentChanged(voucher, $event)"
  ></app-input>

  <div class="button-bar">
    <button class="red" (click)="reset()">Cancel</button>
    <button class="green" (click)="saveVoucher()">Save</button>
    <button [disabled]="!voucher.key" class="blue" (click)="generateQR()">
      Make QR
    </button>
    <button [disabled]="!voucher.key" (click)="getHistory()">History</button>
  </div>
</div>

<div
  class="flex-columns"
  *ngIf="!showHistory && voucher && voucher.qrCode"
  style="min-width: 250px; min-height: 250px"
>
  <app-input
    type="multiline"
    name="QR Code: "
    [model]="voucher.qrCode"
    [editable]="false"
  ></app-input>
  <qrcode
    [qrdata]="voucher.qrCode"
    [(width)]="qrWidth"
    [colorDark]="'#000000'"
    [colorLight]="'#FFFFFF'"
    [allowEmptyStrings]="false"
    [errorCorrectionLevel]="'M'"
  ></qrcode>
</div>

<div
  class="flex-columns"
  style="
    justify-content: flex-start;
    min-width: 250px;
    max-height: 95%;
    overflow: auto;
  "
  *ngIf="showHistory"
>
  <h2>History: Total = {{ history.length }}</h2>
  <h3>
    Given: {{ totals.given | currency : "EUR" }} - Redeemed:
    {{ totals.redeemed | currency : "EUR" }} - Available:
    {{ totals.balance | currency : "EUR" }} - Average Spent:
    {{ totals.redeemed / totals.length | currency : "EUR" }}
  </h3>
  <div
    style="
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    "
  >
    <div style="width: 100px">History</div>

    <div style="width: 150px">UID</div>
    <div style="width: 150px">Date</div>
    <div style="width: 100px">Balance</div>
    <div style="width: 100px">Spent</div>
  </div>
  <div
    *ngFor="let user of history"
    style="
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    "
  >
    <button
      style="width: 100px"
      (click)="showUserInfo(userHistoryModal, user.key)"
    >
      Receipts
    </button>
    <div style="width: 150px">{{ user.key | slice : 15 }}...</div>
    <div style="width: 150px">
      {{ user.date | date : "yyyy-MM-dd HH:mm" }}
    </div>
    <div style="width: 100px" *ngIf="userHistories[user.key]">
      {{ userHistories[user.key].balance | currency : "EUR" }}
    </div>

    <div style="width: 100px" *ngIf="userHistories[user.key]">
      {{ userHistories[user.key].spent | currency : "EUR" }}
    </div>
  </div>
</div>

<ng-template #userHistoryModal style="color: black" let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="color: black" id="modal-basic-title">
      User History
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      X
    </button>
  </div>
  <div class="modal-body" style="color: black">
    {{ userHistory.user.userName }} {{ userHistory.user.uid }}
    {{ userHistory.balance | currency : "EUR" }}
    <h3>Receipt History (Scanned Bar Receipt)</h3>
    <div>Date - Bar - Amount - ReceiptId</div>
    <div *ngFor="let receipt of userHistory.receipts">
      {{ receipt.key | date : "yyyy-MM-dd HH:mm" }} - {{ receipt.bar }} -
      {{ receipt.amount / 10 | currency : "EUR" }} -
      {{ receipt.receipt }}
    </div>

    <h3>Order History (Drink)</h3>
    <div>Date - Amount - Type</div>
    <div *ngFor="let receipt of userHistory.history | keyvalue">
      {{ receipt.key | date : "yyyy-MM-dd HH:mm" }} -
      {{ receipt.value.total | currency : "EUR" }} - {{ receipt.value.type }}
    </div>
  </div>
</ng-template>

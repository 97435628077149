import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value: any):  Number {
    if(value.total) {
      return value.total
    }
    if(value.amount) {
      return value.amount;
    }
    if(value.price) {
      return value.price
    }
    return 0;
  }

}

<div [class]="titleClass">{{ name }}:</div>

<select *ngIf="editable && type == 'select'" [ngModel]="model" (ngModelChange)="model = $event; onChange($event)">
  <option *ngIf="!listHideAll" [value]="''">All</option>
  <option *ngFor="let opt of list" [value]="opt">{{ opt }}</option>
</select>

<app-multiselect *ngIf="editable && type == 'multiselect'" [listHideAll]="listHideAll" [list]="list" (changed)="onChangeList($event, list)" [hint]="hint"></app-multiselect>

<input *ngIf="editable && type == 'checkbox'" [type]="type" class="edit" [checked]="model" (change)="model = !model; onChange($event)" [name]="name" [placeholder]="placeholder" style="border-bottom: 1px solid black" />
<input *ngIf="
    editable &&
    type != 'checkbox' &&
    type != 'select' &&
    type != 'multiselect' &&
    type != 'multiline' && type != 'date'
  " [type]="type" class="edit" [(ngModel)]="model" (ngModelChange)="onChange($event)" [name]="name" [placeholder]="placeholder" />

<div *ngIf="!editable && type != 'multiselect'" class="edit" style="overflow-x: auto !important">
    {{ model }}
</div>

<div *ngIf="!editable && type == 'multiselect'" class="edit">
    <div>{{ model.length || "All" }} Selected</div>
</div>

<div *ngIf="editable && type == 'multiline'" class="edit">
    <textarea [(ngModel)]="model" (ngModelChange)="onChange($event)"></textarea>
</div>

<input *ngIf="type == 'date'" type="date" [ngModel]="model" (ngModelChange)="onChange($event,false)">

<ng-content></ng-content>

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  customerFirebase: {
    apiKey: "AIzaSyB0PthIDR5zfKVbWXCMMWwwvoxuEiyK8ks",
    authDomain: "customerapp-e7bca.firebaseapp.com",
    databaseURL: "https://customerapp-e7bca.firebaseio.com",
    projectId: "customerapp-e7bca",
    storageBucket: "customerapp-e7bca.appspot.com",
    messagingSenderId: "172937190560",
    appId: "1:172937190560:web:1bec2efe0f188bdc86bdc5",
    measurementId: "G-V4Z8MQNG3E",
  },
  staffFirebase: {
    apiKey: "AIzaSyBfCuIoup3NNAhp69Eml-vpEHOx6UlNvZY",
    authDomain: "staffapp-b0578.firebaseapp.com",
    databaseURL: "https://staffapp-b0578.firebaseio.com",
    projectId: "staffapp-b0578",
    storageBucket: "staffapp-b0578.appspot.com",
    messagingSenderId: "1074841454918",
    appId: "1:1074841454918:web:24faed77f5d8098e01fd47",
    measurementId: "G-RRN03N27C8",
  },
  calendarAPI: {
    functions: "http://127.0.0.1:8843/calendarapi-bcf5d/europe-west3/app",
    company: "TheEndKaraoke",
    username: "duncansaunders1997@gmail.com",
    password: "plads4982",
    location: "Pertempto",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { DateRange } from "@angular/material/datepicker";
import { be } from "date-fns/locale";
import { Artist } from "src/app/Classes/artist";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { MonthPipe } from "src/app/Pipes/month.pipe";
import { SongKeySplitterPipe } from "src/app/Pipes/song-key-splitter.pipe";
import { YearMonthCountPipe } from "src/app/Pipes/year-month-count.pipe";
import { Firebase } from "src/app/Services/firebase";
import { LoadingService } from "src/app/Services/loading.service";
import { NgbdSortableHeader } from "../song-requests/song-requests.component";
import { SortEvent } from "../statistics.directive";
import { StatisticsService } from "../statistics.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-statistics",
  templateUrl: "./statistics.component.html",
  styleUrls: ["./statistics.component.css"],
})
export class StatisticsComponent implements OnInit {
  catalogue: any[] = [];
  total = 0;
  statistics: any;
  showGraph = false;

  date = new Date();
  searchType = {
    year: this.date.getFullYear(),
    month: this.date.getMonth(),
    type: "all",
  };
  graphType = "songs";
  multi: any[] = [
    {
      name: "Top 20 Songs",
      series: [],
    },
  ];

  barnames = [];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = "Song";
  yAxisLabel: string = "Times Sung";
  timeline: boolean = false;
  bar;
  colorScheme = {
    domain: ["#5AA454", "#E44D25", "#CFC0BB", "#7aa3e5", "#a8385d", "#aae3f5"],
  };

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    private firebase: Firebase,
    private bI: BarInfo,
    public service: StatisticsService,
    private loading: LoadingService,
    private sdb: StaffAppDatabase
  ) {}

  async sleep50ms(): Promise<void> {
    await new Promise((resolve) => setTimeout(resolve, 50));
  }

  async getStats(reload = false, type = "songs") {
    this.graphType = type;

    if (this.showGraph && !reload) {
      this.showGraph = false;
      return;
    }
    this.multi = [];
    if (this.graphType == "songs") {
      const relevant = Object.assign([], this.catalogue)
        .sort((b, a) => {
          return (
            new YearMonthCountPipe().transform(Object.assign({}, a.sungd)) -
            new YearMonthCountPipe().transform(Object.assign({}, b.sungd))
          );
        })
        .slice(0, 15);
      relevant.forEach((entry) => {
        var series = [];
        Object.keys(entry.sungd).forEach((year) => {
          entry.sungd[year].forEach((month, index) => {
            series.push({
              name: year + " " + new MonthPipe().transform(index),
              value: month,
            });
          });
        });
        this.multi.push({ name: entry.name, series });
      });
    } else {
      var artists: any = {};

      try {
        Object.assign([], this.catalogue).forEach((song) => {
          const artistKey = new SongKeySplitterPipe().transform(song.key)[0];
          const ss = Object.assign({}, song.sungd);
          if (artists[artistKey]) {
            Object.keys(ss).forEach((year) => {
              artists[artistKey].sungd[year] = Object.assign(
                [],
                this.sumArrays(
                  Object.assign([], artists[artistKey].sungd[year]),
                  Object.assign([], ss[year])
                )
              );
            });
          } else {
            artists[artistKey] = { name: song.artist, sungd: ss };
          }
        });
      } catch (e) {
        console.log(e);
      }

      const relevant = Object.assign([], Object.values(artists))
        .sort((b: any, a: any) => {
          return (
            new YearMonthCountPipe().transform(Object.assign({}, a.sungd)) -
            new YearMonthCountPipe().transform(Object.assign({}, b.sungd))
          );
        })
        .slice(0, 15);

      relevant.forEach((entry: any) => {
        var series = [];
        Object.keys(entry.sungd).forEach((year) => {
          entry.sungd[year].forEach((month, index) => {
            series.push({
              name: year + " " + new MonthPipe().transform(index),
              value: month,
            });
          });
        });

        this.multi.push({ name: entry.name, series });
      });
    }

    this.showGraph = true;
  }

  sumArrays(array1: number[], array2: number[]) {
    const mergedArray: number[] = [];
    const length = Math.max(array1.length, array2.length);

    for (let i = 0; i < length; i++) {
      const value1 = array1[i] ?? 0;
      const value2 = array2[i] ?? 0;
      mergedArray.push(value1 + value2);
    }

    return mergedArray;
  }

  dateChanged(input) {
    this.date = new Date(input);
    this.searchType.year = this.date.getFullYear();
    this.searchType.month = this.date.getMonth();
  }

  search() {
    this.catalogue.forEach((key) => {
      key.sungSearch = new YearMonthCountPipe().transform(
        key.sungd,
        this.searchType
      );
    });
    this.service.setCountries(this.catalogue);
  }

  ngOnInit(): void {
    this.loading.nextEmit("on");

    this.getData();
  }

  async getData() {
    this.loading.nextEmit("on");
    if (!this.barnames.length) {
      this.barnames = await this.sdb.getBarsPromise();
    }

    if (!this.bar) {
      this.bar = this.bI.bar;
    }
    const temp = (await this.firebase.getArtistsSync()).reduce(function (
      map,
      obj
    ) {
      map[obj.key] = obj;
      return map;
    },
    {});

    this.statistics = await this.firebase.getStatistics(this.bar);

    for (var stat of this.statistics) {
      const splits = stat.key.split("_");
      var bettersplits = splits;
      while (bettersplits.length > 2) {
        const newSplits = [
          bettersplits[0] + "_" + bettersplits[1],
          ...bettersplits.slice(2),
        ];
        bettersplits = newSplits;
      }
      const artistKey = bettersplits[0];
      const songIndex = Number(bettersplits[1]);
      if (!temp[artistKey]) {
        console.log(artistKey);
        continue;
      }

      try {
        temp[artistKey].songs[songIndex].sungSearch = 0;
        const temp2 = Object.assign({}, stat.sung);
        Object.keys(temp2).forEach((key, index) => {
          temp2[key] = Array.from(Object.assign([], temp2[key])).map(
            (a) => Number(a) || 0
          );
        });
        temp[artistKey].songs[songIndex].sung = stat.sung;
        temp[artistKey].songs[songIndex].sungd = temp2;
        temp[artistKey].songs[songIndex].sungSearch =
          new YearMonthCountPipe().transform(temp2, this.searchType);
      } catch (e) {
        console.log(e);
        console.error(stat, bettersplits);
      }
    }
    const temp2: any[] = Object.values(temp).reduce(
      (pre: any[], curr: Artist) =>
        pre.concat(curr.songs.filter((a) => a && a.sungd)),
      []
    ) as any[];
    this.catalogue = temp2;
    this.total = this.catalogue.length;
    this.service.setCountries(temp2);

    this.loading.nextEmit(null);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
}
